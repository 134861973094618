/** @jsx jsx */
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik, FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import SelectInput from '../../../components/SelectInput';
import TextInput from '../../../components/TextInput';
import { EmployeeEditFormFragment } from '../../../generated/graphql';
import { USER_GENDER_OPTIONS } from '../../../helpers/constants';
import EmployeeRoleMultiSelect from './EmployeeRoleMultiSelect';

const validationSchema = Yup.object({
  user: Yup.object({
    first_name: Yup.string().required('Erforderlich'),
    last_name: Yup.string().required('Erforderlich'),
    email: Yup.string()
      .email('Keine gültige Email')
      .nullable(),
  }),
});

interface IEmployeeEditFormProps {
  onSubmit: (values: EmployeeEditFormFragment, formikHelpers: FormikHelpers<EmployeeEditFormFragment>) => void;
  onCancel?: (event?: any) => void;
  initialValues: EmployeeEditFormFragment;
}

const EmployeeEditForm = ({ onCancel, onSubmit, initialValues }: IEmployeeEditFormProps) => (
  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ isSubmitting, submitForm, values }) => (
      <ContentCard elevation={Elevation.FOUR}>
        <ContentCardHeader
          leftElement={<span css={styles.heading}>Mitarbeiter bearbeiten</span>}
          rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
        />

        <ContentCardScroll>
          <FormGroup label="Geschlecht" name="user.gender">
            <SelectInput name="user.gender" options={USER_GENDER_OPTIONS} />
          </FormGroup>
          <FormGroup label="Vorname" labelInfo="(erforderlich)" name="user.first_name">
            <TextInput name="user.first_name" placeholder="Vorname" />
          </FormGroup>
          <FormGroup label="Nachname" labelInfo="(erforderlich)" name="user.last_name">
            <TextInput name="user.last_name" placeholder="Nachname" />
          </FormGroup>
          <FormGroup label="Email" name="user.email">
            <TextInput name="user.email" type="email" placeholder="Email" />
          </FormGroup>
          <FormGroup label="Telefon" name="user.phone">
            <TextInput name="user.phone" type="text" placeholder="Telefon" />
          </FormGroup>
          <FormGroup label="Mobil" name="user.mobile">
            <TextInput name="user.mobile" type="text" placeholder="Mobil" />
          </FormGroup>
          <FormGroup label="Adresse" name="user.street">
            <TextInput name="user.street" type="text" placeholder="Straße" />
          </FormGroup>
          <FormGroup name="user.postal_code">
            <TextInput name="user.postal_code" type="text" placeholder="PLZ" />
          </FormGroup>
          <FormGroup name="user.city">
            <TextInput name="user.city" type="text" placeholder="Ort" />
          </FormGroup>

          <Divider css={styles.formDivider} />
          <FormGroup label="Position" labelInfo="(erforderlich)" name="position">
            <TextInput name="position" placeholder="Position" />
          </FormGroup>
          <FormGroup label="Rollen" name="user.roles">
            <EmployeeRoleMultiSelect name="user.roles" />
          </FormGroup>
        </ContentCardScroll>

        <ContentCardFooter
          rightElement={
            <Fragment>
              <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
              <Button
                text="Änderungen Sichern"
                loading={isSubmitting}
                intent={Intent.PRIMARY}
                onClick={submitForm}
                css={styles.footerButton}
              />
            </Fragment>
          }
        />
      </ContentCard>
    )}
  </Formik>
);

EmployeeEditForm.fragments = {
  employee: gql`
    fragment EmployeeEditForm on Employee {
      id
      position
      user {
        id
        first_name
        last_name
        gender
        email
        phone
        mobile
        street
        city
        postal_code
        roles {
          id
          name
        }
      }
    }
  `,
};

export default EmployeeEditForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
