/** @jsx jsx */
import { HTMLTable } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router';
import Empty from '../../../components/Empty';
import Error from '../../../components/Error';
import NoResults from '../../../components/NoResults';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { useAllotmentListQuery } from '../../../generated/graphql';
import { useQueryParams } from '../../../hooks/navigation';
import { dummyAllotments } from '../dummy';
import AllotmentListItem from './AllotmentListItem';

const LoadingTable = () => (
  <HTMLTable condensed css={styles.table}>
    <thead>
      <tr>
        <TableHeaderCell>Status</TableHeaderCell>
        <TableHeaderCell>Name</TableHeaderCell>
        <TableHeaderCell>Karten</TableHeaderCell>
        <TableHeaderCell>Gültigkeit</TableHeaderCell>
      </tr>
    </thead>
    <tbody>
      {dummyAllotments.map((item, index) => (
        <AllotmentListItem key={index} item={item} skeleton />
      ))}
    </tbody>
  </HTMLTable>
);

const AllotmentList = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const filters = queryParams.get('filters');

  const { data, loading, error } = useAllotmentListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoadingTable />;
  }

  if (error) {
    return <Error />;
  }

  if (!data || !data.allotments) {
    return filters ? <NoResults /> : <Empty />;
  }

  return (
    <HTMLTable interactive condensed css={styles.table}>
      <thead>
        <tr>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Karten</TableHeaderCell>
          <TableHeaderCell>Gültigkeit</TableHeaderCell>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.allotments.map(allotment => (
            <AllotmentListItem
              key={allotment.id}
              item={allotment}
              onClick={() => history.push(`/allotments/${allotment.id}`)}
            />
          ))}
      </tbody>
    </HTMLTable>
  );
};

export default AllotmentList;

const styles = {
  table: css`
    width: 100%;
  `,
  loadingMoreContainer: css`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  loadingMoreText: css`
    padding-left: 10px;
  `,
};
