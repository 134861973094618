import gql from 'graphql-tag';
import React from 'react';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { LocationListItemFragment } from '../../../generated/graphql';

export type LocationListProps = {
  item: LocationListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

const LocationListItem = ({ item, skeleton, onClick }: LocationListProps) => {
  const address: string | null =
    item.street && item.postal_code && item.city ? `${item.street}, ${item.postal_code} ${item.city}` : null;

  return (
    <tr onClick={onClick}>
      <TableCell>
        <Text skeleton={skeleton}>{item.name}</Text>
      </TableCell>
      <TableCell>
        <Text skeleton={skeleton}>{address}</Text>
      </TableCell>
      <TableCell>
        <Text skeleton={skeleton}>{item.meeting_point}</Text>
      </TableCell>
    </tr>
  );
};

LocationListItem.fragments = {
  location: gql`
    fragment LocationListItem on Location {
      id
      name
      street
      city
      postal_code
      meeting_point
      public_transport
      accessible
      deleted_at
    }
  `,
};

export default LocationListItem;
