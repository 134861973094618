/** @jsx jsx */
import { Button, Colors, Icon, Intent, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Dispatch, Fragment } from 'react';
import PersonIconList from '../../../components/PersonIconList';
import Text from '../../../components/Text';
import { CandidateListItemFragment, EmailStatus, useCandidateSignupQuery } from '../../../generated/graphql';
import { ActionType } from '../types';

export type CandidateListItemProps = {
  id: string;
  isInGroup?: boolean;
  isInTeam?: boolean;
  dispatch: Dispatch<ActionType>;
};

export enum CandidateStatus {
  CANDIDATE = 'candidate',
  NO_EMAIL = 'no_email',
  EMAIL_SENT = 'email_sent',
  EMAIL_OPENED = 'email_opened',
  EMAIL_REJECTED = 'email_rejected',
  EMAIL_ADDRESS_INVALID = 'email_address_invalid',
  EMAIL_UNKNOWN_ERROR = 'email_unknown_error',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  UNKNOWN = 'unknown',
}

const getEmailStatus = (emails: CandidateListItemFragment['emails']): EmailStatus =>
  emails.reduce<EmailStatus>((acc, email) => {
    if (acc === EmailStatus.Opened) {
      return acc;
    }
    return email.opened_first_at ? EmailStatus.Opened : email.status;
  }, EmailStatus.Unknown);

const getCandidateStatus = (signup: CandidateListItemFragment): CandidateStatus => {
  const { soul, companion, is_invited, has_accepted, emails } = signup;
  const attendee = soul || companion!;
  const { user } = attendee;

  if (!is_invited) {
    return CandidateStatus.CANDIDATE;
  }

  if (has_accepted) {
    return CandidateStatus.ACCEPTED;
  }

  if (!user.email) {
    return CandidateStatus.NO_EMAIL;
  }

  switch (getEmailStatus(emails)) {
    case EmailStatus.Opened:
      return CandidateStatus.EMAIL_OPENED;
    case EmailStatus.Queued:
    case EmailStatus.Scheduled:
    case EmailStatus.Sent:
    case EmailStatus.Delayed:
      return CandidateStatus.EMAIL_SENT;
    case EmailStatus.MarkedSpam:
    case EmailStatus.SoftBounced:
    case EmailStatus.HardBounced:
    case EmailStatus.Rejected:
      return CandidateStatus.EMAIL_REJECTED;
    case EmailStatus.Invalid:
      return CandidateStatus.EMAIL_ADDRESS_INVALID;
    case EmailStatus.Unknown:
      return CandidateStatus.EMAIL_UNKNOWN_ERROR;
  }

  return CandidateStatus.UNKNOWN;
};

const CandidateStatusText = ({ status, item }: { status: CandidateStatus; item: CandidateListItemFragment }) => {
  const user = item.soul ? item.soul.user : item.companion!.user;

  switch (status) {
    case CandidateStatus.CANDIDATE:
      return (
        <Text small muted>
          Noch nicht eingeladen.
        </Text>
      );
    case CandidateStatus.ACCEPTED:
      return (
        <Text small intent={Intent.SUCCESS}>
          Bestätigt!
        </Text>
      );
    case CandidateStatus.NO_EMAIL:
      return (
        <Fragment>
          <Text small>{user.phone || user.mobile || '-'} </Text>
          <Text small muted>
            (keine Email)
          </Text>
        </Fragment>
      );
    case CandidateStatus.EMAIL_OPENED:
      return (
        <Text small muted>
          Einladungsmail gelesen.
        </Text>
      );
    case CandidateStatus.EMAIL_SENT:
      return (
        <Text small muted>
          Einladungsmail verschickt.
        </Text>
      );
    case CandidateStatus.EMAIL_REJECTED:
      return (
        <Text small intent={Intent.WARNING}>
          Einladungsmail abgewiesen!
        </Text>
      );
    case CandidateStatus.EMAIL_ADDRESS_INVALID:
      return (
        <Text small intent={Intent.WARNING}>
          E-Mail Adresse ungültig!
        </Text>
      );
    default:
      return (
        <Text small intent={Intent.WARNING}>
          Unbekannter Fehler!
        </Text>
      );
  }
};

const getIndicatorColors = (status: CandidateStatus): { iconColor: string; indicatorColor: string } => {
  const successStatuses = [CandidateStatus.ACCEPTED];
  const warningStatuses = [
    CandidateStatus.EMAIL_REJECTED,
    CandidateStatus.EMAIL_ADDRESS_INVALID,
    CandidateStatus.EMAIL_UNKNOWN_ERROR,
    CandidateStatus.UNKNOWN,
  ];

  if (successStatuses.includes(status)) {
    return {
      indicatorColor: Colors.GREEN3,
      iconColor: Colors.LIGHT_GRAY5,
    };
  }

  if (warningStatuses.includes(status)) {
    return {
      indicatorColor: Colors.ORANGE3,
      iconColor: Colors.LIGHT_GRAY5,
    };
  }

  return {
    indicatorColor: Colors.LIGHT_GRAY5,
    iconColor: Colors.GRAY1,
  };
};

const CandidateListItem = ({ id, isInGroup, isInTeam, dispatch }: CandidateListItemProps) => {
  const { data, loading, error } = useCandidateSignupQuery({
    variables: {
      id,
    },
  });

  if (loading || error || !data || !data.signup) {
    return null;
  }

  const { signup } = data;
  const attendee = signup.soul ? signup.soul : signup.companion!;
  const isSoul = !!signup.soul;
  const { user, organisation } = attendee;
  const status = getCandidateStatus(signup);
  const { indicatorColor, iconColor } = getIndicatorColors(status);

  const remove = () => {
    if (isInGroup) {
      return dispatch({
        type: isSoul ? 'removeGroupMember' : 'removeGroupMember',
        payload: signup.id,
      });
    }

    if (isInTeam) {
      return dispatch({
        type: isSoul ? 'removeTeamMember' : 'removeTeam',
        payload: signup.id,
      });
    }

    return dispatch({
      type: isSoul ? 'removeLoneSoul' : 'removeLoneCompanion',
      payload: signup.id,
    });
  };

  return (
    <div css={styles.container}>
      <div css={styles.indicator(indicatorColor)}>
        <Tooltip content={isSoul ? 'Genießer' : 'Begleiter'}>
          <Icon icon={isSoul ? IconNames.HEART : IconNames.HAND} color={iconColor} />
        </Tooltip>
      </div>
      <div css={styles.name}>
        {user!.display_name}
        <br />
        {!!organisation && (
          <Text muted small>
            {organisation.name}
          </Text>
        )}
      </div>
      <div css={styles.status}>
        <PersonIconList person={attendee} />
        <br />
        <CandidateStatusText item={signup} status={status} />
      </div>
      <div css={styles.action}>
        <Tooltip content="Entfernen">
          <Button small icon={IconNames.SMALL_CROSS} onClick={remove} />
        </Tooltip>
      </div>
    </div>
  );
};

CandidateListItem.fragments = {
  candidate: gql`
    fragment CandidateListItem on Signup {
      id
      event_id
      token
      is_candidate
      is_invited
      has_accepted
      has_rejected
      notes
      priority
      emails {
        id
        status
        opened_first_at
      }
      companion {
        id
        notes
        can_blind
        can_wheelchair
        can_wheeled_walker
        can_drive
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
        user {
          id
          display_name
          email
          phone
          mobile
          lat
          lng
        }
      }
      soul {
        id
        notes
        buys_ticket
        needs_blind
        needs_wheelchair
        needs_wheeled_walker
        needs_drive
        needs_trainstation_fetch
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
        user {
          id
          display_name
          email
          phone
          mobile
          lat
          lng
        }
      }
    }
  `,
};

export default CandidateListItem;

const styles = {
  container: css`
    min-height: 55px;
    display: flex;
    align-items: center;
    &:not(:first-of-type) {
      box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
      div:first-of-type {
        box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
      }
    }
  `,
  indicator: (color: string) => css`
    width: 40px;
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    background-color: ${color};
  `,
  name: css`
    padding: 6px 11px;
    width: calc(50% - 40px);
  `,
  status: css`
    padding: 6px 11px;
    width: calc(50% - 40px);
  `,
  action: css`
    width: 40px;
  `,
};
