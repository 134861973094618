import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import {
  CompanionCreateFormFragment,
  useCreateCompanionMutation,
  UserGender,
  UserStatus,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import CompanionForm from './CompanionForm';

type CompanionCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const CompanionCreateOverlay = ({ onSuccess, ...overlayProps }: CompanionCreateOverlayProps) => {
  const [createCompanion] = useCreateCompanionMutation();
  const handleSubmit = useSubmit({
    mutate: (values: CompanionCreateFormFragment) =>
      createCompanion({
        variables: {
          input: {
            ...values,
            user: {
              create: values.user,
            },
            tags: {
              sync: values.tags.map(tag => tag.id),
            },
            organisation: {
              connect: values.organisation ? values.organisation.id : null,
              disconnect: !values.organisation,
            },
          },
        },
      }),
    onCompleted: ({ data }) => {
      if (data && data.createCompanion) {
        onSuccess(data.createCompanion.id);
      }
    },
    getErrors: getLaravelValidationErrors,
  });

  const initialValues: CompanionCreateFormFragment = {
    can_blind: false,
    can_drive: false,
    can_wheelchair: false,
    can_wheeled_walker: false,
    user: {
      status: UserStatus.Active,
      first_name: '',
      last_name: '',
      gender: UserGender.Female,
    },
    organisation: null,
    tags: [],
  };

  return (
    <ContentOverlay {...overlayProps}>
      <CompanionForm<CompanionCreateFormFragment>
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default CompanionCreateOverlay;
