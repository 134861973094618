/** @jsx jsx */
import { Colors, Intent } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import CategoryTag from '../../../components/CategoryTag';
import Text from '../../../components/Text';
import { EventStatus, FutureSignupListItemFragment } from '../../../generated/graphql';
import { getSignupStatus, SignupStatus } from '../../../helpers/dataUtils';
import { formatDay } from '../../../helpers/utils';

export type FutureSignupListItemProps = {
  signup: FutureSignupListItemFragment;
  skeleton?: boolean;
};

const renderSignupStatus = (signup: FutureSignupListItemFragment, skeleton: boolean | undefined) => {
  if (signup.event.status === EventStatus.Cancelled) {
    return (
      <Text small muted skeleton={skeleton}>
        Veranstaltung abgesagt
      </Text>
    );
  }

  if (signup.event.status === EventStatus.Draft) {
    return (
      <Text small muted skeleton={skeleton}>
        Unbekannter Fehler
      </Text>
    );
  }

  if (!signup.event.candidates_chosen) {
    return (
      <Text small muted skeleton={skeleton}>
        Noch keine Teilnehmerliste
      </Text>
    );
  }

  switch (getSignupStatus(signup)) {
    case SignupStatus.ACCEPTED:
      return (
        <Text small intent={Intent.SUCCESS} skeleton={skeleton}>
          Zusage
        </Text>
      );
    case SignupStatus.REJECTED:
      return (
        <Text small intent={Intent.DANGER} skeleton={skeleton}>
          Absage
        </Text>
      );
    case SignupStatus.PROSPECT:
      return (
        <Text small muted skeleton={skeleton}>
          Interessent
        </Text>
      );
    case SignupStatus.CANDIDATE:
      return (
        <Text small skeleton={skeleton}>
          Kandidat
        </Text>
      );
    default:
      return (
        <Text small intent={Intent.PRIMARY} skeleton={skeleton}>
          Warte auf Zusage
        </Text>
      );
  }
};

const FutureSignupListItem = ({ signup, skeleton }: FutureSignupListItemProps) => (
  <tr>
    <td css={styles.tableCell}>
      {signup.event.categories.map(category => (
        <CategoryTag key={category.id} category={category} skeleton={skeleton} />
      ))}
      <Text skeleton={skeleton}>{signup.event.name}</Text>
    </td>
    <td css={styles.tableCell}>{renderSignupStatus(signup, skeleton)}</td>
    <td css={styles.tableCell}>
      <Text skeleton={skeleton}>{formatDay(signup.event.start)}</Text>
    </td>
  </tr>
);

FutureSignupListItem.fragments = {
  futureSignup: gql`
    fragment FutureSignupListItem on Signup {
      id
      is_candidate
      is_invited
      has_accepted
      has_rejected
      emails {
        id
        status
        opened_first_at
      }
      event {
        id
        name
        status
        candidates_chosen
        start
        categories {
          id
          name
          color
        }
      }
    }
  `,
};

export default FutureSignupListItem;

const styles = {
  tableCell: css`
    vertical-align: middle !important;
    border-top: 1px ${Colors.LIGHT_GRAY1} solid;
    height: 55px;
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;
    }
    tr:first-of-type & {
      border-top: none;
    }
    tr:hover & {
      background-color: ${Colors.LIGHT_GRAY5} !important;
    }
  `,
  tableActionCell: css`
    text-align: right !important;
  `,
  resourceLink: css`
    margin-right: 5px;
  `,
  buttonGroup: css`
    display: flex;
  `,
  actionButton: css`
    margin-right: 5px;
  `,
  statusContainer: css`
    display: flex;
    justify-content: space-between;
  `,
};
