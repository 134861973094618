import { HTMLSelect, IHTMLSelectProps, IOptionProps } from '@blueprintjs/core';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';

interface ISelectInputProps extends IHTMLSelectProps {
  name: string;
  options: Array<IOptionProps>;
}

const SelectInput = (props: ISelectInputProps) => {
  const [{ multiple, ...otherFieldProps }] = useField(props.name);
  const fieldRef = useRef<HTMLSelectElement>();
  const formik = useFormikContext();

  return (
    <HTMLSelect
      {...props}
      {...otherFieldProps}
      elementRef={ref => (fieldRef.current = ref || undefined)}
      disabled={props.disabled || formik.isSubmitting}
    />
  );
};

export default SelectInput;
