import React from 'react';
import { EventDetailPageFragment } from '../../../generated/graphql';
import AttendeeGroup from './AttendeeGroup';
import AttendeeLoneCompanion from './AttendeeLoneCompanion';
import AttendeeLoneSoul from './AttendeeLoneSoul';
import AttendeeTeam from './AttendeeTeam';

type AttendeeListProps = {
  event: EventDetailPageFragment;
};

const AttendeeList = ({ event }: AttendeeListProps) => {
  if (!event.candidates_chosen) {
    return null;
  }

  return (
    <div>
      {event.groups.map(group => (
        <AttendeeGroup key={group.id} item={group} />
      ))}
      {event.teams.map(team => (
        <AttendeeTeam key={team.id} item={team} />
      ))}
      {event.loneSouls.map(loneSoul => (
        <AttendeeLoneSoul key={loneSoul.id} item={loneSoul} />
      ))}
      {event.loneCompanions.map(loneCompanion => (
        <AttendeeLoneCompanion key={loneCompanion.id} item={loneCompanion} />
      ))}
    </div>
  );
};

export default AttendeeList;
