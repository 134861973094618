import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import {
  AllotmentEditFormFragment,
  useAllotmentEditLazyQuery,
  useUpdateAllotmentMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import AllotmentForm from './AllotmentForm';

interface IAllotmentEditOverlayProps extends IOverlayProps {
  id: string;
}

const AllotmentEditOverlay = ({ id, ...overlayProps }: IAllotmentEditOverlayProps) => {
  const [loadAllotment, { data, called, loading, error }] = useAllotmentEditLazyQuery({
    variables: {
      id,
    },
  });
  const [updateAllotment] = useUpdateAllotmentMutation();
  const handleSubmit = useSubmit({
    mutate: (values: AllotmentEditFormFragment) => {
      const { status, amount_left, ...input } = values;
      return updateAllotment({
        variables: {
          input,
        },
      });
    },
    onCompleted: () => {
      loadAllotment(); // Have to execute query again since it's a lazy one
      overlayProps.onClose && overlayProps.onClose();
    },
    getErrors: getLaravelValidationErrors,
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadAllotment();
  }

  const renderContent = () => {
    if (loading) {
      return <LoadingCard />;
    }

    if (error || !data || !data.allotment) {
      return <ErrorCard />;
    }

    return (
      <AllotmentForm<AllotmentEditFormFragment>
        isEdit
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={data.allotment}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default AllotmentEditOverlay;
