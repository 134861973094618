/** @jsx jsx */
import { Classes, Colors, Icon, IconName, Position, Tooltip } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Intent, PersonTagIconFragment } from '../generated/graphql';

type PersonTagIconProps = {
  tag: PersonTagIconFragment;
  position?: Position;
  skeleton?: boolean;
};

const PersonTagIcon = ({ tag, position, skeleton }: PersonTagIconProps) => (
  <Tooltip content={tag.name} position={position} disabled={skeleton}>
    <Icon
      icon={tag.icon as IconName}
      intent={tag.intent}
      color={tag.intent && tag.intent !== Intent.None ? undefined : Colors.GRAY1}
      className={skeleton ? Classes.SKELETON : ''}
      css={styles.icon}
    />
  </Tooltip>
);

PersonTagIcon.fragments = {
  tag: gql`
    fragment PersonTagIcon on Tag {
      id
      name
      icon
      intent
    }
  `,
};

export default PersonTagIcon;

const styles = {
  icon: css`
    margin-right: 5px;
  `,
};
