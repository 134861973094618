/** @jsx jsx */
import { Button, Elevation, HTMLSelect, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import Filter from '../../../components/Filter';
import FilterItem from '../../../components/FilterItem';
import { EventStatus } from '../../../generated/graphql';
import EventCreateOverlay from '../components/EventCreateOverlay';
import EventList from '../components/EventList';

const EventListPage = () => {
  const history = useHistory();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const onCreated = (modelId: string) => {
    AppToaster.success('Veranstaltung hinzugefügt!');
    history.push(`/events/${modelId}`);
  };

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.list}>
      <EventCreateOverlay
        isOpen={isCreateDialogOpen}
        onSuccess={onCreated}
        onClose={() => setIsCreateDialogOpen(false)}
      />
      <ContentCardHeader
        leftElement={
          <Filter keys={['status']}>
            <FilterItem name="status" label="Status" defaultValue={EventStatus.Draft}>
              {({ value, onChange }) => (
                <HTMLSelect
                  value={value || undefined}
                  onChange={onChange}
                  options={[
                    {
                      label: 'Unveröffentlicht',
                      value: EventStatus.Draft,
                    },
                    {
                      label: 'Veröffentlicht',
                      value: EventStatus.Published,
                    },
                    {
                      label: 'Teilnehmer eingeladen',
                      value: EventStatus.Closed,
                    },
                    {
                      label: 'Abgesagt',
                      value: EventStatus.Cancelled,
                    },
                  ]}
                />
              )}
            </FilterItem>
          </Filter>
        }
        rightElement={
          <Button
            text="Hinzufügen"
            icon={IconNames.ADD}
            intent={Intent.PRIMARY}
            css={styles.headerButton}
            onClick={() => setIsCreateDialogOpen(true)}
          />
        }
      />
      <EventList />
    </ContentCard>
  );
};

export default EventListPage;

const styles = {
  headerButton: css`
    margin-left: 10px;
  `,
  filterButtonTitle: css`
    display: flex;
  `,
  list: css`
    margin-bottom: 20px;
  `,
};
