import { Menu, MenuDivider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import NavMenuItem from './NavMenuItem';

const Sidebar = () => (
  <div style={styles.sidebar}>
    <Menu style={styles.menu}>
      <NavMenuItem icon={IconNames.DASHBOARD} text="Übersicht" path="/" exact />
      <NavMenuItem icon={IconNames.SHOP} text="Instanzen" path="/instances" />
      <MenuDivider />
      <NavMenuItem icon={IconNames.TIMELINE_EVENTS} text="Veranstaltungen" path="/events">
        <NavMenuItem icon={IconNames.PROPERTIES} text="Übersicht" path="/events" exact />
        <NavMenuItem icon={IconNames.PROJECTS} text="Archiv" path="/events/archive" />
        <NavMenuItem icon={IconNames.HORIZONTAL_BAR_CHART} text="Kontingente" path="/allotments" />
        <NavMenuItem icon={IconNames.TAG} text="Kategorien" path="/categories" />
      </NavMenuItem>
      <NavMenuItem icon={IconNames.ENVELOPE} text="Newsletter" path="/newsletter">
        <NavMenuItem icon={IconNames.PROPERTIES} text="Übersicht" path="/newsletter" exact />
        <NavMenuItem icon={IconNames.PROJECTS} text="Archiv" path="/newsletter/archive" />
      </NavMenuItem>
      <MenuDivider />

      <NavMenuItem icon={IconNames.HEART} text="Genießer" path="/souls" />
      <NavMenuItem icon={IconNames.HAND} text="Begleiter" path="/companions" />
      <NavMenuItem icon={IconNames.HOME} text="Organisationen" path="/organisations" />

      <MenuDivider />
      <NavMenuItem icon={IconNames.BANK_ACCOUNT} text="Spender" path="/donors" />
      <NavMenuItem icon={IconNames.MAP_MARKER} text="Lokationen" path="/locations" />

      <MenuDivider />
      <NavMenuItem icon={IconNames.PERSON} text="Mitarbeiter" path="/employees">
        <NavMenuItem icon={IconNames.PROPERTIES} text="Übersicht" path="/employees" exact />
        <NavMenuItem icon={IconNames.BADGE} text="Rollen" path="/roles" />
      </NavMenuItem>
      <NavMenuItem icon={IconNames.CHART} text="Statistik" path="/stats" />
      <NavMenuItem icon={IconNames.SETTINGS} text="Einstellungen" path="/settings">
        <NavMenuItem icon={IconNames.SETTINGS} text="Einstellungen" path="/settings" exact />
        <NavMenuItem icon={IconNames.CONSOLE} text="API-Aufrufe" path="/settings/api-calls" />
      </NavMenuItem>
    </Menu>
  </div>
);

export default Sidebar;

const styles = {
  sidebar: {
    width: 200,
    position: 'fixed' as 'fixed',
    minHeight: '100vh',
    display: 'flex',
  },
  menu: {
    backgroundColor: 'transparent',
    marginTop: 50,
  },
};
