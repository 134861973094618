import { FetchResult } from '@apollo/client';
import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import {
  CreateContactInput,
  CreateContactMutation,
  useCreateContactMutation,
  UserGender,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import ContactForm from './ContactForm';

type ContactCreateOverlayProps = {
  donorId: string;
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const ContactCreateOverlay = ({ donorId, onSuccess, ...overlayProps }: ContactCreateOverlayProps) => {
  const [createContact] = useCreateContactMutation();
  const handleSubmit = useSubmit<CreateContactInput, FetchResult<CreateContactMutation>>({
    mutate: values =>
      createContact({
        variables: {
          input: values,
        },
      }),
    onCompleted: ({ data }) => {
      if (data && data.createContact) {
        onSuccess(data.createContact.id);
      }
    },
    getErrors: getLaravelValidationErrors,
  });

  const initialValues: CreateContactInput = {
    donor_id: donorId,
    user: {
      create: {
        first_name: '',
        last_name: '',
        gender: UserGender.Female,
      },
    },
  };

  return (
    <ContentOverlay {...overlayProps}>
      <ContactForm<CreateContactInput>
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default ContactCreateOverlay;
