/** @jsx jsx */
import { Colors, Elevation, Icon, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import TrashTag from '../../../components/TrashTag';
import { useLocationDetailQuery } from '../../../generated/graphql';
import LocationDetailMenu from '../components/LocationDetailMenu';
import LocationEditOverlay from '../components/LocationEditOverlay';

const LocationDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { locationId } = useParams();

  const options = {
    variables: {
      id: locationId || '',
    },
  };

  const { data, loading, error } = useLocationDetailQuery(options);

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.location) return <NotFoundCard resource="Lokation" />;

  const { location } = data;

  const address: string | null =
    location.street && location.postal_code && location.city
      ? `${location.street}, ${location.postal_code} ${location.city}`
      : null;

  return (
    <Fragment>
      <LocationEditOverlay id={location.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{location.name}</span>
              <Tag minimal css={styles.personTag}>
                Lokation
              </Tag>
              {!!location.deleted_at && <TrashTag />}
            </Fragment>
          }
          rightElement={<LocationDetailMenu location={location} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div css={styles.content}>
          <PropertyList>
            <PropertyListHeader>Details</PropertyListHeader>
            <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
          </PropertyList>
          <PropertyListDivider />
          <PropertyList>
            <PropertyListHeader>Lokation Info</PropertyListHeader>
            <PropertyListItem label="Barrierefrei">
              <Icon icon={location.accessible ? IconNames.TICK : IconNames.CROSS} />
            </PropertyListItem>
            <PropertyListItem label="Treffpunkt" value={location.meeting_point} />
            <PropertyListItem label="Öff. Nahverkehr" value={location.public_transport} />
          </PropertyList>
        </div>
      </ContentCard>
    </Fragment>
  );
};

LocationDetailPage.fragments = {
  location: gql`
    fragment LocationDetailPage on Location {
      id
      name
      street
      city
      postal_code
      meeting_point
      public_transport
      accessible
      deleted_at
    }
  `,
};

export default LocationDetailPage;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  table: css`
    width: 100%;
  `,
  tableHeadCell: css`
    background-color: ${Colors.LIGHT_GRAY5};
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;
    }
  `,
  overlayContent: css`
    left: calc(50vw - 225px);
    margin: 10vh 0;
    width: 450px;
    max-height: 80vh;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
