/** @jsx jsx */
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { addDays, parseISO } from 'date-fns';
import { Formik, FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import DatePickerInput from '../../../components/DatePickerInput';
import FormGroup from '../../../components/FormGroup';
import NumberInput from '../../../components/NumberInput';
import TextInput from '../../../components/TextInput';

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
  amount: Yup.number()
    .integer('Muss Ganzzahl sein')
    .positive('Muss positiv sein')
    .required('Erforderlich'),
  start: Yup.date()
    .typeError('Muss gültiges Datum sein')
    .required('Erforderlich'),
  end: Yup.date()
    .typeError('Muss gültiges Datum sein')
    .nullable(),
});

interface IAllotmentEditFormProps<T> {
  isEdit?: boolean;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  onCancel?: (event?: any) => void;
  initialValues: T;
}

const AllotmentForm = <T extends { start: string }>({
  isEdit,
  onCancel,
  onSubmit,
  initialValues,
}: IAllotmentEditFormProps<T>) => (
  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ isSubmitting, submitForm, values }) => (
      <ContentCard elevation={Elevation.FOUR}>
        <ContentCardHeader
          leftElement={<span css={styles.heading}>Kontingent {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
          rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
        />

        <ContentCardScroll>
          <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
            <TextInput name="name" placeholder="Name" />
          </FormGroup>
          <FormGroup label="Karten" labelInfo="(erforderlich)" name="amount">
            <NumberInput name="amount" placeholder="Karten" min={1} />
          </FormGroup>
          <FormGroup label="Start" labelInfo="(erforderlich)" name="start">
            <DatePickerInput name="start" />
          </FormGroup>
          <FormGroup label="Ende" name="end" helperText="Frei lassen für unbegrenzte Gültigkeit">
            <DatePickerInput name="end" minDate={addDays(parseISO(values.start), 1)} />
          </FormGroup>
        </ContentCardScroll>

        <ContentCardFooter
          rightElement={
            <Fragment>
              <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
              <Button
                text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                loading={isSubmitting}
                intent={Intent.PRIMARY}
                onClick={submitForm}
                css={styles.footerButton}
              />
            </Fragment>
          }
        />
      </ContentCard>
    )}
  </Formik>
);

AllotmentForm.fragments = {
  create: gql`
    fragment AllotmentCreateForm on Allotment {
      name
      amount
      start
      end
    }
  `,
  edit: gql`
    fragment AllotmentEditForm on Allotment {
      id
      name
      status
      amount
      amount_left
      start
      end
    }
  `,
};

export default AllotmentForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
