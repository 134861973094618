import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { DonorCreateFormFragment, useCreateDonorMutation } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import DonorForm from './DonorForm';

type DonorCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const DonorCreateOverlay = ({ onSuccess, ...overlayProps }: DonorCreateOverlayProps) => {
  const [createDonor] = useCreateDonorMutation();
  const handleSubmit = useSubmit({
    mutate: (values: DonorCreateFormFragment) =>
      createDonor({
        variables: {
          input: values,
        },
      }),
    onCompleted: ({ data }) => {
      if (data && data.createDonor) {
        onSuccess(data.createDonor.id);
      }
    },
    getErrors: getLaravelValidationErrors,
  });

  const initialValues: DonorCreateFormFragment = {
    name: '',
  };

  return (
    <ContentOverlay {...overlayProps}>
      <DonorForm<DonorCreateFormFragment>
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default DonorCreateOverlay;
