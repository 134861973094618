/** @jsx jsx */
import { Button, Intent, Menu, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemListRenderer, ItemRenderer, Select } from '@blueprintjs/select';
import { css, jsx } from '@emotion/core';
import algoliasearch from 'algoliasearch/lite';
import { getIn, useField, useFormikContext } from 'formik';
import { useRef } from 'react';
import { AutocompleteProvided, Configure, Hit } from 'react-instantsearch-core';
import { connectAutoComplete, InstantSearch } from 'react-instantsearch-dom';
import config from '../helpers/config';
import { getBranchId } from '../helpers/constants';
import { useFocusOnError } from '../helpers/forms';

type DropdownSearchInputComponentProps<T> = {
  name: string;
  resets?: string[];
  canClear?: boolean;
  labelPath: string;
  renderItem: (hit: Hit<T>) => React.ReactNode;
};

const DropdownSearchInputComponent = <T extends { id: number }>({
  name,
  resets = [],
  canClear = true,
  renderItem,
  labelPath,
  hits,
  refine,
  currentRefinement,
}: DropdownSearchInputComponentProps<T> & AutocompleteProvided<T>) => {
  const [field, meta] = useField<Hit<T>>(name);
  const fieldRef = useRef<HTMLElement>();
  const formik = useFormikContext<any>();
  useFocusOnError({ fieldRef, name });

  const itemListRenderer: ItemListRenderer<Hit<T>> = ({ items, itemsParentRef, renderItem }) => {
    const renderedItems = items.map(renderItem).filter(item => item != null);
    return (
      <Menu ulRef={itemsParentRef} css={styles.menu}>
        {renderedItems}
      </Menu>
    );
  };

  const itemRenderer: ItemRenderer<Hit<T>> = (item, { handleClick, modifiers }) =>
    !modifiers.matchesPredicate ? null : (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.objectID}
        onClick={handleClick}
        text={renderItem(item)}
      />
    );

  const getLabel = (item: Hit<T>) => getIn(item, labelPath);

  const onSelect = (item: Hit<T> | null) => {
    formik.setFieldValue(name, item);
    resets.forEach(reset => formik.setFieldValue(reset, null));
  };

  return (
    <div css={styles.selectConainer}>
      <Select<Hit<T>>
        disabled={formik.isSubmitting}
        items={hits}
        itemListRenderer={itemListRenderer}
        itemListPredicate={(_, items) => items} // Filtering done by Algolia obviously
        itemRenderer={itemRenderer}
        query={currentRefinement}
        onQueryChange={refine}
        noResults={<MenuItem disabled={true} text="Keine Resultate." />}
        onItemSelect={onSelect}
        inputProps={{
          intent: meta.error && meta.touched ? Intent.DANGER : Intent.NONE,
        }}
        popoverProps={{
          minimal: true,
        }}
      >
        {/* children become the popover target; render value here */}
        <Button
          disabled={formik.isSubmitting}
          text={field.value ? getLabel(field.value) : '—'}
          rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
          intent={meta.error && meta.touched ? Intent.DANGER : Intent.NONE}
          elementRef={ref => (fieldRef.current = ref || undefined)}
        />
      </Select>
      {canClear && !!field.value && (
        <Button
          disabled={formik.isSubmitting}
          minimal
          icon={IconNames.DELETE}
          onClick={() => onSelect(null)}
          css={styles.clearButton}
        />
      )}
    </div>
  );
};

type DropdownSearchInputProps<T> = {
  index: string;
  canClear?: boolean;
  filters?: string;
} & DropdownSearchInputComponentProps<T>;

const DropdownSearchInput = <T extends { id: number }>({
  index,
  filters,
  ...componentProps
}: DropdownSearchInputProps<T>) => {
  const ConnectedDropdownSearchInputComponent = connectAutoComplete<
    DropdownSearchInputComponentProps<T> & AutocompleteProvided<T>,
    T
  >(DropdownSearchInputComponent);
  const searchClient = useRef(algoliasearch(config.algolia.appId, config.algolia.appSecret)).current;

  return (
    <InstantSearch searchClient={searchClient} indexName={index}>
      <ConnectedDropdownSearchInputComponent {...componentProps} />
      <Configure hitsPerPage={12} filters={`branch_id=${getBranchId()}` + filters ? ` AND ${filters}` : ''} />
    </InstantSearch>
  );
};

export default DropdownSearchInput;

const styles = {
  menu: css`
    max-height: 300px;
    max-width: 400px;
    overflow: auto;
  `,
  clearButton: css`
    margin-left: 10px;
  `,
  selectConainer: css`
    display: flex;
  `,
};
