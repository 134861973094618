import { useQuery } from '@apollo/client';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { IS_AUTHENTICATED } from '../modules/auth/queries';

interface IPrivateRoute extends RouteProps {
  component?: any; // TODO: Find out how to fix this
  unauthenticatedRedirect?: string;
}

const PrivateRoute = ({ component: Component, unauthenticatedRedirect, ...rest }: IPrivateRoute) => {
  const { data } = useQuery(IS_AUTHENTICATED);

  if (!data) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props: RouteProps) =>
        data.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: unauthenticatedRedirect || '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
