import { IOptionProps } from '@blueprintjs/core';
import { EventTicketRetrievalType, EventTicketType, SoulTargetGroup, UserGender } from '../generated/graphql';

let branchId = localStorage.getItem('branch_id');

console.log('env:', process.env.REACT_APP_ENV);

export enum AppEnv {
  Dev = 'DEV',
  Stage = 'STAGE',
  Prod = 'PROD',
}

export const ENV = process.env.REACT_APP_ENV as AppEnv;

export const getBranchId = () => {
  if (!branchId) {
    branchId = localStorage.getItem('branch_id');
  }
  return branchId || 0;
};

export const TICKET_TYPE_OPTIONS: IOptionProps[] = [
  {
    value: EventTicketType.Unlimited,
    label: 'Freier Eintritt',
  },
  {
    value: EventTicketType.Fixed,
    label: 'Feste Anzahl für diese Veranstaltung',
  },
  {
    value: EventTicketType.Allotment,
    label: 'Kontingent nutzen',
  },
];

export const TICKET_RETRIEVAL_TYPE_OPTIONS: IOptionProps[] = [
  {
    value: EventTicketRetrievalType.Delivery,
    label: 'Zustellung per Post',
  },
  {
    value: EventTicketRetrievalType.Onsite,
    label: 'Abholen am Veranstaltungsort',
  },
];

export const USER_GENDER_OPTIONS: IOptionProps[] = [
  {
    value: UserGender.Female,
    label: 'Weiblich',
  },
  {
    value: UserGender.Male,
    label: 'Männlich',
  },
];

export const SOUL_TARGET_GROUP_OPTIONS: IOptionProps[] = [
  {
    value: SoulTargetGroup.Disabled,
    label: 'Behinderung',
  },
  {
    value: SoulTargetGroup.Child,
    label: 'Kind',
  },
  {
    value: SoulTargetGroup.Immigrant,
    label: 'Migrationshintergrund',
  },
  {
    value: SoulTargetGroup.Senior,
    label: 'Senior',
  },
  {
    value: SoulTargetGroup.SociallyInNeed,
    label: 'Sozial bedürftig',
  },
  {
    value: SoulTargetGroup.Refugee,
    label: 'Flüchtling',
  },
  {
    value: '',
    label: '—',
  },
];
