import gql from 'graphql-tag';
import { EventDetailPageFragment, EventListItemFragment } from './generated/graphql';

// **********************************************************************
//
//  ATTENTION:  Any changes here must also be respected in client.graphql
//              so graphql code generation continues to work
//
// **********************************************************************

export const typeDefs = gql`
  extend type Query {
    isAuthenticated: Boolean!
  }

  extend type Event {
    prospectCount: Int!
    candidateCount: Int!
    inviteeCount: Int!
    attendeeCount: Int!
    prospects: [Signup!]!
    candidates: [Signup!]!
    invitees: [Signup!]!
    attendees: [Signup!]!
    groups: [Signup!]!
    teams: [Signup!]!
    loneSouls: [Signup!]!
    loneCompanions: [Signup!]!
  }
`;

interface Signup {
  is_candidate: boolean;
  is_invited: boolean;
  has_accepted: boolean;
}

interface HasSignups {
  signups: Signup[];
}

export const resolvers = {
  Event: {
    prospectCount: (event: HasSignups) => event.signups.filter(signup => !signup.is_candidate).length,
    candidateCount: (event: HasSignups) => event.signups.filter(signup => signup.is_candidate).length,
    inviteeCount: (event: HasSignups) =>
      event.signups.filter(signup => signup.is_candidate && signup.is_invited).length,
    attendeeCount: (event: HasSignups) =>
      event.signups.filter(signup => signup.is_candidate && signup.is_invited && signup.has_accepted).length,
    prospects: (event: EventListItemFragment) => event.signups.filter(signup => !signup.is_candidate),
    candidates: (event: EventListItemFragment) => event.signups.filter(signup => signup.is_candidate),
    invitees: (event: EventListItemFragment) =>
      event.signups.filter(signup => signup.is_candidate && signup.is_invited),
    attendees: (event: EventListItemFragment) =>
      event.signups.filter(signup => signup.is_candidate && signup.is_invited && signup.has_accepted),
    groups: (event: EventDetailPageFragment) =>
      event.signups.filter(
        signup => signup.is_candidate && signup.group.length && signup.companion && signup.companion.organisation,
      ),
    teams: (event: EventDetailPageFragment) =>
      event.signups.filter(
        signup => signup.is_candidate && signup.group.length && signup.companion && !signup.companion.organisation,
      ),
    loneSouls: (event: EventDetailPageFragment) =>
      event.signups.filter(signup => signup.is_candidate && signup.soul && !signup.leader.length),
    loneCompanions: (event: EventDetailPageFragment) =>
      event.signups.filter(signup => signup.is_candidate && signup.companion && !signup.group.length),
  },
};
