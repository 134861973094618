/** @jsx jsx */
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik, FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import PersonMultiSelect from '../../../components/PersonMultiSelect';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import { PersonType } from '../../../types';

export type OrganisationFormValues = {
  id?: string;
  name: string;
  description?: string | null;
  souls: { id: string; display_name: string }[];
  companions: { id: string; display_name: string }[];
};

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
});

type OrganisationFormProps = {
  onSubmit: (values: OrganisationFormValues, formikHelpers: FormikHelpers<OrganisationFormValues>) => void;
  onCancel?: (event?: any) => void;
  initialValues: OrganisationFormValues;
};

const isEdit = (values: OrganisationFormValues) => 'id' in values;

const OrganisationForm = ({ onCancel, onSubmit, initialValues }: OrganisationFormProps) => (
  <Formik<OrganisationFormValues> initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ isSubmitting, submitForm, values }) => (
      <ContentCard elevation={Elevation.FOUR}>
        <ContentCardHeader
          leftElement={<span css={styles.heading}>Organisation {isEdit(values) ? 'bearbeiten' : 'hinzufügen'}</span>}
          rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
        />

        <ContentCardScroll>
          <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
            <TextInput name="name" placeholder="Name" />
          </FormGroup>
          <FormGroup name="description">
            <TextAreaInput name="description" placeholder="Beschreibung" fill rows={10} />
          </FormGroup>

          <Divider css={styles.formDivider} />

          <FormGroup label="Begleiter" labelInfo="(erforderlich)" name="group.souls">
            <PersonMultiSelect
              name="companions"
              type={PersonType.Companion}
              filters={isEdit(values) ? `organisation_id=${values.id} OR organisation_id=-1` : 'organisation_id=-1'}
            />
          </FormGroup>
          <FormGroup label="Genießer" labelInfo="(erforderlich)" name="group.souls">
            <PersonMultiSelect
              name="souls"
              type={PersonType.Soul}
              filters={isEdit(values) ? `organisation_id=${values.id} OR organisation_id=-1` : 'organisation_id=-1'}
            />
          </FormGroup>
        </ContentCardScroll>

        <ContentCardFooter
          rightElement={
            <Fragment>
              <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
              <Button
                text={isEdit(values) ? 'Änderungen Sichern' : 'Hinzufügen'}
                loading={isSubmitting}
                intent={Intent.PRIMARY}
                onClick={submitForm}
                css={styles.footerButton}
              />
            </Fragment>
          }
        />
      </ContentCard>
    )}
  </Formik>
);

OrganisationForm.fragments = {
  create: gql`
    fragment OrganisationCreateForm on Organisation {
      name
      description
      souls {
        id
      }
      companions {
        id
      }
    }
  `,
  edit: gql`
    fragment OrganisationEditForm on Organisation {
      id
      name
      description
      souls {
        id
        user {
          id
          display_name
        }
      }
      companions {
        id
        user {
          id
          display_name
        }
      }
      deleted_at
    }
  `,
};

export default OrganisationForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
