import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { useCreateOrganisationMutation } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import OrganisationForm, { OrganisationFormValues } from './OrganisationForm';

type OrganisationCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const OrganisationCreateOverlay = ({ onSuccess, ...overlayProps }: OrganisationCreateOverlayProps) => {
  const [createOrganisation] = useCreateOrganisationMutation();
  const handleSubmit = useSubmit({
    mutate: (values: OrganisationFormValues) =>
      createOrganisation({
        variables: {
          input: {
            ...values,
            souls: {
              sync: values.souls.map(soul => soul.id.toString()),
            },
            companions: {
              sync: values.companions.map(companion => companion.id.toString()),
            },
          },
        },
      }),
    onCompleted: ({ data }) => {
      if (data && data.createOrganisation) {
        onSuccess(data.createOrganisation.id);
      }
    },
    getErrors: getLaravelValidationErrors,
  });

  const initialValues = {
    name: '',
    souls: [],
    companions: [],
  };

  return (
    <ContentOverlay {...overlayProps}>
      <OrganisationForm onCancel={overlayProps.onClose} onSubmit={handleSubmit} initialValues={initialValues} />
    </ContentOverlay>
  );
};

export default OrganisationCreateOverlay;
