import { Intent, INumericInputProps, NumericInput } from '@blueprintjs/core';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { useFocusOnError } from '../helpers/forms';

interface INumberInputProps extends INumericInputProps {
  name: string;
}

const NumberInput = (props: INumberInputProps) => {
  const [field, meta] = useField<number>(props.name);
  const fieldRef = useRef<HTMLInputElement>();
  const formik = useFormikContext<any>();
  useFocusOnError({ fieldRef, name: props.name });

  return (
    <NumericInput
      {...props}
      inputRef={ref => (fieldRef.current = ref || undefined)}
      onValueChange={number => formik.setFieldValue(props.name, number || null)}
      onBlur={field.onBlur}
      value={field.value || ''}
      minorStepSize={null}
      disabled={props.disabled || formik.isSubmitting}
      intent={meta.error && meta.touched ? Intent.DANGER : props.intent || Intent.NONE}
    />
  );
};

export default NumberInput;
