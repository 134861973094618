/** @jsx jsx */
import { Classes, Colors, Icon, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import Text from './Text';

type PersonNotesIconProps = {
  notes: string;
  position?: Position;
  skeleton?: boolean;
};

const PersonNotesIcon = ({ notes, position, skeleton }: PersonNotesIconProps) => (
  <Popover interactionKind={PopoverInteractionKind.HOVER} hoverOpenDelay={0} position={position} disabled={skeleton}>
    <Icon
      icon={IconNames.INFO_SIGN}
      color={Colors.GRAY1}
      className={skeleton ? Classes.SKELETON : ''}
      css={styles.icon}
    />
    <div css={styles.notes}>
      <Text small running>
        {notes}
      </Text>
    </div>
  </Popover>
);

export default PersonNotesIcon;

const styles = {
  icon: css`
    margin-right: 5px;
  `,
  notes: css`
    padding: 16px 20px;
    max-width: 500px;
  `,
};
