import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import {
  EmployeeEditFormFragment,
  useEmployeeEditLazyQuery,
  useUpdateEmployeeMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import EmployeeEditForm from './EmployeeEditForm';

interface IEmployeeEditOverlayProps extends IOverlayProps {
  id: string;
}

const EmployeeEditOverlay = ({ id, ...overlayProps }: IEmployeeEditOverlayProps) => {
  const [loadEmployee, { data, called, loading, error }] = useEmployeeEditLazyQuery({
    variables: {
      id,
    },
  });
  const [updateEmployee] = useUpdateEmployeeMutation();
  const handleSubmit = useSubmit({
    mutate: (values: EmployeeEditFormFragment) =>
      updateEmployee({
        variables: {
          input: {
            ...values,
            user: {
              update: {
                ...values.user,
                roles: {
                  sync: values.user.roles.map(role => role.id),
                },
              },
            },
          },
        },
      }),
    onCompleted: () => {
      loadEmployee(); // Have to execute query again since it's a lazy one
      overlayProps.onClose && overlayProps.onClose();
    },
    getErrors: getLaravelValidationErrors,
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadEmployee();
  }

  const renderContent = () => {
    if (loading) {
      return <LoadingCard />;
    }

    if (error || !data || !data.employee) {
      return <ErrorCard />;
    }

    return <EmployeeEditForm onCancel={overlayProps.onClose} onSubmit={handleSubmit} initialValues={data.employee} />;
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default EmployeeEditOverlay;
