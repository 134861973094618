import { IInputGroupProps, InputGroup, Intent } from '@blueprintjs/core';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { useFocusOnError } from '../helpers/forms';

interface ITextInputProps extends IInputGroupProps {
  name: string;
}

const TextInput = (props: ITextInputProps) => {
  const formik = useFormikContext();
  const fieldRef = useRef<HTMLInputElement>();
  useFocusOnError({ fieldRef, name: props.name });
  const [field, meta] = useField(props.name);

  return (
    <InputGroup
      {...props}
      {...field}
      inputRef={ref => (fieldRef.current = ref || undefined)} // Blueprint still uses old callback refs
      value={field.value || ''}
      disabled={props.disabled || formik.isSubmitting}
      intent={meta.error && meta.touched ? Intent.DANGER : props.intent || Intent.NONE}
    />
  );
};

export default TextInput;
