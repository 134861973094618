/** @jsx jsx */
import { Button, Colors, Navbar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import SearchBar from '../modules/search/components/SearchBar';

const TopNavBar = () => (
  <Navbar fixedToTop css={styles.navBar}>
    <SearchBar />
    <div css={styles.navBarSide}>
      <Button text="Home" minimal icon={IconNames.HOME} />
    </div>
  </Navbar>
);

export default TopNavBar;

const styles = {
  navBar: css`
    box-shadow: none;
    background-color: ${Colors.LIGHT_GRAY5};
    max-width: 1070px;
    left: auto !important;
    right: auto !important;
    width: calc(100vw - 210px);
    height: 50px;
    padding: 5px 10px 0;
    margin-left: -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  navBarSide: css`
    display: flex;
  `,
};
