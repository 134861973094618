/** @jsx jsx */
import { Checkbox, Collapse, Colors } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useFilterContext } from './Filter';

type FilterItemProps = {
  name: string;
  label: string;
  defaultValue: string;
  children: ({
    value,
    onChange,
  }: {
    value: string | null;
    onChange: (event: React.ChangeEvent<any>) => void;
  }) => React.ReactNode;
};

const FilterItem = ({ name, label, defaultValue, children }: FilterItemProps) => {
  const { filters, setFilterValue } = useFilterContext();
  const isActive = filters[name] !== null && filters[name] !== undefined;

  const onChange = (event: React.ChangeEvent<any>) => setFilterValue(name, event.currentTarget.value);

  return (
    <Fragment>
      <div css={styles.header}>
        <Checkbox
          css={styles.checkbox}
          checked={isActive}
          onChange={event =>
            event.currentTarget.checked ? setFilterValue(name, defaultValue) : setFilterValue(name, null)
          }
          label={label}
        />
      </div>
      <Collapse isOpen={isActive}>
        <div css={styles.content}>{children({ value: filters[name], onChange })}</div>
      </Collapse>
    </Fragment>
  );
};

export default FilterItem;

const styles = {
  header: css`
    padding: 12px;
    box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
  `,
  checkbox: css`
    margin-bottom: 0;
  `,
  content: css`
    padding: 12px;
    background-color: ${Colors.LIGHT_GRAY5};
    box-shadow: inset 0 0 1px 0 rgba(16, 22, 26, 0.15);
  `,
};
