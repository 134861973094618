/** @jsx jsx */
import { NetworkStatus, useQuery } from '@apollo/client';
import { HTMLTable } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import Empty from '../../../components/Empty';
import Error from '../../../components/Error';
import InfiniteScrollWrapper from '../../../components/InfiniteScrollWrapper';
import TableHeaderCell from '../../../components/TableHeaderCell';
import {
  CompanionAttendsDocument,
  CompanionAttendsQuery,
  CompanionAttendsQueryVariables,
  SoulAttendsDocument,
  SoulAttendsQuery,
  SoulAttendsQueryVariables,
} from '../../../generated/graphql';
import { dummyAttends } from '../dummy';
import AttendListItem from './AttendListItem';

type AttendListProps = {
  isSoul: boolean;
  prospectId: string;
};

type QueryDataType = SoulAttendsQuery | CompanionAttendsQuery;
type QueryVariablesType = SoulAttendsQueryVariables | CompanionAttendsQueryVariables;

const isSoulAttends = (data: QueryDataType): data is SoulAttendsQuery => !!(data as SoulAttendsQuery).soul;

const TableWrapper = ({ children }: { children: React.ReactNode }) => (
  <HTMLTable condensed css={styles.table}>
    <thead>
      <tr>
        <TableHeaderCell>Veranstaltung</TableHeaderCell>
        <TableHeaderCell>Zeitpunkt</TableHeaderCell>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </HTMLTable>
);

const AttendList = ({ isSoul, prospectId }: AttendListProps) => {
  const query = isSoul ? SoulAttendsDocument : CompanionAttendsDocument;
  const { data, loading, error, networkStatus, fetchMore } = useQuery<QueryDataType, QueryVariablesType>(query, {
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: prospectId,
      cursor: null,
    },
  });

  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  if (loading && !loadingMore) {
    return (
      <TableWrapper>
        {dummyAttends.map(attend => (
          <AttendListItem key={attend.id} attend={attend} skeleton />
        ))}
      </TableWrapper>
    );
  }

  if (error) {
    return <Error />;
  }

  if (!data) {
    return <Empty />;
  }

  const root = isSoulAttends(data) ? data.soul : data.companion;

  if (!root || !root.attends || !root.attends.edges || !root.attends.edges.length) {
    return <Empty />;
  }

  const hasNextPage = root.attends.pageInfo.hasNextPage;
  const nextCursor = root.attends.pageInfo.endCursor;

  return (
    <InfiniteScrollWrapper
      loading={loadingMore}
      onLoadMore={() =>
        fetchMore({
          variables: {
            id: prospectId,
            cursor: nextCursor,
          },
        })
      }
      hasNextPage={hasNextPage}
    >
      <TableWrapper>
        {root.attends.edges.map(edge =>
          edge && edge.node ? <AttendListItem key={edge.node.id} attend={edge.node} /> : null,
        )}
        {hasNextPage && dummyAttends.map(attend => <AttendListItem key={attend.id} attend={attend} skeleton />)}
      </TableWrapper>
    </InfiniteScrollWrapper>
  );
};

export default AttendList;

const styles = {
  table: css`
    width: 100%;
  `,
  loadingMoreContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  `,
  loadingMoreText: css`
    padding-left: 10px;
  `,
};
