/** @jsx jsx */
import { Button, Intent, Menu, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ISelectProps, ItemListRenderer, ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';
import { css, jsx } from '@emotion/core';
import { getIn, useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { useFocusOnError } from '../helpers/forms';

type DropdownInputProps<T> = {
  name: string;
  resets?: string[];
  canClear?: boolean;
  labelPath: string;
  items: T[];
};

function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join('|'), 'gi');
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
}

const DropdownInputComponent = <T extends { id: string }>({
  name,
  items,
  resets = [],
  labelPath,
  canClear = true,
  ...selectProps
}: DropdownInputProps<T> & Partial<ISelectProps<T>>) => {
  const [field, meta] = useField<T>(name);
  const fieldRef = useRef<HTMLElement>();
  const formik = useFormikContext<any>();
  useFocusOnError({ fieldRef, name });

  const itemListRenderer: ItemListRenderer<T> = ({ items, itemsParentRef, renderItem }) => {
    const renderedItems = items.map(renderItem).filter(item => item != null);
    return (
      <Menu ulRef={itemsParentRef} css={styles.menu}>
        {renderedItems}
      </Menu>
    );
  };

  const itemRenderer: ItemRenderer<T> = (item, { handleClick, modifiers, query }) =>
    !modifiers.matchesPredicate ? null : (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.id}
        onClick={handleClick}
        text={highlightText(getLabel(item), query)}
      />
    );

  const itemPredicate: ItemPredicate<T> = (query, item, _index, exactMatch) => {
    const normalizedLabel = getLabel(item).toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedLabel === normalizedQuery;
    } else {
      return normalizedLabel.indexOf(normalizedQuery) >= 0;
    }
  };

  const getLabel = (item: T) => getIn(item, labelPath);

  const onSelect = (item: T | null) => {
    formik.setFieldValue(name, item);
    resets.forEach(reset => formik.setFieldValue(reset, null));
  };

  return (
    <div css={styles.selectConainer}>
      <Select<T>
        {...selectProps}
        disabled={formik.isSubmitting}
        items={items}
        itemListRenderer={itemListRenderer}
        itemPredicate={itemPredicate}
        itemRenderer={itemRenderer}
        noResults={<MenuItem disabled={true} text="Keine Resultate." />}
        onItemSelect={onSelect}
        popoverProps={{
          minimal: true,
        }}
        inputProps={{
          intent: meta.error && meta.touched ? Intent.DANGER : Intent.NONE,
        }}
      >
        {/* children become the popover target; render value here */}
        <Button
          disabled={formik.isSubmitting}
          text={field.value ? getLabel(field.value) : '—'}
          rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
          elementRef={ref => (fieldRef.current = ref || undefined)}
          intent={meta.error && meta.touched ? Intent.DANGER : Intent.NONE}
        />
      </Select>
      {canClear && !!field.value && (
        <Button
          disabled={formik.isSubmitting}
          minimal
          icon={IconNames.DELETE}
          onClick={() => onSelect(null)}
          css={styles.clearButton}
        />
      )}
    </div>
  );
};

export default DropdownInputComponent;

const styles = {
  menu: css`
    max-height: 300px;
    max-width: 400px;
    overflow: auto;
  `,
  clearButton: css`
    margin-left: 10px;
  `,
  selectConainer: css`
    display: flex;
  `,
};
