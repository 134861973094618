/** @jsx jsx */
import { Button, Colors, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { addDays, parseISO } from 'date-fns';
import { Formik, FormikHelpers } from 'formik';
import { Nullable } from 'formik-apollo';
import gql from 'graphql-tag';
import { Fragment } from 'react';
import { Highlight } from 'react-instantsearch-dom';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import DatePickerInput from '../../../components/DatePickerInput';
import DropdownSearchInput from '../../../components/DropdownSearchInput';
import FormGroup from '../../../components/FormGroup';
import NumberInput from '../../../components/NumberInput';
import SelectInput from '../../../components/SelectInput';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import {
  EventCreateFormFragment,
  EventEditFormFragment,
  EventTicketRetrievalType,
  EventTicketType,
} from '../../../generated/graphql';
import { TICKET_RETRIEVAL_TYPE_OPTIONS, TICKET_TYPE_OPTIONS } from '../../../helpers/constants';
import { DonorHit } from '../../../types';
import AllotmentSelect from './AllotmentSelect';
import CategoryMultiSelect from './CategoryMultiSelect';
import ContactSelect from './ContactSelect';
import LocationSelect from './LocationSelect';

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
  start: Yup.date()
    .typeError('Muss gültiges Datum sein')
    .required('Erforderlich'),
  end: Yup.date()
    .typeError('Muss gültiges Datum sein')
    .nullable(),
  tickets: Yup.number()
    .typeError('Erforderlich')
    .integer('Muss Ganzzahl sein')
    .positive('Muss positiv sein')
    .when('ticket_type', (ticketType: EventTicketType, schema: Yup.NumberSchema<number>) =>
      ticketType === EventTicketType.Fixed ? schema : schema.nullable(),
    ),
  allotment: Yup.object()
    .typeError('Erforderlich')
    .when('ticket_type', (ticketType: EventTicketType, schema: Yup.ObjectSchema) =>
      ticketType === EventTicketType.Allotment ? schema : schema.nullable(),
    ),
  ticket_retrieval_type: Yup.mixed<EventTicketRetrievalType>()
    .oneOf([EventTicketRetrievalType.Onsite, EventTicketRetrievalType.Delivery])
    .when('ticket_type', (ticketType: EventTicketType, schema: Yup.MixedSchema) =>
      ticketType !== EventTicketType.Unlimited ? schema : schema.nullable(),
    ),
  ticket_retrieval_location: Yup.string().when(
    ['ticket_type', 'ticket_retrieval_type'],
    (ticketType: EventTicketType, ticketRetrievalType: EventTicketRetrievalType, schema: Yup.StringSchema) =>
      ticketType !== EventTicketType.Unlimited && ticketRetrievalType === EventTicketRetrievalType.Onsite
        ? schema.required('Erforderlich')
        : schema,
  ),
  ticket_time: Yup.number()
    .typeError('Erforderlich')
    .integer('Muss Ganzzahl sein')
    .positive('Muss positiv sein')
    .when(
      ['ticket_type', 'ticket_retrieval_type'],
      (ticketType: EventTicketType, ticketRetrievalType: EventTicketRetrievalType, schema: Yup.NumberSchema) =>
        ticketType !== EventTicketType.Unlimited && ticketRetrievalType === EventTicketRetrievalType.Onsite
          ? schema
          : schema.nullable(),
    ),
  donor: Yup.object().typeError('Erforderlich'),
  contact: Yup.object().typeError('Erforderlich'),
  location: Yup.object().typeError('Erforderlich'),
});

interface IEventFormProps<T> {
  isEdit?: boolean;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  onCancel?: (event?: any) => void;
  initialValues: Nullable<T>;
}

type EventFormValues = EventEditFormFragment | EventCreateFormFragment;

const EventForm = <T extends EventFormValues>({ isEdit, onCancel, onSubmit, initialValues }: IEventFormProps<T>) => (
  <Formik<T> initialValues={initialValues as T} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ isSubmitting, submitForm, values }) => (
      <ContentCard elevation={Elevation.FOUR}>
        <ContentCardHeader
          leftElement={<span css={styles.heading}>Veranstaltung {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
          rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
        />

        <ContentCardScroll>
          <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
            <TextInput name="name" placeholder="Name" />
          </FormGroup>
          <FormGroup label="Start" labelInfo="(erforderlich)" name="start">
            <DatePickerInput name="start" timePickerProps={{ showArrowButtons: true, precision: 'minute' }} />
          </FormGroup>
          <FormGroup label="Ende" name="end" helperText="Frei lassen für unbegrenzte Gültigkeit">
            <DatePickerInput
              name="end"
              minDate={addDays(parseISO(values.start), 1)}
              timePickerProps={{ showArrowButtons: true, precision: 'minute' }}
            />
          </FormGroup>
          <FormGroup label="Kategorie(n)" labelInfo="(erforderlich)" name="categories">
            <CategoryMultiSelect name="categories" />
          </FormGroup>
          <FormGroup label="Beschreibung" name="description">
            <TextAreaInput name="description" placeholder="Beschreibung" fill rows={10} />
          </FormGroup>
          <FormGroup label="Interne Notizen" name="notes">
            <TextAreaInput name="notes" placeholder="Interne Notizen" fill rows={10} />
          </FormGroup>

          <Divider css={styles.formDivider} />
          <FormGroup label="Karten" name="ticket_type" labelInfo="(erforderlich)">
            <SelectInput name="ticket_type" options={TICKET_TYPE_OPTIONS} />
          </FormGroup>
          {values.ticket_type === EventTicketType.Fixed && (
            <FormGroup label="Anzahl" labelInfo="(erforderlich)" name="tickets">
              <NumberInput name="tickets" placeholder="Karten" min={1} />
            </FormGroup>
          )}
          {values.ticket_type === EventTicketType.Allotment && (
            <FormGroup label="Kontingent" labelInfo="(erforderlich)" name="allotment">
              <AllotmentSelect name="allotment" />
            </FormGroup>
          )}
          {values.ticket_type !== EventTicketType.Unlimited && (
            <FormGroup label="Woher?" name="ticket_retrieval_type" labelInfo="(erforderlich)">
              <SelectInput name="ticket_retrieval_type" options={TICKET_RETRIEVAL_TYPE_OPTIONS} />
            </FormGroup>
          )}
          {values.ticket_type !== EventTicketType.Unlimited &&
            values.ticket_retrieval_type === EventTicketRetrievalType.Onsite && (
              <Fragment>
                <FormGroup label="Reservierung" name="reservation_number">
                  <TextInput name="reservation_number" placeholder="Reservierungsnummer" />
                </FormGroup>
                <FormGroup label="Karten abholen" name="ticket_retrieval_location">
                  <TextInput name="ticket_retrieval_location" />
                </FormGroup>
                <FormGroup
                  label="Vorlaufzeit"
                  labelInfo="(erforderlich)"
                  helperText="Minuten vor Beginn der Veranstaltung müssen die Karten vom Kartenverteiler abgeholt werden"
                  name="ticket_time"
                >
                  <NumberInput name="ticket_time" min={1} />
                </FormGroup>
              </Fragment>
            )}

          <Divider css={styles.formDivider} />
          <FormGroup label="Spender" name="donor" labelInfo="(erforderlich)">
            <DropdownSearchInput<DonorHit>
              name="donor"
              index="donors"
              labelPath="name"
              resets={['contact', 'location']}
              canClear={false}
              renderItem={hit => <Highlight hit={hit} attribute="name" css={styles.highlight} />}
            />
          </FormGroup>
          {!!values.donor && (
            <FormGroup label="Ansprechpartner" name="contact" labelInfo="(erforderlich)">
              <ContactSelect name="contact" donorId={values.donor.id} />
            </FormGroup>
          )}
          {!!values.donor && (
            <FormGroup label="Lokation" name="location" labelInfo="(erforderlich)">
              <LocationSelect name="location" donorId={values.donor.id} />
            </FormGroup>
          )}
        </ContentCardScroll>

        <ContentCardFooter
          rightElement={
            <Fragment>
              <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
              <Button
                text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                loading={isSubmitting}
                intent={Intent.PRIMARY}
                onClick={submitForm}
                css={styles.footerButton}
              />
            </Fragment>
          }
        />
      </ContentCard>
    )}
  </Formik>
);

EventForm.fragments = {
  create: gql`
    fragment EventCreateForm on Event {
      name
      description
      start
      end
      tickets
      ticket_time
      ticket_type
      reservation_number
      ticket_retrieval_type
      ticket_retrieval_location
      notes
      categories {
        id
      }
      donor {
        id
      }
      contact {
        id
      }
      location {
        id
      }
      allotment {
        id
      }
    }
  `,
  edit: gql`
    fragment EventEditForm on Event {
      id
      name
      description
      start
      end
      tickets
      ticket_time
      ticket_type
      reservation_number
      ticket_retrieval_type
      ticket_retrieval_location
      notes
      categories {
        id
        name
      }
      donor {
        id
        name
      }
      contact {
        id
        user {
          id
          display_name
        }
      }
      location {
        id
        name
      }
      allotment {
        id
        name
      }
    }
  `,
};

export default EventForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
  highlight: css`
    em {
      font-style: normal;
      color: ${Colors.BLUE1};
    }
  `,
};
