/** @jsx jsx */
import { Classes, HTMLTable, Spinner } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { NetworkStatus } from 'apollo-client';
import { useHistory } from 'react-router';
import { ContentCardFooter } from '../../../components/ContentCard';
import Empty from '../../../components/Empty';
import Error from '../../../components/Error';
import InfiniteScrollWrapper from '../../../components/InfiniteScrollWrapper';
import NoResults from '../../../components/NoResults';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { useCompanionListQuery, UserStatus } from '../../../generated/graphql';
import { useQueryParams } from '../../../hooks/navigation';
import { dummyCompanions } from '../dummy';
import CompanionListItem from './CompanionListItem';

const LoadingTable = () => (
  <HTMLTable condensed css={styles.table}>
    <thead>
      <tr>
        <TableHeaderCell>Name</TableHeaderCell>
        <TableHeaderCell>Besonderheiten</TableHeaderCell>
        <TableHeaderCell>Telefon</TableHeaderCell>
        <TableHeaderCell>Email</TableHeaderCell>
      </tr>
    </thead>
    <tbody>
      {dummyCompanions.map((item, index) => (
        <CompanionListItem key={index} item={item} skeleton />
      ))}
    </tbody>
  </HTMLTable>
);

const CompanionList = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const status = (queryParams.get('status') as UserStatus) || UserStatus.Active;
  const filters = queryParams.get('filters');

  const { data, loading, error, fetchMore, networkStatus } = useCompanionListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      status,
      cursor: null,
    },
  });

  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  if (loading && !loadingMore) {
    return <LoadingTable />;
  }

  if (error) {
    return <Error />;
  }

  if (!data || !data.companions || !data.companions.edges || !data.companions.edges.length) {
    return filters ? <NoResults /> : <Empty />;
  }

  const hasNextPage = data!.companions!.pageInfo.hasNextPage;
  const nextCursor = data!.companions!.pageInfo.endCursor;

  return (
    <InfiniteScrollWrapper
      loading={loadingMore}
      onLoadMore={() =>
        fetchMore({
          variables: {
            cursor: nextCursor,
          },
        })
      }
      hasNextPage={hasNextPage}
    >
      <HTMLTable interactive condensed css={styles.table}>
        <thead>
          <tr>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Besonderheiten</TableHeaderCell>
            <TableHeaderCell>Telefon</TableHeaderCell>
            <TableHeaderCell>Email</TableHeaderCell>
          </tr>
        </thead>
        <tbody>
          {data.companions.edges.map(edge =>
            edge && edge.node ? (
              <CompanionListItem
                key={edge.node.id}
                item={edge.node}
                onClick={() => history.push(`/companions/${edge.node!.id}`)}
              />
            ) : null,
          )}
        </tbody>
      </HTMLTable>
      {loadingMore && (
        <ContentCardFooter>
          <div css={styles.loadingMoreContainer}>
            <Spinner size={16} />
            <div className={Classes.TEXT_MUTED} css={styles.loadingMoreText}>
              Lade mehr
            </div>
          </div>
        </ContentCardFooter>
      )}
    </InfiniteScrollWrapper>
  );
};

export default CompanionList;

const styles = {
  table: css`
    width: 100%;
  `,
  loadingMoreContainer: css`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  loadingMoreText: css`
    padding-left: 10px;
  `,
};
