/** @jsx jsx */
import { Colors, Elevation, HTMLTable, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Fragment } from 'react';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { GroupType } from '../types';
import ProspectGroupListItem from './ProspectGroupListItem';
import ProspectListItem from './ProspectListItem';

type ProspectListProps = {
  soulSignupIds: string[];
  companionSignupIds: string[];
  groupSignupData: GroupType[];
  addLoneSoul: (soulSignupId: string) => void;
  addLoneCompanion: (companionSignupId: string) => void;
  addTeam: (companionSignupId: string, soulSignupId: string) => void;
  addGroup: (companionSignupId: string, soulSignupIds: string[]) => void;
};

const ListWrapper = ({ children, isGroup }: { children: React.ReactNode; isGroup?: boolean }) => (
  <HTMLTable condensed css={styles.table} interactive>
    <colgroup>
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: 60 }} />
    </colgroup>
    <thead>
      <tr>
        <TableHeaderCell>Name</TableHeaderCell>
        <TableHeaderCell>Info</TableHeaderCell>
        <TableHeaderCell>{isGroup ? 'Personen' : 'Priorität'}</TableHeaderCell>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </HTMLTable>
);

const ProspectList = ({
  groupSignupData,
  soulSignupIds,
  companionSignupIds,
  addLoneSoul,
  addLoneCompanion,
  addTeam,
  addGroup,
}: ProspectListProps) => (
  <Fragment>
    <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
      <ContentCardHeader
        leftElement={
          <Fragment>
            <Icon icon={IconNames.HOME} color={Colors.GRAY1} css={styles.icon} />
            Angemeldete Gruppen
          </Fragment>
        }
      />
      <ListWrapper isGroup>
        {groupSignupData.map(group => (
          <ProspectGroupListItem key={group.companion} id={group.companion} addGroup={addGroup} />
        ))}
      </ListWrapper>
    </ContentCard>
    <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
      <ContentCardHeader
        leftElement={
          <Fragment>
            <Icon icon={IconNames.HEART} color={Colors.GRAY1} css={styles.icon} />
            Angemeldete Genießer
          </Fragment>
        }
      />
      <ListWrapper>
        {soulSignupIds.map(soulSignupId => (
          <ProspectListItem
            key={soulSignupId}
            id={soulSignupId}
            addLoneSoul={addLoneSoul}
            addLoneCompanion={addLoneCompanion}
            addTeam={addTeam}
          />
        ))}
      </ListWrapper>
    </ContentCard>
    <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
      <ContentCardHeader
        leftElement={
          <Fragment>
            <Icon icon={IconNames.HAND} color={Colors.GRAY1} css={styles.icon} />
            Angemeldete Begleiter
          </Fragment>
        }
      />
      <ListWrapper>
        {companionSignupIds.map(companionSignupId => (
          <ProspectListItem
            key={companionSignupId}
            id={companionSignupId}
            addLoneSoul={addLoneSoul}
            addLoneCompanion={addLoneCompanion}
            addTeam={addTeam}
          />
        ))}
      </ListWrapper>
    </ContentCard>
  </Fragment>
);

ProspectList.fragments = {
  signups: gql`
    fragment ProspectList on Signup {
      ...ProspectListItem
      group {
        ...ProspectListItem
      }
      leader {
        ...ProspectListItem
      }
    }
    ${ProspectListItem.fragments.prospect}
  `,
};

export default ProspectList;

const styles = {
  spacedCard: css`
    overflow: hidden;
    padding: 0;
    margin-bottom: 12px;
  `,
  icon: css`
    margin-right: 5px;
  `,
  table: css`
    width: 100%;
  `,
};
