/** @jsx jsx */
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import OrganisationCreateOverlay from '../components/OrganisationCreateOverlay';
import OrganisationList from '../components/OrganisationList';

const OrganisationListPage = () => {
  const history = useHistory();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const onCreated = (modelId: string) => {
    AppToaster.success('Organisation hinzugefügt!');
    history.push(`/organisations/${modelId}`);
  };

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.list}>
      <OrganisationCreateOverlay
        isOpen={isCreateDialogOpen}
        onSuccess={onCreated}
        onClose={() => setIsCreateDialogOpen(false)}
      />
      <ContentCardHeader
        leftElement={<Button text="Filter" icon={IconNames.FILTER} />}
        rightElement={
          <Fragment>
            <Button
              text="Hinzufügen"
              icon={IconNames.ADD}
              intent={Intent.PRIMARY}
              css={styles.headerButton}
              onClick={() => setIsCreateDialogOpen(true)}
            />
            <Button text="Exportieren" icon={IconNames.EXPORT} css={styles.headerButton} />
          </Fragment>
        }
      />
      <OrganisationList />
    </ContentCard>
  );
};

export default OrganisationListPage;

const styles = {
  headerButton: css`
    margin-left: 10px;
  `,
  list: css`
    margin-bottom: 20px;
  `,
};
