import { AppEnv, ENV } from './constants';

const envConfig = {
  [AppEnv.Dev]: {
    endpoint: 'http://culture-key-backend.test/graphql',
    algolia: {
      appId: 'X0EJQ5FVKC',
      appSecret: '8065ca37843e5261ef6b69e87d35180d',
    },
  },
  [AppEnv.Stage]: {
    endpoint: 'https://staging-api.wulffinteractive.com/graphql',
    algolia: {
      appId: 'NOTFRMM3VL',
      appSecret: 'f7d73371194b0a7d331052124f32b9fd',
    },
  },
  [AppEnv.Prod]: {
    endpoint: 'https://api.wulffinteractive.com/graphql',
    algolia: {
      appId: '???',
      appSecret: '???',
    },
  },
};

const config = envConfig[ENV];

export default config;
