/** @jsx jsx */
import { Button, Divider, Intent, Popover } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { createContext, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../hooks/navigation';
import FilterHeader from './FilterHeader';
import Text from './Text';

type FilterProps = {
  keys: string[];
  children: React.ReactNode;
};

type Filters = { [k: string]: string | null };

type FilterContext = {
  filters: Filters;
  setFilterValue: (name: string, value: string | null) => void;
  commitFilters: () => void;
  resetFilters: () => void;
};

const FilterContext = createContext<FilterContext>({} as FilterContext);

const toFilterValues = (queryParams: URLSearchParams, keys: string[]) =>
  keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: queryParams.get(key),
    }),
    {},
  );

const Filter = ({ keys, children }: FilterProps) => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const [filters, setFilters] = useState<Filters>({});
  const filterCount = keys.filter(key => filters[key] !== null && filters[key] !== undefined).length;

  const resetFilters = useCallback(() => {
    setFilters({});
  }, []);

  useEffect(() => setFilters(toFilterValues(queryParams, keys)), [queryParams, keys]);

  const setFilterValue = useCallback(
    (name: string, value: string | null) =>
      setFilters(currentFilters => ({
        ...currentFilters,
        [name]: value,
      })),
    [],
  );

  const commitFilters = useCallback(() => {
    keys.forEach(key => (filters[key] ? queryParams.set(key, filters[key] || '') : queryParams.delete(key)));
    history.push({ search: `?${queryParams.toString()}` });
  }, [queryParams, keys, filters, history]);

  return (
    <FilterContext.Provider value={{ filters, setFilterValue, resetFilters, commitFilters }}>
      <Popover>
        <Button
          text={
            <div css={styles.buttonTitle}>
              Filter
              {!!filterCount && (
                <Fragment>
                  <Divider css={styles.divider} />
                  <Text intent={Intent.PRIMARY}>{filterCount}</Text>
                </Fragment>
              )}
            </div>
          }
          icon={IconNames.FILTER}
        />
        <div>
          <FilterHeader />
          {children}
        </div>
      </Popover>
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => useContext(FilterContext);

export default Filter;

const styles = {
  buttonTitle: css`
    display: flex;
  `,
  divider: css`
    margin: 2px 7px !important;
  `,
  popover: css`
    overflow: hidden;
  `,
};
