/** @jsx jsx */
import { Elevation, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { PropertyList, PropertyListHeader, PropertyListItem } from '../../../components/PropertyList';
import TrashTag from '../../../components/TrashTag';
import { useOrganisationDetailQuery } from '../../../generated/graphql';
import OrganisationCompanionList from '../components/OrganisationCompanionList';
import OrganisationCompanionListItem from '../components/OrganisationCompanionListItem';
import OrganisationDetailMenu from '../components/OrganisationDetailMenu';
import OrganisationEditOverlay from '../components/OrganisationEditOverlay';
import OrganisationSoulList from '../components/OrganisationSoulList';
import OrganisationSoulListItem from '../components/OrganisationSoulListItem';

const OrganisationDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { organisationId } = useParams();

  const options = {
    variables: {
      id: organisationId || '',
    },
  };

  const { data, loading, error } = useOrganisationDetailQuery(options);

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.organisation) return <NotFoundCard resource="Organisation" />;

  const { organisation } = data;

  return (
    <Fragment>
      <OrganisationEditOverlay
        id={organisation.id}
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
      />
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{organisation.name}</span>
              <Tag minimal css={styles.personTag}>
                Organisation
              </Tag>
              {!!organisation.deleted_at && <TrashTag />}
            </Fragment>
          }
          rightElement={<OrganisationDetailMenu organisation={organisation} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div css={styles.content}>
          <PropertyList>
            <PropertyListHeader>Details</PropertyListHeader>
            <PropertyListItem
              label="Beschreibung"
              value={organisation.description || '—'}
              showValueDisabled={!organisation.description}
            />
          </PropertyList>
        </div>
      </ContentCard>
      <div css={styles.listsContainer}>
        <div css={styles.leftList}>
          <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
            <ContentCardHeader
              leftElement={
                <Fragment>
                  <span css={styles.heading}>Begleiter</span>
                </Fragment>
              }
            />
            <OrganisationCompanionList companions={organisation.companions} />
          </ContentCard>
        </div>
        <div css={styles.rightList}>
          <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
            <ContentCardHeader
              leftElement={
                <Fragment>
                  <span css={styles.heading}>Genießer</span>
                </Fragment>
              }
            />
            <OrganisationSoulList souls={organisation.souls} />
          </ContentCard>
        </div>
      </div>
    </Fragment>
  );
};

OrganisationDetailPage.fragments = {
  organisation: gql`
    fragment OrganisationDetailPage on Organisation {
      id
      name
      description
      souls {
        ...OrganisationSoulListItem
      }
      companions {
        ...OrganisationCompanionListItem
      }
      deleted_at
    }
    ${OrganisationSoulListItem.fragments.soul}
    ${OrganisationCompanionListItem.fragments.companion}
  `,
};

export default OrganisationDetailPage;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  table: css`
    width: 100%;
  `,
  overlayContent: css`
    left: calc(50vw - 225px);
    margin: 10vh 0;
    width: 450px;
    max-height: 80vh;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
  listsContainer: css`
    display: flex;
  `,
  leftList: css`
    width: 50%;
    padding-right: 10px;
  `,
  rightList: css`
    width: 50%;
    padding-left: 10px;
  `,
};
