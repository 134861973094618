import { LocationDescriptorObject, LocationState, Path } from 'history';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQueryParams = () => {
  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState(new URLSearchParams(search));

  useEffect(() => {
    setQueryParams(new URLSearchParams(search));
  }, [search]);

  return queryParams;
};

interface Navigate {
  (path: Path, state?: LocationState): void;
  (location: LocationDescriptorObject<LocationState>): void;
}

export const useNavigate = () => {
  const history = useHistory();

  const navigate: Navigate = (path: any, state?: any) => {
    history.push(path, state);
  };

  return useCallback(navigate, [history]);
};
