import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import React from 'react';
import { CompanionMarkerType, LocationMarkerType, MarkerType, SoulMarkerType } from '../types';
import Marker from './Marker';

const mapStyles: google.maps.MapTypeStyle[] = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f8fa',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#5c7080',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f8fa',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bfccd6',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ebf1f5',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#738694',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#d8e1e8',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a9ba8',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#738694',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ced9e0',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#5c7080',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#a7b6c2',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#d8e1e8',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e1e8ed',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#bfccd6',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
];

const getBounds = (markers: MarkerType[]) => {
  const bounds = new google.maps.LatLngBounds();

  markers.forEach(({ type, id, ...marker }) => {
    bounds.extend(marker);
  });

  return bounds;
};

type MatchingMapProps = {
  soulMarker: SoulMarkerType;
  companionMarkers: CompanionMarkerType[];
  locationMarker: LocationMarkerType;
  highlightedCompanionId: string | null;
  onHighlightedCompanionIdChange: (id: string | null) => void;
};

const MatchingMap = ({
  soulMarker,
  companionMarkers,
  locationMarker,
  highlightedCompanionId,
  onHighlightedCompanionIdChange,
}: MatchingMapProps) => {
  const { isLoaded } = useLoadScript({
    id: 'script-loader',
    googleMapsApiKey: 'AIzaSyCbtSYp5tA62efencokks8ESw4jSJxL1vY',
    language: 'de',
    region: 'DE',
  });

  const renderMap = () => (
    <GoogleMap
      id="example-map"
      mapContainerStyle={{
        height: '100%',
        width: '600px',
      }}
      onLoad={map => map.fitBounds(getBounds([soulMarker, locationMarker, ...companionMarkers]))}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
        styles: mapStyles,
      }}
    >
      <Marker marker={soulMarker} />
      <Marker marker={locationMarker} />
      {companionMarkers.map(companionMarker => (
        <Marker
          key={companionMarker.id}
          marker={companionMarker}
          isActive={highlightedCompanionId === companionMarker.id}
          onMouseOver={() => onHighlightedCompanionIdChange(companionMarker.id)}
          onMouseOut={() => onHighlightedCompanionIdChange(null)}
        />
      ))}
    </GoogleMap>
  );
  return isLoaded ? renderMap() : null;
};

export default MatchingMap;
