import { FetchResult } from '@apollo/client';
import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import {
  UpdateContactInput,
  UpdateContactMutation,
  useContactEditLazyQuery,
  useUpdateContactMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import ContactForm from './ContactForm';

interface IContactEditOverlayProps extends IOverlayProps {
  id: string;
}

const ContactEditOverlay = ({ id, ...overlayProps }: IContactEditOverlayProps) => {
  const [loadContact, { data, called, loading, error }] = useContactEditLazyQuery({
    variables: {
      id,
    },
  });
  const [updateContact] = useUpdateContactMutation();
  const handleSubmit = useSubmit<UpdateContactInput, FetchResult<UpdateContactMutation>>({
    mutate: input =>
      updateContact({
        variables: {
          input,
        },
      }),
    onCompleted: () => {
      loadContact(); // Have to execute query again since it's a lazy one
      overlayProps.onClose && overlayProps.onClose();
    },
    getErrors: getLaravelValidationErrors,
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadContact();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data || !data.contact) return <ErrorCard />;

    const initialValues: UpdateContactInput = {
      ...data.contact,
      user: {
        update: {
          ...data.contact.user,
        },
      },
    };

    return (
      <ContactForm<UpdateContactInput>
        isEdit
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default ContactEditOverlay;
