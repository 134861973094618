import { ApolloError, isApolloError } from 'apollo-client';
import { GraphQLError } from 'graphql';

/**
 * The problems associated with a validation error.
 */
export type Problems = Record<string, string[]>;

/**
 * A `GraphQLError` instance that looks like a validation error.
 */
export interface ValidationError extends GraphQLError {
  extensions: { validation: Problems };
}

const isString = (value: any) => typeof value === 'string';
const isObject = (value: any) => value && typeof value === 'object';

const obsoleteKeysRegex = /input\.|create\.|update\.|sync\.|connect\./gi;
const cleanKey = (key: string) => key.replace(obsoleteKeysRegex, '');

/**
 * Indicates that an error is a validation error.
 */
export const VALIDATION_ERROR = 'validation';

/**
 * Is the error a validation error?
 */
export const isValidationError = (error: any): error is ValidationError =>
  isObject(error) &&
  isObject(error.extensions) &&
  isObject(error.extensions!.validation) &&
  error.extensions!.category === VALIDATION_ERROR;

const addError = (errors: { [k: string]: {} }, keyPath: string, message: string): {} => {
  const [key, ...nextKeyParts] = keyPath.split('.');
  return {
    ...errors,
    [key]: nextKeyParts.length ? addError(errors[key] || {}, nextKeyParts.join('.'), message) : message,
  };
};

/**
 * Extract all validation errors from an error.
 */
export const getLaravelValidationErrors = (error: ApolloError | GraphQLError | Error): Record<string, string> => {
  if (error && isApolloError(error)) {
    return error.graphQLErrors.reduce((acc, e) => Object.assign(acc, getLaravelValidationErrors(e)), {});
  }

  if (error && isValidationError(error)) {
    return Object.entries(error.extensions.validation).reduce((acc, [key, value]) => {
      const message = Array.isArray(value) ? value[0] : value;
      return isString(message) ? addError(acc, cleanKey(key), message) : acc;
    }, {});
  }

  return {};
};
