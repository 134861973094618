import gql from 'graphql-tag';
import React, { Fragment, useState } from 'react';
import PersonIconList from '../../../components/PersonIconList';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { useProspectSignupQuery } from '../../../generated/graphql';
import InfoDrawer from './InfoDrawer';

export type ProspectListItemProps = {
  id: string;
  addLoneSoul: (soulSignupId: string) => void;
  addLoneCompanion: (companionSignupId: string) => void;
  addTeam: (companionSignupId: string, soulSignupId: string) => void;
};

const ProspectListItem = ({ id, addLoneSoul, addLoneCompanion, addTeam }: ProspectListItemProps) => {
  const [isInfoDrawerOpen, setIsInfoDrawerOpen] = useState(false);
  const { data, loading, error } = useProspectSignupQuery({
    variables: {
      id,
    },
  });

  if (loading || error || !data || !data.signup) {
    return null;
  }

  const { signup } = data;
  const prospect = signup.soul ? signup.soul : signup.companion!;
  const { user, organisation } = prospect;

  return (
    <Fragment>
      <InfoDrawer
        signup={signup}
        addLoneSoul={addLoneSoul}
        addLoneCompanion={addLoneCompanion}
        addTeam={addTeam}
        isOpen={isInfoDrawerOpen}
        onClose={() => setIsInfoDrawerOpen(false)}
      />
      <tr onClick={() => setIsInfoDrawerOpen(true)}>
        <TableCell>
          {user!.display_name}
          <br />
          {!!organisation && (
            <Text muted small>
              {organisation.name}
            </Text>
          )}
        </TableCell>
        <TableCell>
          <PersonIconList person={prospect} />
          <br />
          <Text small muted>
            {signup.notes}
          </Text>
        </TableCell>
        <TableCell>{signup.priority}</TableCell>
      </tr>
    </Fragment>
  );
};

ProspectListItem.fragments = {
  prospect: gql`
    fragment ProspectListItem on Signup {
      id
      event_id
      token
      is_candidate
      is_invited
      has_accepted
      has_rejected
      notes
      priority
      emails {
        id
        status
        opened_first_at
      }
      companion {
        id
        notes
        can_blind
        can_wheelchair
        can_wheeled_walker
        can_drive
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
        user {
          id
          display_name
          email
          phone
          mobile
          lat
          lng
        }
      }
      soul {
        id
        notes
        buys_ticket
        needs_blind
        needs_wheelchair
        needs_wheeled_walker
        needs_drive
        needs_trainstation_fetch
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
        user {
          id
          display_name
          email
          phone
          mobile
          lat
          lng
        }
      }
    }
  `,
};

export default ProspectListItem;
