import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import {
  CompanionEditFormFragment,
  useCompanionEditLazyQuery,
  useUpdateCompanionMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import CompanionForm from './CompanionForm';

interface ICompanionEditOverlayProps extends IOverlayProps {
  id: string;
}

const CompanionEditOverlay = ({ id, ...overlayProps }: ICompanionEditOverlayProps) => {
  const [loadCompanion, { data, called, loading, error }] = useCompanionEditLazyQuery({
    variables: {
      id,
    },
  });
  const [updateCompanion] = useUpdateCompanionMutation();
  const handleSubmit = useSubmit({
    mutate: (values: CompanionEditFormFragment) =>
      updateCompanion({
        variables: {
          input: {
            ...values,
            user: {
              update: values.user,
            },
            tags: {
              sync: values.tags.map(tag => tag.id),
            },
            organisation: {
              connect: values.organisation ? values.organisation.id : null,
              disconnect: !values.organisation,
            },
          },
        },
      }),
    onCompleted: () => {
      loadCompanion(); // Have to execute query again since it's a lazy one
      overlayProps.onClose && overlayProps.onClose();
    },
    getErrors: getLaravelValidationErrors,
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadCompanion();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data || !data.companion) return <ErrorCard />;
    return (
      <CompanionForm<CompanionEditFormFragment>
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={data.companion}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default CompanionEditOverlay;
