/** @jsx jsx */
import { Card, Elevation } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Dispatch } from 'react';
import { ActionType, CandidateListType } from '../types';
import CandidateListItem from './CandidateListItem';

type CandidateListProps = {
  candidates: CandidateListType;
  dispatch: Dispatch<ActionType>;
};

const CandidateList = ({ candidates, dispatch }: CandidateListProps) => (
  <div>
    {candidates.groups.map(({ companion, souls }) => (
      <Card key={companion} css={styles.spacedCard} elevation={Elevation.TWO}>
        <CandidateListItem id={companion} dispatch={dispatch} isInGroup />
        {souls.map(soul => (
          <CandidateListItem key={soul} id={soul} dispatch={dispatch} isInGroup />
        ))}
      </Card>
    ))}
    {candidates.teams.map(({ companion, souls }) => (
      <Card key={companion} css={styles.spacedCard} elevation={Elevation.TWO}>
        <CandidateListItem id={companion} dispatch={dispatch} isInTeam />
        {souls.map(soul => (
          <CandidateListItem key={soul} id={soul} dispatch={dispatch} isInTeam />
        ))}
      </Card>
    ))}
    {candidates.souls.map(soul => (
      <Card key={soul} css={styles.spacedCard} elevation={Elevation.TWO}>
        <CandidateListItem id={soul} dispatch={dispatch} />
      </Card>
    ))}
    {candidates.companions.map(companion => (
      <Card key={companion} css={styles.spacedCard} elevation={Elevation.TWO}>
        <CandidateListItem id={companion} dispatch={dispatch} />
      </Card>
    ))}
  </div>
);

CandidateList.fragments = {
  signups: gql`
    fragment CandidateList on Signup {
      ...CandidateListItem
      group {
        ...CandidateListItem
      }
      leader {
        ...CandidateListItem
      }
    }
    ${CandidateListItem.fragments.candidate}
  `,
};

export default CandidateList;

const styles = {
  spacedCard: css`
    overflow: hidden;
    padding: 0;
    margin-bottom: 12px;
  `,
};
