/** @jsx jsx */
import { Button, Colors, Elevation, Intent, Tab, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik, FormikHelpers } from 'formik';
import { Fragment } from 'react';
import { Highlight } from 'react-instantsearch-dom';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import DropdownSearchInput from '../../../components/DropdownSearchInput';
import FormGroup from '../../../components/FormGroup';
import PersonMultiSelect from '../../../components/PersonMultiSelect';
import PersonOrganisationSelect from '../../../components/PersonOrganisationSelect';
import TextAreaInput from '../../../components/TextAreaInput';
import { PersonType, UserHit } from '../../../types';

export type AddSignupsFormValues = {
  loneSouls?: UserHit[];
  loneCompanions?: UserHit[];
  group?: {
    organisation: {
      id: number;
      name: string;
    };
    companion: UserHit;
    souls: UserHit[];
  };
};

interface IAddSignupsFormProps {
  onSubmit: (values: AddSignupsFormValues, formikHelpers: FormikHelpers<AddSignupsFormValues>) => void;
  onCancel?: (event?: any) => void;
  initialValues: AddSignupsFormValues;
}

const LoneSoulsPanel = () => (
  <FormGroup label="Genießer" labelInfo="(erforderlich)" name="loneSouls">
    <PersonMultiSelect name="loneSouls" type={PersonType.Soul} />
  </FormGroup>
);

const LoneCompanionsPanel = () => (
  <FormGroup label="Begleiter" labelInfo="(erforderlich)" name="loneCompanions">
    <PersonMultiSelect name="loneCompanions" type={PersonType.Companion} />
  </FormGroup>
);

const GroupPanel = ({ organisationId }: { organisationId?: number }) => (
  <Fragment>
    <FormGroup label="Organisation" labelInfo="(erforderlich)" name="group.organisation">
      <PersonOrganisationSelect name="group.organisation" />
    </FormGroup>
    {!!organisationId && (
      <Fragment>
        <FormGroup label="Begleiter" labelInfo="(erforderlich)" name="group.companion">
          <DropdownSearchInput<UserHit>
            name="group.companion"
            index="companions"
            labelPath="display_name"
            filters={`organisation_id=${organisationId}`}
            renderItem={hit => (
              <Fragment>
                <Highlight hit={hit} attribute="first_name" css={styles.highlight} />{' '}
                <Highlight hit={hit} attribute="last_name" css={styles.highlight} />
              </Fragment>
            )}
          />
        </FormGroup>
        <FormGroup label="Genießer" labelInfo="(erforderlich)" name="group.souls">
          <PersonMultiSelect name="group.souls" type={PersonType.Soul} filters={`organisation_id=${organisationId}`} />
        </FormGroup>
      </Fragment>
    )}
  </Fragment>
);

const AddSignupsForm = ({ onCancel, onSubmit, initialValues }: IAddSignupsFormProps) => (
  <Formik initialValues={initialValues} onSubmit={onSubmit}>
    {({ isSubmitting, submitForm, values }) => (
      <ContentCard elevation={Elevation.FOUR}>
        <ContentCardHeader
          leftElement={<span css={styles.heading}>Interessenten hinzufügen</span>}
          rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
        />

        <ContentCardScroll>
          <Tabs id="signupType">
            <Tab id="loneSouls" title="Einzelne Genießer" panel={<LoneSoulsPanel />} />
            <Tab id="loneCompanions" title="Einzelne Begleiter" panel={<LoneCompanionsPanel />} />
            <Tab
              id="group"
              title="Gruppe"
              panel={<GroupPanel organisationId={values.group && values.group.organisation.id} />}
            />
          </Tabs>
          <FormGroup label="Anmerkungen" name="notes">
            <TextAreaInput name="notes" placeholder="Für Nutzer sichtbar" fill rows={3} />
          </FormGroup>
        </ContentCardScroll>

        <ContentCardFooter
          rightElement={
            <Fragment>
              <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
              <Button
                text="Hinzufügen"
                loading={isSubmitting}
                intent={Intent.PRIMARY}
                onClick={submitForm}
                css={styles.footerButton}
              />
            </Fragment>
          }
        />
      </ContentCard>
    )}
  </Formik>
);

export default AddSignupsForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
  highlight: css`
    em {
      font-style: normal;
      color: ${Colors.BLUE1};
    }
  `,
};
