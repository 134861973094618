/** @jsx jsx */
import { Button, Callout, Classes, Drawer, Intent, Position, Tab, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Fragment, useState } from 'react';
import PersonIconList from '../../../components/PersonIconList';
import { ProspectListItemFragment } from '../../../generated/graphql';
import AttendsTab from './AttendsTab';
import CompanionMatchListItem from './CompanionMatchListItem';
import FutureSignupsTab from './FutureSignupsTab';
import MatchingTab from './MatchingTab';

type InfoDrawerProps = {
  signup: ProspectListItemFragment;
  isOpen: boolean;
  onClose: () => void;
  addLoneSoul: (soulSignupId: string) => void;
  addLoneCompanion: (companionSignupId: string) => void;
  addTeam: (companionSignupId: string, soulSignupId: string) => void;
};

const InfoDrawer = ({ signup, addLoneSoul, addLoneCompanion, addTeam, isOpen, onClose }: InfoDrawerProps) => {
  const prospect = signup.soul || signup.companion!;
  const isSoul = !!signup.soul;
  const { user } = prospect;

  const [tabWidth, setTabWidth] = useState(isSoul ? '1200px' : '800px');
  const [activeTab, setActiveTab] = useState(isSoul ? 'matching' : 'attends');

  const tabSwitched = (tabId: any) => {
    if (tabId === 'matching') {
      setTabWidth('1200px');
    } else {
      setTabWidth('800px');
    }
    setActiveTab(tabId);
  };

  const addLone = () => (isSoul ? addLoneSoul(signup.id) : addLoneCompanion(signup.id));

  return (
    <Drawer
      title={
        <Fragment>
          <span css={styles.title}>{user.display_name}</span>
          <PersonIconList person={prospect} position={Position.BOTTOM} />
        </Fragment>
      }
      position={Position.LEFT}
      size={tabWidth}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div css={styles.container} className={Classes.DRAWER_BODY}>
        {!!signup.notes && (
          <Callout css={styles.callout} intent={Intent.PRIMARY} icon={null}>
            {signup.notes}
          </Callout>
        )}
        <Tabs id="infoTabs" selectedTabId={activeTab} css={styles.tabsContainer} onChange={tabSwitched}>
          {isSoul && (
            <Tab
              id="matching"
              title="Angemeldete Begleiter"
              panel={<MatchingTab soulSignup={signup} addTeam={addTeam} />}
            />
          )}
          <Tab id="attends" title="Teilnahmen" panel={<AttendsTab isSoul={isSoul} prospectId={prospect.id} />} />
          <Tab id="signups" title="Anmeldungen" panel={<FutureSignupsTab isSoul={isSoul} prospectId={prospect.id} />} />
        </Tabs>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <Button
          icon={IconNames.SMALL_TICK}
          intent={Intent.SUCCESS}
          text={isSoul ? 'Ohne Begleitung auf die Teilnehmerliste' : 'Ohne Genießer auf die Teilnehmerliste'}
          css={styles.addLoneButton}
          onClick={addLone}
        />
      </div>
    </Drawer>
  );
};

InfoDrawer.fragments = {
  companionMatching: gql`
    fragment CompanionMatchingTab on Event {
      id
      signups {
        ...CompanionMatchListItem
      }
      location {
        id
        street
        postal_code
        city
        lat
        lng
      }
    }
    ${CompanionMatchListItem.fragments.companion}
  `,
};

export default InfoDrawer;

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow: visible;
    padding-top: 16px;
  `,
  title: css`
    font-size: 16px;
    margin-right: 10px;
  `,
  tabsContainer: css`
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1;
    & div[role='tabpanel'][aria-hidden='false'] {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
    }
  `,
  tabPanel: css`
    margin: 0 -20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  `,
  callout: css`
    margin: 0 20px 16px;
    width: auto !important;
  `,
  addLoneButton: css`
    float: right;
  `,
};
