/** @jsx jsx */
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EmployeeList from '../components/EmployeeList';

const EmployeeListPage = () => (
  <ContentCard elevation={Elevation.TWO} css={styles.list}>
    <ContentCardHeader
      leftElement={<Button text="Filter" icon={IconNames.FILTER} />}
      rightElement={
        <Fragment>
          <Button text="Hinzufügen" icon={IconNames.ADD} intent={Intent.PRIMARY} css={styles.headerButton} />
          <Button text="Exportieren" icon={IconNames.EXPORT} css={styles.headerButton} />
        </Fragment>
      }
    />
    <EmployeeList />
  </ContentCard>
);

export default EmployeeListPage;

const styles = {
  headerButton: css`
    margin-left: 10px;
  `,
  list: css`
    margin-bottom: 20px;
  `,
};
