import gql from 'graphql-tag';
import React from 'react';
import EmailLink from '../../../components/EmailLink';
import ResourceLink from '../../../components/ResourceLink';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { EmployeeListItemFragment } from '../../../generated/graphql';

export type EmployeeListProps = {
  item: EmployeeListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

const EmployeeListItem = ({ item, skeleton, onClick }: EmployeeListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <Text skeleton={skeleton}>{item.user.display_name}</Text> <br />
      <Text skeleton={skeleton} small muted>
        {item.position}
      </Text>
    </TableCell>
    <TableCell>
      {item.user.roles.map(role => (
        <ResourceLink key={role.id} path={`/roles/${role.id}`} text={role.name} skeleton={skeleton} />
      ))}
    </TableCell>
    <TableCell>
      <EmailLink email={item.user.email} skeleton={skeleton} />
    </TableCell>
  </tr>
);

EmployeeListItem.fragments = {
  employee: gql`
    fragment EmployeeListItem on Employee {
      id
      position
      user {
        id
        display_name
        email
        phone
        mobile
        roles {
          id
          name
        }
      }
    }
  `,
};

export default EmployeeListItem;
