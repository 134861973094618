import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { SoulCreateFormFragment, useCreateSoulMutation, UserGender, UserStatus } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import SoulForm from './SoulForm';

type SoulCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const SoulCreateOverlay = ({ onSuccess, ...overlayProps }: SoulCreateOverlayProps) => {
  const [createSoul] = useCreateSoulMutation();
  const handleSubmit = useSubmit({
    mutate: (values: SoulCreateFormFragment) =>
      createSoul({
        variables: {
          input: {
            ...values,
            user: {
              create: values.user,
            },
            tags: {
              sync: values.tags.map(tag => tag.id),
            },
            organisation: {
              connect: values.organisation ? values.organisation.id : null,
              disconnect: !values.organisation,
            },
          },
        },
      }),
    onCompleted: ({ data }) => {
      if (data && data.createSoul) {
        onSuccess(data.createSoul.id);
      }
    },
    getErrors: getLaravelValidationErrors,
  });

  const initialValues: SoulCreateFormFragment = {
    buys_ticket: false,
    needs_blind: false,
    needs_drive: false,
    needs_trainstation_fetch: false,
    needs_wheelchair: false,
    needs_wheeled_walker: false,
    user: {
      status: UserStatus.Active,
      first_name: '',
      last_name: '',
      gender: UserGender.Female,
    },
    organisation: null,
    tags: [],
  };

  return (
    <ContentOverlay {...overlayProps}>
      <SoulForm<SoulCreateFormFragment>
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default SoulCreateOverlay;
