import { assertNever } from '../../helpers/utils';
import { ActionType, MatchingStateType, SignupSkeleton } from './types';

export const initialState: MatchingStateType = {
  takenTickets: 0,
  prospects: {
    groups: [],
    souls: [],
    companions: [],
  },
  candidates: {
    groups: [],
    teams: [],
    souls: [],
    companions: [],
  },
};

export const isCompanionCandidate = (
  { candidates: { companions, teams, groups } }: MatchingStateType,
  companionSignupId: string,
) => {
  if (companions.some(companion => companion === companionSignupId)) {
    return true;
  }
  if (teams.some(team => team.companion === companionSignupId)) {
    return true;
  }
  if (groups.some(group => group.companion === companionSignupId)) {
    return true;
  }
  return false;
};

const addDerivedState = (state: MatchingStateType): MatchingStateType => {
  const takenTickets =
    state.candidates.souls.length +
    state.candidates.companions.length +
    state.candidates.teams.reduce<number>((acc, team) => acc + 1 + team.souls.length, 0) +
    state.candidates.groups.reduce<number>((acc, group) => acc + 1 + group.souls.length, 0);

  const possibleCardholders = [
    ...state.candidates.companions,
    ...state.candidates.teams.map(team => team.companion),
    ...state.candidates.groups.map(group => group.companion),
  ];
  const cardholder =
    !state.cardholder || !possibleCardholders.includes(state.cardholder) ? possibleCardholders[0] : state.cardholder;

  return {
    ...state,
    takenTickets,
    cardholder,
  };
};

const importCandidate = (acc: MatchingStateType, signup: SignupSkeleton) => {
  if (signup.leader.length && signup.soul) {
    return acc;
  }

  if (signup.group.length) {
    const groupObj = {
      companion: signup.id,
      souls: signup.group!.map(soulSignup => soulSignup.id),
    };
    return {
      ...acc,
      candidates: signup.companion!.organisation
        ? {
            ...acc.candidates,
            groups: [...acc.candidates.groups, groupObj],
          }
        : {
            ...acc.candidates,
            teams: [...acc.candidates.teams, groupObj],
          },
    };
  }

  return {
    ...acc,
    candidates: signup.companion
      ? {
          ...acc.candidates,
          companions: [...acc.candidates.companions, signup.id],
        }
      : {
          ...acc.candidates,
          souls: [...acc.candidates.souls, signup.id],
        },
  };
};

const importProspect = (acc: MatchingStateType, signup: SignupSkeleton) => {
  if (signup.leader.length && signup.soul && signup.soul.organisation) {
    return acc;
  }

  if (signup.group.length && signup.companion!.organisation) {
    return {
      ...acc,
      prospects: {
        ...acc.prospects,
        groups: [
          ...acc.prospects.groups,
          {
            companion: signup.id,
            souls: signup.group!.map(soulSignup => soulSignup.id),
          },
        ],
      },
    };
  }

  if (signup.companion) {
    return {
      ...acc,
      prospects: {
        ...acc.prospects,
        companions: [...acc.prospects.companions, signup.id],
      },
    };
  }

  return {
    ...acc,
    prospects: {
      ...acc.prospects,
      souls: [...acc.prospects.souls, signup.id],
    },
  };
};

const init = (signups: SignupSkeleton[]) =>
  signups.reduce<MatchingStateType>(
    (acc, signup) => (signup.is_candidate ? importCandidate(acc, signup) : importProspect(acc, signup)),
    initialState,
  );

const addLoneSoul = (state: MatchingStateType, loneSoul: string) => ({
  ...state,
  prospects: {
    ...state.prospects,
    souls: state.prospects.souls.filter(soul => soul !== loneSoul),
  },
  candidates: {
    ...state.candidates,
    souls: [...state.candidates.souls, loneSoul],
  },
});

const removeLoneSoul = (state: MatchingStateType, loneSoul: string) => ({
  ...state,
  candidates: {
    ...state.candidates,
    souls: state.candidates.souls.filter(soul => soul !== loneSoul),
  },
  prospects: {
    ...state.prospects,
    souls: [...state.prospects.souls, loneSoul],
  },
});

const addLoneCompanion = (state: MatchingStateType, loneCompanion: string) => ({
  ...state,
  prospects: {
    ...state.prospects,
    companions: state.prospects.companions.filter(companion => companion !== loneCompanion),
  },
  candidates: {
    ...state.candidates,
    companions: [...state.candidates.companions, loneCompanion],
  },
});

const removeLoneCompanion = (state: MatchingStateType, loneCompanion: string) => ({
  ...state,
  candidates: {
    ...state.candidates,
    companions: state.candidates.companions.filter(companion => companion !== loneCompanion),
  },
  prospects: {
    ...state.prospects,
    companions: [...state.prospects.companions, loneCompanion],
  },
});

const addTeam = (state: MatchingStateType, team: { companion: string; soul: string }) => {
  // Add new team if companion was a prospect
  if (state.prospects.companions.includes(team.companion)) {
    return {
      ...state,
      prospects: {
        ...state.prospects,
        companions: state.prospects.companions.filter(companion => companion !== team.companion),
        souls: state.prospects.souls.filter(soul => soul !== team.soul),
      },
      candidates: {
        ...state.candidates,
        teams: [
          ...state.candidates.teams,
          {
            companion: team.companion,
            souls: [team.soul],
          },
        ],
      },
    };
  }

  // Add new team if companion was a loneCompanion
  if (state.candidates.companions.includes(team.companion)) {
    return {
      ...state,
      prospects: {
        ...state.prospects,
        souls: state.prospects.souls.filter(soul => soul !== team.soul),
      },
      candidates: {
        ...state.candidates,
        companions: state.candidates.companions.filter(companion => companion !== team.companion),
        teams: [
          ...state.candidates.teams,
          {
            companion: team.companion,
            souls: [team.soul],
          },
        ],
      },
    };
  }

  // Add soul to existing team
  return {
    ...state,
    prospects: {
      ...state.prospects,
      souls: state.prospects.souls.filter(soul => soul !== team.soul),
    },
    candidates: {
      ...state.candidates,
      teams: state.candidates.teams.map(existingTeam =>
        existingTeam.companion === team.companion
          ? {
              ...existingTeam,
              souls: [...existingTeam.souls, team.soul],
            }
          : existingTeam,
      ),
    },
  };
};

const addGroup = (state: MatchingStateType, group: { companion: string; souls: string[] }) => ({
  ...state,
  prospects: {
    ...state.prospects,
    groups: state.prospects.groups.filter(existingGroup => existingGroup.companion !== group.companion),
  },
  candidates: {
    ...state.candidates,
    groups: [
      ...state.candidates.groups,
      {
        ...group,
      },
    ],
  },
});

const removeTeam = (state: MatchingStateType, companion: string) => {
  const team = state.candidates.teams.find(team => team.companion === companion);
  if (!team) {
    return state;
  }

  return {
    ...state,
    prospects: {
      ...state.prospects,
      companions: [...state.prospects.companions, team.companion],
      souls: [...state.prospects.souls, ...team.souls],
    },
    candidates: {
      ...state.candidates,
      teams: state.candidates.teams.filter(existingTeam => existingTeam.companion !== team.companion),
    },
  };
};

const removeTeamMember = (state: MatchingStateType, soul: string) => {
  const team = state.candidates.teams.find(team => team.souls.includes(soul));
  if (!team) {
    return state;
  }

  if (team.souls.length <= 1) {
    return removeTeam(state, team.companion);
  }

  return {
    ...state,
    prospects: {
      ...state.prospects,
      souls: [...state.prospects.souls, soul],
    },
    candidates: {
      ...state.candidates,
      teams: state.candidates.teams.map(existingTeam =>
        existingTeam.companion === team.companion
          ? {
              ...team,
              souls: team.souls.filter(existingSoul => existingSoul !== soul),
            }
          : existingTeam,
      ),
    },
  };
};

const removeGroup = (state: MatchingStateType, companion: string) => {
  const group = state.candidates.groups.find(group => group.companion === companion);
  if (!group) {
    return state;
  }

  return {
    ...state,
    prospects: {
      ...state.prospects,
      groups: [
        ...state.prospects.groups,
        {
          ...group,
        },
      ],
    },
    candidates: {
      ...state.candidates,
      groups: state.candidates.groups.filter(existingGroup => existingGroup.companion !== group.companion),
    },
  };
};

const removeGroupMember = (state: MatchingStateType, soul: string) => {
  const group = state.candidates.groups.find(group => group.souls.includes(soul));
  if (!group) {
    return state;
  }

  if (group.souls.length <= 1) {
    return removeGroup(state, group.companion);
  }

  // TODO: Currently, we don't allow group members to turned into a
  // loneSoul prospect. When a group member is removed, that person will just "disappear"

  return {
    ...state,
    candidates: {
      ...state.candidates,
      groups: state.candidates.groups.map(existingGroup =>
        existingGroup.companion === group.companion
          ? {
              ...group,
              souls: group.souls.filter(existingSoul => existingSoul !== soul),
            }
          : existingGroup,
      ),
    },
  };
};

const setCardholder = (state: MatchingStateType, cardholder: string) => ({
  ...state,
  cardholder,
});

export const reducer = (state: MatchingStateType, action: ActionType): MatchingStateType => {
  console.log('before state: ', state);
  console.log('action: ', action);
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'init':
      return addDerivedState(init(action.payload));
    case 'addLoneSoul':
      return addDerivedState(addLoneSoul(state, action.payload));
    case 'removeLoneSoul':
      return addDerivedState(removeLoneSoul(state, action.payload));
    case 'addLoneCompanion':
      return addDerivedState(addLoneCompanion(state, action.payload));
    case 'removeLoneCompanion':
      return addDerivedState(removeLoneCompanion(state, action.payload));
    case 'addTeam':
      return addDerivedState(addTeam(state, action.payload));
    case 'removeTeam':
      return addDerivedState(removeTeam(state, action.payload));
    case 'removeTeamMember':
      return addDerivedState(removeTeamMember(state, action.payload));
    case 'addGroup':
      return addDerivedState(addGroup(state, action.payload));
    case 'removeGroup':
      return addDerivedState(removeGroup(state, action.payload));
    case 'removeGroupMember':
      return addDerivedState(removeGroupMember(state, action.payload));
    case 'setCardholder':
      return addDerivedState(setCardholder(state, action.payload));
    default:
      return assertNever(action);
  }
};
