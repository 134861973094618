import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import client from '../client';
import LoginPage from '../modules/auth/pages/LoginPage';
import MainPage from '../pages/MainPage';
import NotFoundPage from '../pages/NotFoundPage';
import PrivateRoute from './PrivateRoute';

const App = () => (
  <ApolloProvider client={client}>
    <Router>
      <Switch>
        <Route path="/not-found" exact component={NotFoundPage} />
        <Route path="/login" component={LoginPage} />
        <PrivateRoute path="/" component={MainPage} />
      </Switch>
    </Router>
  </ApolloProvider>
);

export default App;
