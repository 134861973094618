/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-01-01 13:00:00`. */
  DateTime: any,
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any,
};

export type AddGroupSignupInput = {
  companion: Scalars['ID'],
  souls: Array<Scalars['ID']>,
};

export type AddSignupsInput = {
  id: Scalars['ID'],
  loneSouls?: Maybe<Array<Scalars['ID']>>,
  loneCompanions?: Maybe<Array<Scalars['ID']>>,
  group?: Maybe<AddGroupSignupInput>,
  notes?: Maybe<Scalars['String']>,
};

export type Allotment = {
   __typename?: 'Allotment',
  id: Scalars['ID'],
  name: Scalars['String'],
  status: AllotmentStatus,
  amount: Scalars['Int'],
  amount_left: Scalars['Int'],
  start: Scalars['DateTime'],
  end?: Maybe<Scalars['DateTime']>,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
};

export enum AllotmentStatus {
  Planned = 'PLANNED',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Depleted = 'DEPLETED'
}

export type Applicant = Companion | Soul;

export type AuthPayload = {
   __typename?: 'AuthPayload',
  access_token: Scalars['String'],
  refresh_token: Scalars['String'],
  expires_in: Scalars['Int'],
  token_type: Scalars['String'],
  user: User,
};

export type CandidatesInput = {
  cardholder: Scalars['ID'],
  groups: Array<GroupInput>,
  teams: Array<GroupInput>,
  souls: Array<Scalars['ID']>,
  companions: Array<Scalars['ID']>,
};

export type Category = {
   __typename?: 'Category',
  id: Scalars['ID'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  color: Scalars['String'],
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
  deleted_at?: Maybe<Scalars['DateTime']>,
};

export type Companion = Person & {
   __typename?: 'Companion',
  id: Scalars['ID'],
  notes?: Maybe<Scalars['String']>,
  can_wheelchair: Scalars['Boolean'],
  can_wheeled_walker: Scalars['Boolean'],
  can_drive: Scalars['Boolean'],
  can_blind: Scalars['Boolean'],
  user: User,
  organisation?: Maybe<Organisation>,
  tags: Array<Tag>,
  signups?: Maybe<SignupConnection>,
  future_signups?: Maybe<SignupConnection>,
  attends?: Maybe<SignupConnection>,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
  deleted_at?: Maybe<Scalars['DateTime']>,
};


export type CompanionUserArgs = {
  trashed?: Maybe<Trashed>
};


export type CompanionOrganisationArgs = {
  trashed?: Maybe<Trashed>
};


export type CompanionSignupsArgs = {
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};


export type CompanionFuture_SignupsArgs = {
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};


export type CompanionAttendsArgs = {
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};

export type CompanionConnection = {
   __typename?: 'CompanionConnection',
  pageInfo: PageInfo,
  edges?: Maybe<Array<Maybe<CompanionEdge>>>,
};

export type CompanionEdge = {
   __typename?: 'CompanionEdge',
  node?: Maybe<Companion>,
  cursor: Scalars['String'],
};

export type Contact = {
   __typename?: 'Contact',
  id: Scalars['ID'],
  user: User,
  donor: Donor,
  notes?: Maybe<Scalars['String']>,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
  deleted_at?: Maybe<Scalars['DateTime']>,
};


export type ContactUserArgs = {
  trashed?: Maybe<Trashed>
};


export type ContactDonorArgs = {
  trashed?: Maybe<Trashed>
};

export type CreateAllotmentInput = {
  name: Scalars['String'],
  amount: Scalars['Int'],
  start: Scalars['DateTime'],
  end?: Maybe<Scalars['DateTime']>,
};

export type CreateCategoryInput = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  color: Scalars['String'],
};

export type CreateCompanionInput = {
  notes?: Maybe<Scalars['String']>,
  can_wheelchair?: Maybe<Scalars['Boolean']>,
  can_wheeled_walker?: Maybe<Scalars['Boolean']>,
  can_drive?: Maybe<Scalars['Boolean']>,
  can_blind?: Maybe<Scalars['Boolean']>,
  user: UpdateUserRelation,
  tags?: Maybe<UpdateTagsRelation>,
  organisation?: Maybe<UpdateOrganisationRelation>,
};

export type CreateContactInput = {
  donor_id: Scalars['ID'],
  user?: Maybe<UpdateUserRelation>,
  notes?: Maybe<Scalars['String']>,
};

export type CreateDonorInput = {
  name: Scalars['String'],
  public?: Maybe<Scalars['Boolean']>,
  street?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
};

export type CreateEventInput = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  start: Scalars['DateTime'],
  end?: Maybe<Scalars['DateTime']>,
  ticket_type: EventTicketType,
  tickets?: Maybe<Scalars['Int']>,
  ticket_time?: Maybe<Scalars['Int']>,
  reservation_number?: Maybe<Scalars['String']>,
  ticket_retrieval_location?: Maybe<Scalars['String']>,
  ticket_retrieval_type?: Maybe<EventTicketRetrievalType>,
  notes?: Maybe<Scalars['String']>,
  donor: UpdateDonorRelation,
  contact: UpdateContactRelation,
  location: UpdateLocationRelation,
  allotment?: Maybe<UpdateAllotmentRelation>,
  categories?: Maybe<UpdateCategoriesRelation>,
};

export type CreateLocationInput = {
  name: Scalars['String'],
  street: Scalars['String'],
  city: Scalars['String'],
  postal_code: Scalars['String'],
  meeting_point: Scalars['String'],
  public_transport: Scalars['String'],
  accessible: Scalars['Boolean'],
};

export type CreateOrganisationInput = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  souls?: Maybe<UpdateOrganisationSoulRelation>,
  companions?: Maybe<UpdateOrganisationCompanionRelation>,
};

export type CreateSoulInput = {
  target_group?: Maybe<SoulTargetGroup>,
  notes?: Maybe<Scalars['String']>,
  buys_ticket?: Maybe<Scalars['Boolean']>,
  needs_wheelchair?: Maybe<Scalars['Boolean']>,
  needs_wheeled_walker?: Maybe<Scalars['Boolean']>,
  needs_drive?: Maybe<Scalars['Boolean']>,
  needs_trainstation_fetch?: Maybe<Scalars['Boolean']>,
  needs_blind?: Maybe<Scalars['Boolean']>,
  user: UpdateUserRelation,
  tags?: Maybe<UpdateTagsRelation>,
  organisation?: Maybe<UpdateOrganisationRelation>,
};

export type CreateUserInput = {
  roles?: Maybe<UpdateRolesRelation>,
  status?: Maybe<UserStatus>,
  first_name: Scalars['String'],
  last_name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  gender: UserGender,
  street?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  mobile?: Maybe<Scalars['String']>,
};



export type Donor = {
   __typename?: 'Donor',
  id: Scalars['ID'],
  name: Scalars['String'],
  public: Scalars['Boolean'],
  street?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  contacts: Array<Contact>,
  locations: Array<Location>,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
  deleted_at?: Maybe<Scalars['DateTime']>,
};


export type DonorContactsArgs = {
  trashed?: Maybe<Trashed>
};


export type DonorLocationsArgs = {
  trashed?: Maybe<Trashed>
};

export type DonorConnection = {
   __typename?: 'DonorConnection',
  pageInfo: PageInfo,
  edges?: Maybe<Array<Maybe<DonorEdge>>>,
};

export type DonorEdge = {
   __typename?: 'DonorEdge',
  node?: Maybe<Donor>,
  cursor: Scalars['String'],
};

export type Email = {
   __typename?: 'Email',
  id: Scalars['ID'],
  status: EmailStatus,
  opened_first_at?: Maybe<Scalars['DateTime']>,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
};

export enum EmailStatus {
  Queued = 'QUEUED',
  Scheduled = 'SCHEDULED',
  Sent = 'SENT',
  Opened = 'OPENED',
  Rejected = 'REJECTED',
  Invalid = 'INVALID',
  SoftBounced = 'SOFT_BOUNCED',
  HardBounced = 'HARD_BOUNCED',
  MarkedSpam = 'MARKED_SPAM',
  Delayed = 'DELAYED',
  Unknown = 'UNKNOWN'
}

export type Employee = {
   __typename?: 'Employee',
  id: Scalars['ID'],
  position: Scalars['String'],
  user: User,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
  deleted_at?: Maybe<Scalars['DateTime']>,
};


export type EmployeeUserArgs = {
  trashed?: Maybe<Trashed>
};

export type EmployeeConnection = {
   __typename?: 'EmployeeConnection',
  pageInfo: PageInfo,
  edges?: Maybe<Array<Maybe<EmployeeEdge>>>,
};

export type EmployeeEdge = {
   __typename?: 'EmployeeEdge',
  node?: Maybe<Employee>,
  cursor: Scalars['String'],
};

export type Event = {
   __typename?: 'Event',
  id: Scalars['ID'],
  slug: Scalars['String'],
  status: EventStatus,
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  start: Scalars['DateTime'],
  end?: Maybe<Scalars['DateTime']>,
  tickets?: Maybe<Scalars['Int']>,
  available_tickets?: Maybe<Scalars['Int']>,
  taken_tickets: Scalars['Int'],
  ticket_type: EventTicketType,
  ticket_time: Scalars['Int'],
  reservation_number?: Maybe<Scalars['String']>,
  ticket_retrieval_location?: Maybe<Scalars['String']>,
  ticket_retrieval_type?: Maybe<EventTicketRetrievalType>,
  candidates_chosen: Scalars['Boolean'],
  notes?: Maybe<Scalars['String']>,
  donor: Donor,
  contact: Contact,
  location: Location,
  cardholder?: Maybe<Companion>,
  allotment?: Maybe<Allotment>,
  categories: Array<Category>,
  signups: Array<Signup>,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
  deleted_at?: Maybe<Scalars['DateTime']>,
  prospectCount: Scalars['Int'],
  candidateCount: Scalars['Int'],
  inviteeCount: Scalars['Int'],
  attendeeCount: Scalars['Int'],
  prospects: Array<Signup>,
  candidates: Array<Signup>,
  invitees: Array<Signup>,
  attendees: Array<Signup>,
  groups: Array<Signup>,
  teams: Array<Signup>,
  loneSouls: Array<Signup>,
  loneCompanions: Array<Signup>,
};


export type EventDonorArgs = {
  trashed?: Maybe<Trashed>
};


export type EventContactArgs = {
  trashed?: Maybe<Trashed>
};


export type EventLocationArgs = {
  trashed?: Maybe<Trashed>
};


export type EventCardholderArgs = {
  trashed?: Maybe<Trashed>
};


export type EventCategoriesArgs = {
  trashed?: Maybe<Trashed>
};


export type EventSignupsArgs = {
  trashed?: Maybe<Trashed>
};

export type EventConnection = {
   __typename?: 'EventConnection',
  pageInfo: PageInfo,
  edges?: Maybe<Array<Maybe<EventEdge>>>,
};

export type EventEdge = {
   __typename?: 'EventEdge',
  node?: Maybe<Event>,
  cursor: Scalars['String'],
};

export enum EventStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export enum EventTicketRetrievalType {
  Onsite = 'ONSITE',
  Delivery = 'DELIVERY'
}

export enum EventTicketType {
  Fixed = 'FIXED',
  Allotment = 'ALLOTMENT',
  Unlimited = 'UNLIMITED'
}

export type ForgotPasswordInput = {
  email: Scalars['String'],
};

export type ForgotPasswordResponse = {
   __typename?: 'ForgotPasswordResponse',
  status: Scalars['String'],
  message?: Maybe<Scalars['String']>,
};

export type GroupInput = {
  companion: Scalars['ID'],
  souls: Array<Scalars['ID']>,
};

export enum Intent {
  None = 'none',
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger'
}

export type Location = {
   __typename?: 'Location',
  id: Scalars['ID'],
  name: Scalars['String'],
  street: Scalars['String'],
  city: Scalars['String'],
  postal_code: Scalars['String'],
  lat: Scalars['Float'],
  lng: Scalars['Float'],
  meeting_point: Scalars['String'],
  public_transport: Scalars['String'],
  accessible: Scalars['Boolean'],
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
  deleted_at?: Maybe<Scalars['DateTime']>,
};

export type LocationConnection = {
   __typename?: 'LocationConnection',
  pageInfo: PageInfo,
  edges?: Maybe<Array<Maybe<LocationEdge>>>,
};

export type LocationEdge = {
   __typename?: 'LocationEdge',
  node?: Maybe<Location>,
  cursor: Scalars['String'],
};

export type LoginInput = {
  username: Scalars['String'],
  password: Scalars['String'],
  branch: Scalars['String'],
};

export type LogoutResponse = {
   __typename?: 'LogoutResponse',
  status: Scalars['String'],
  message?: Maybe<Scalars['String']>,
};

export type Mutation = {
   __typename?: 'Mutation',
  createSoul: Soul,
  approveSoul: Soul,
  denySoul: Soul,
  activateSoul: Soul,
  deactivateSoul: Soul,
  updateSoul?: Maybe<Soul>,
  deleteSoul?: Maybe<Soul>,
  restoreSoul?: Maybe<Soul>,
  forceDeleteSoul?: Maybe<Soul>,
  createCompanion: Companion,
  approveCompanion: Companion,
  denyCompanion: Companion,
  activateCompanion: Companion,
  deactivateCompanion: Companion,
  updateCompanion?: Maybe<Companion>,
  deleteCompanion?: Maybe<Companion>,
  restoreCompanion?: Maybe<Companion>,
  forceDeleteCompanion?: Maybe<Companion>,
  updateEmployee?: Maybe<Employee>,
  deleteEmployee?: Maybe<Employee>,
  restoreEmployee?: Maybe<Employee>,
  forceDeleteEmployee?: Maybe<Employee>,
  createOrganisation: Organisation,
  updateOrganisation?: Maybe<Organisation>,
  deleteOrganisation?: Maybe<Organisation>,
  restoreOrganisation?: Maybe<Organisation>,
  forceDeleteOrganisation?: Maybe<Organisation>,
  createDonor: Donor,
  updateDonor?: Maybe<Donor>,
  deleteDonor?: Maybe<Donor>,
  restoreDonor?: Maybe<Donor>,
  forceDeleteDonor?: Maybe<Donor>,
  createLocation?: Maybe<Location>,
  updateLocation?: Maybe<Location>,
  deleteLocation?: Maybe<Location>,
  restoreLocation?: Maybe<Location>,
  forceDeleteLocation?: Maybe<Location>,
  createContact?: Maybe<Contact>,
  updateContact?: Maybe<Contact>,
  deleteContact?: Maybe<Contact>,
  restoreContact?: Maybe<Contact>,
  forceDeleteContact?: Maybe<Contact>,
  updateRole?: Maybe<Role>,
  deleteRole?: Maybe<Role>,
  createCategory?: Maybe<Category>,
  updateCategory?: Maybe<Category>,
  deleteCategory?: Maybe<Category>,
  restoreCategory?: Maybe<Category>,
  forceDeleteCategory?: Maybe<Category>,
  createAllotment?: Maybe<Allotment>,
  updateAllotment?: Maybe<Allotment>,
  deleteAllotment?: Maybe<Allotment>,
  deleteSignup?: Maybe<Signup>,
  createEvent: Event,
  updateEvent?: Maybe<Event>,
  deleteEvent?: Maybe<Event>,
  restoreEvent?: Maybe<Event>,
  forceDeleteEvent?: Maybe<Event>,
  acceptSignups?: Maybe<Event>,
  addSignups?: Maybe<Event>,
  updateCandidates?: Maybe<Event>,
  login: AuthPayload,
  refreshToken: RefreshTokenPayload,
  logout: LogoutResponse,
  forgotPassword: ForgotPasswordResponse,
  updateForgottenPassword: ForgotPasswordResponse,
  register: AuthPayload,
};


export type MutationCreateSoulArgs = {
  input: CreateSoulInput
};


export type MutationApproveSoulArgs = {
  id: Scalars['ID']
};


export type MutationDenySoulArgs = {
  id: Scalars['ID']
};


export type MutationActivateSoulArgs = {
  id: Scalars['ID']
};


export type MutationDeactivateSoulArgs = {
  id: Scalars['ID']
};


export type MutationUpdateSoulArgs = {
  input: UpdateSoulInput
};


export type MutationDeleteSoulArgs = {
  id: Scalars['ID']
};


export type MutationRestoreSoulArgs = {
  id: Scalars['ID']
};


export type MutationForceDeleteSoulArgs = {
  id: Scalars['ID']
};


export type MutationCreateCompanionArgs = {
  input: CreateCompanionInput
};


export type MutationApproveCompanionArgs = {
  id: Scalars['ID']
};


export type MutationDenyCompanionArgs = {
  id: Scalars['ID']
};


export type MutationActivateCompanionArgs = {
  id: Scalars['ID']
};


export type MutationDeactivateCompanionArgs = {
  id: Scalars['ID']
};


export type MutationUpdateCompanionArgs = {
  input: UpdateCompanionInput
};


export type MutationDeleteCompanionArgs = {
  id: Scalars['ID']
};


export type MutationRestoreCompanionArgs = {
  id: Scalars['ID']
};


export type MutationForceDeleteCompanionArgs = {
  id: Scalars['ID']
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput
};


export type MutationDeleteEmployeeArgs = {
  id: Scalars['ID']
};


export type MutationRestoreEmployeeArgs = {
  id: Scalars['ID']
};


export type MutationForceDeleteEmployeeArgs = {
  id: Scalars['ID']
};


export type MutationCreateOrganisationArgs = {
  input: CreateOrganisationInput
};


export type MutationUpdateOrganisationArgs = {
  input: UpdateOrganisationInput
};


export type MutationDeleteOrganisationArgs = {
  id: Scalars['ID']
};


export type MutationRestoreOrganisationArgs = {
  id: Scalars['ID']
};


export type MutationForceDeleteOrganisationArgs = {
  id: Scalars['ID']
};


export type MutationCreateDonorArgs = {
  input: CreateDonorInput
};


export type MutationUpdateDonorArgs = {
  input: UpdateDonorInput
};


export type MutationDeleteDonorArgs = {
  id: Scalars['ID']
};


export type MutationRestoreDonorArgs = {
  id: Scalars['ID']
};


export type MutationForceDeleteDonorArgs = {
  id: Scalars['ID']
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID']
};


export type MutationRestoreLocationArgs = {
  id: Scalars['ID']
};


export type MutationForceDeleteLocationArgs = {
  id: Scalars['ID']
};


export type MutationCreateContactArgs = {
  input: CreateContactInput
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput
};


export type MutationDeleteContactArgs = {
  id: Scalars['ID']
};


export type MutationRestoreContactArgs = {
  id: Scalars['ID']
};


export type MutationForceDeleteContactArgs = {
  id: Scalars['ID']
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID']
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID']
};


export type MutationRestoreCategoryArgs = {
  id: Scalars['ID']
};


export type MutationForceDeleteCategoryArgs = {
  id: Scalars['ID']
};


export type MutationCreateAllotmentArgs = {
  input: CreateAllotmentInput
};


export type MutationUpdateAllotmentArgs = {
  input: UpdateAllotmentInput
};


export type MutationDeleteAllotmentArgs = {
  id: Scalars['ID']
};


export type MutationDeleteSignupArgs = {
  id: Scalars['ID']
};


export type MutationCreateEventArgs = {
  input: CreateEventInput
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID']
};


export type MutationRestoreEventArgs = {
  id: Scalars['ID']
};


export type MutationForceDeleteEventArgs = {
  id: Scalars['ID']
};


export type MutationAcceptSignupsArgs = {
  id: Scalars['ID'],
  token: Scalars['String']
};


export type MutationAddSignupsArgs = {
  input: AddSignupsInput
};


export type MutationUpdateCandidatesArgs = {
  input: UpdateCandidatesInput
};


export type MutationLoginArgs = {
  input?: Maybe<LoginInput>
};


export type MutationRefreshTokenArgs = {
  input?: Maybe<RefreshTokenInput>
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput
};


export type MutationUpdateForgottenPasswordArgs = {
  input?: Maybe<NewPasswordWithCodeInput>
};


export type MutationRegisterArgs = {
  input?: Maybe<RegisterInput>
};

export type NewPasswordWithCodeInput = {
  email: Scalars['String'],
  token: Scalars['String'],
  password: Scalars['String'],
  password_confirmation: Scalars['String'],
};

export type OrderByClause = {
  field: Scalars['String'],
  order: SortOrder,
};

export type Organisation = {
   __typename?: 'Organisation',
  id: Scalars['ID'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  souls: Array<Soul>,
  companions: Array<Companion>,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
  deleted_at?: Maybe<Scalars['DateTime']>,
};

export type PageInfo = {
   __typename?: 'PageInfo',
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'],
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'],
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>,
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>,
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>,
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>,
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>,
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>,
};

export type PaginatorInfo = {
   __typename?: 'PaginatorInfo',
  /** Total count of available items in the page. */
  count: Scalars['Int'],
  /** Current pagination page. */
  currentPage: Scalars['Int'],
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>,
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'],
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>,
  /** Last page number of the collection. */
  lastPage: Scalars['Int'],
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'],
  /** Total items available in the collection. */
  total: Scalars['Int'],
};

export type Permission = {
   __typename?: 'Permission',
  id: Scalars['ID'],
  name: Scalars['String'],
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
};

export type PermissionConnection = {
   __typename?: 'PermissionConnection',
  pageInfo: PageInfo,
  edges?: Maybe<Array<Maybe<PermissionEdge>>>,
};

export type PermissionEdge = {
   __typename?: 'PermissionEdge',
  node?: Maybe<Permission>,
  cursor: Scalars['String'],
};

export type Person = {
  id: Scalars['ID'],
  user?: Maybe<User>,
  organisation?: Maybe<Organisation>,
  tags: Array<Tag>,
};

export type Query = {
   __typename?: 'Query',
  me?: Maybe<User>,
  tags: Array<Tag>,
  souls?: Maybe<SoulConnection>,
  soul?: Maybe<Soul>,
  companions?: Maybe<CompanionConnection>,
  companion?: Maybe<Companion>,
  employees?: Maybe<EmployeeConnection>,
  employee?: Maybe<Employee>,
  organisations: Array<Organisation>,
  organisation?: Maybe<Organisation>,
  donors?: Maybe<DonorConnection>,
  donor?: Maybe<Donor>,
  locations?: Maybe<LocationConnection>,
  location?: Maybe<Location>,
  contact?: Maybe<Contact>,
  roles: Array<Role>,
  role?: Maybe<Role>,
  permissions?: Maybe<PermissionConnection>,
  permission?: Maybe<Permission>,
  categories: Array<Category>,
  category?: Maybe<Category>,
  allotments: Array<Allotment>,
  allotment?: Maybe<Allotment>,
  signups: Array<Signup>,
  signup?: Maybe<Signup>,
  events?: Maybe<EventConnection>,
  event?: Maybe<Event>,
  isAuthenticated: Scalars['Boolean'],
};


export type QuerySoulsArgs = {
  status: UserStatus,
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>,
  trashed?: Maybe<Trashed>
};


export type QuerySoulArgs = {
  id: Scalars['ID'],
  trashed?: Maybe<Trashed>
};


export type QueryCompanionsArgs = {
  status: UserStatus,
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>,
  trashed?: Maybe<Trashed>
};


export type QueryCompanionArgs = {
  id: Scalars['ID'],
  trashed?: Maybe<Trashed>
};


export type QueryEmployeesArgs = {
  status: UserStatus,
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>,
  trashed?: Maybe<Trashed>
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'],
  trashed?: Maybe<Trashed>
};


export type QueryOrganisationsArgs = {
  trashed?: Maybe<Trashed>
};


export type QueryOrganisationArgs = {
  id: Scalars['ID'],
  trashed?: Maybe<Trashed>
};


export type QueryDonorsArgs = {
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>,
  trashed?: Maybe<Trashed>
};


export type QueryDonorArgs = {
  id: Scalars['ID'],
  trashed?: Maybe<Trashed>
};


export type QueryLocationsArgs = {
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>,
  trashed?: Maybe<Trashed>
};


export type QueryLocationArgs = {
  id: Scalars['ID'],
  trashed?: Maybe<Trashed>
};


export type QueryContactArgs = {
  id: Scalars['ID'],
  trashed?: Maybe<Trashed>
};


export type QueryRoleArgs = {
  id: Scalars['ID']
};


export type QueryPermissionsArgs = {
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};


export type QueryPermissionArgs = {
  id: Scalars['ID']
};


export type QueryCategoriesArgs = {
  trashed?: Maybe<Trashed>
};


export type QueryCategoryArgs = {
  id: Scalars['ID'],
  trashed?: Maybe<Trashed>
};


export type QueryAllotmentArgs = {
  id: Scalars['ID']
};


export type QuerySignupsArgs = {
  event_id: Scalars['ID'],
  trashed?: Maybe<Trashed>
};


export type QuerySignupArgs = {
  id: Scalars['ID'],
  trashed?: Maybe<Trashed>
};


export type QueryEventsArgs = {
  status?: Maybe<EventStatus>,
  orderBy?: Maybe<Array<OrderByClause>>,
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>,
  trashed?: Maybe<Trashed>
};


export type QueryEventArgs = {
  id: Scalars['ID'],
  trashed?: Maybe<Trashed>
};

export type RefreshTokenInput = {
  refresh_token?: Maybe<Scalars['String']>,
};

export type RefreshTokenPayload = {
   __typename?: 'RefreshTokenPayload',
  access_token: Scalars['String'],
  refresh_token: Scalars['String'],
  expires_in: Scalars['Int'],
  token_type: Scalars['String'],
};

export type RegisterInput = {
  name: Scalars['String'],
  email: Scalars['String'],
  password: Scalars['String'],
  password_confirmation: Scalars['String'],
};

export type Role = {
   __typename?: 'Role',
  id: Scalars['ID'],
  name: Scalars['String'],
  permissions: Array<Permission>,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
};

export type Signup = {
   __typename?: 'Signup',
  id: Scalars['ID'],
  event_id: Scalars['String'],
  is_candidate: Scalars['Boolean'],
  is_invited: Scalars['Boolean'],
  has_accepted: Scalars['Boolean'],
  has_rejected: Scalars['Boolean'],
  token: Scalars['String'],
  is_cardholder: Scalars['Boolean'],
  priority: Scalars['Int'],
  event: Event,
  soul?: Maybe<Soul>,
  companion?: Maybe<Companion>,
  group: Array<Signup>,
  leader: Array<Signup>,
  emails: Array<Email>,
  notes?: Maybe<Scalars['String']>,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
  deleted_at?: Maybe<Scalars['DateTime']>,
};

export type SignupConnection = {
   __typename?: 'SignupConnection',
  pageInfo: PageInfo,
  edges?: Maybe<Array<Maybe<SignupEdge>>>,
};

export type SignupEdge = {
   __typename?: 'SignupEdge',
  node?: Maybe<Signup>,
  cursor: Scalars['String'],
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Soul = Person & {
   __typename?: 'Soul',
  id: Scalars['ID'],
  target_group?: Maybe<SoulTargetGroup>,
  notes?: Maybe<Scalars['String']>,
  buys_ticket: Scalars['Boolean'],
  needs_wheelchair: Scalars['Boolean'],
  needs_wheeled_walker: Scalars['Boolean'],
  needs_drive: Scalars['Boolean'],
  needs_trainstation_fetch: Scalars['Boolean'],
  needs_blind: Scalars['Boolean'],
  user: User,
  organisation?: Maybe<Organisation>,
  tags: Array<Tag>,
  signups?: Maybe<SignupConnection>,
  future_signups?: Maybe<SignupConnection>,
  attends?: Maybe<SignupConnection>,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
  deleted_at?: Maybe<Scalars['DateTime']>,
};


export type SoulUserArgs = {
  trashed?: Maybe<Trashed>
};


export type SoulOrganisationArgs = {
  trashed?: Maybe<Trashed>
};


export type SoulSignupsArgs = {
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};


export type SoulFuture_SignupsArgs = {
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};


export type SoulAttendsArgs = {
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};

export type SoulConnection = {
   __typename?: 'SoulConnection',
  pageInfo: PageInfo,
  edges?: Maybe<Array<Maybe<SoulEdge>>>,
};

export type SoulEdge = {
   __typename?: 'SoulEdge',
  node?: Maybe<Soul>,
  cursor: Scalars['String'],
};

export enum SoulTargetGroup {
  Disabled = 'DISABLED',
  Child = 'CHILD',
  Immigrant = 'IMMIGRANT',
  Senior = 'SENIOR',
  SociallyInNeed = 'SOCIALLY_IN_NEED',
  Refugee = 'REFUGEE',
  None = 'NONE'
}

export type Tag = {
   __typename?: 'Tag',
  id: Scalars['ID'],
  name: Scalars['String'],
  icon: Scalars['String'],
  intent: Intent,
  description?: Maybe<Scalars['String']>,
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
};

export enum Trashed {
  Only = 'ONLY',
  With = 'WITH',
  Without = 'WITHOUT'
}

export type UpdateAllotmentInput = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  start?: Maybe<Scalars['DateTime']>,
  end?: Maybe<Scalars['DateTime']>,
};

export type UpdateAllotmentRelation = {
  connect?: Maybe<Scalars['ID']>,
  disconnect?: Maybe<Scalars['Boolean']>,
};

export type UpdateCandidatesInput = {
  id: Scalars['ID'],
  candidates: CandidatesInput,
};

export type UpdateCategoriesRelation = {
  sync?: Maybe<Array<Scalars['ID']>>,
};

export type UpdateCategoryInput = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  color?: Maybe<Scalars['String']>,
};

export type UpdateCompanionInput = {
  id: Scalars['ID'],
  notes?: Maybe<Scalars['String']>,
  can_wheelchair?: Maybe<Scalars['Boolean']>,
  can_wheeled_walker?: Maybe<Scalars['Boolean']>,
  can_drive?: Maybe<Scalars['Boolean']>,
  can_blind?: Maybe<Scalars['Boolean']>,
  user?: Maybe<UpdateUserRelation>,
  tags?: Maybe<UpdateTagsRelation>,
  organisation?: Maybe<UpdateOrganisationRelation>,
};

export type UpdateContactInput = {
  id: Scalars['ID'],
  user?: Maybe<UpdateUserRelation>,
  notes?: Maybe<Scalars['String']>,
};

export type UpdateContactRelation = {
  connect?: Maybe<Scalars['ID']>,
  disconnect?: Maybe<Scalars['Boolean']>,
};

export type UpdateDonorInput = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  public?: Maybe<Scalars['Boolean']>,
  street?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
};

export type UpdateDonorRelation = {
  connect?: Maybe<Scalars['ID']>,
  disconnect?: Maybe<Scalars['Boolean']>,
};

export type UpdateEmployeeInput = {
  id: Scalars['ID'],
  position?: Maybe<Scalars['String']>,
  user?: Maybe<UpdateUserRelation>,
};

export type UpdateEventInput = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  start?: Maybe<Scalars['DateTime']>,
  end?: Maybe<Scalars['DateTime']>,
  ticket_type?: Maybe<EventTicketType>,
  tickets?: Maybe<Scalars['Int']>,
  ticket_time?: Maybe<Scalars['Int']>,
  reservation_number?: Maybe<Scalars['String']>,
  ticket_retrieval_location?: Maybe<Scalars['String']>,
  ticket_retrieval_type?: Maybe<EventTicketRetrievalType>,
  notes?: Maybe<Scalars['String']>,
  donor?: Maybe<UpdateDonorRelation>,
  contact?: Maybe<UpdateContactRelation>,
  location?: Maybe<UpdateLocationRelation>,
  allotment?: Maybe<UpdateAllotmentRelation>,
  categories?: Maybe<UpdateCategoriesRelation>,
};

export type UpdateLocationInput = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  street?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  meeting_point?: Maybe<Scalars['String']>,
  public_transport?: Maybe<Scalars['String']>,
  accessible?: Maybe<Scalars['Boolean']>,
};

export type UpdateLocationRelation = {
  connect?: Maybe<Scalars['ID']>,
  disconnect?: Maybe<Scalars['Boolean']>,
};

export type UpdateOrganisationCompanionRelation = {
  sync?: Maybe<Array<Scalars['ID']>>,
};

export type UpdateOrganisationInput = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  souls?: Maybe<UpdateOrganisationSoulRelation>,
  companions?: Maybe<UpdateOrganisationCompanionRelation>,
};

export type UpdateOrganisationRelation = {
  connect?: Maybe<Scalars['ID']>,
  disconnect?: Maybe<Scalars['Boolean']>,
};

export type UpdateOrganisationSoulRelation = {
  sync?: Maybe<Array<Scalars['ID']>>,
};

export type UpdatePermissionsRelation = {
  sync?: Maybe<Array<Scalars['ID']>>,
};

export type UpdateRoleInput = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  permissions?: Maybe<UpdatePermissionsRelation>,
};

export type UpdateRolesRelation = {
  sync?: Maybe<Array<Scalars['ID']>>,
};

export type UpdateSoulInput = {
  id: Scalars['ID'],
  target_group?: Maybe<SoulTargetGroup>,
  notes?: Maybe<Scalars['String']>,
  buys_ticket?: Maybe<Scalars['Boolean']>,
  needs_wheelchair?: Maybe<Scalars['Boolean']>,
  needs_wheeled_walker?: Maybe<Scalars['Boolean']>,
  needs_drive?: Maybe<Scalars['Boolean']>,
  needs_trainstation_fetch?: Maybe<Scalars['Boolean']>,
  needs_blind?: Maybe<Scalars['Boolean']>,
  user?: Maybe<UpdateUserRelation>,
  tags?: Maybe<UpdateTagsRelation>,
  organisation?: Maybe<UpdateOrganisationRelation>,
};

export type UpdateTagsRelation = {
  sync?: Maybe<Array<Scalars['ID']>>,
};

export type UpdateUserInput = {
  id: Scalars['ID'],
  roles?: Maybe<UpdateRolesRelation>,
  first_name?: Maybe<Scalars['String']>,
  last_name?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  gender?: Maybe<UserGender>,
  street?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  mobile?: Maybe<Scalars['String']>,
};

export type UpdateUserRelation = {
  update?: Maybe<UpdateUserInput>,
  create?: Maybe<CreateUserInput>,
};

export type User = {
   __typename?: 'User',
  id: Scalars['ID'],
  branch_id: Scalars['ID'],
  roles: Array<Role>,
  status: UserStatus,
  first_name: Scalars['String'],
  last_name: Scalars['String'],
  display_name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  gender: UserGender,
  street?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  lat?: Maybe<Scalars['Float']>,
  lng?: Maybe<Scalars['Float']>,
  phone?: Maybe<Scalars['String']>,
  fax?: Maybe<Scalars['String']>,
  mobile?: Maybe<Scalars['String']>,
  is_superadmin: Scalars['Boolean'],
  created_at: Scalars['DateTime'],
  updated_at: Scalars['DateTime'],
  deleted_at?: Maybe<Scalars['DateTime']>,
};

export enum UserGender {
  Male = 'MALE',
  Female = 'FEMALE'
}

export enum UserStatus {
  All = 'ALL',
  New = 'NEW',
  Active = 'ACTIVE',
  Passive = 'PASSIVE'
}

export type PersonOrganisationSelectFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name'>
);

export type PersonTagIconFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
);

export type PersonTagMultiSelectFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
);

export type AllotmentCreateFormFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'name' | 'amount' | 'start' | 'end'>
);

export type AllotmentEditFormFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name' | 'status' | 'amount' | 'amount_left' | 'start' | 'end'>
);

export type AllotmentListItemFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name' | 'status' | 'amount' | 'amount_left' | 'start' | 'end'>
);

export type AllotmentDetailPageFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name' | 'status' | 'amount' | 'amount_left' | 'start' | 'end'>
);

export type AllotmentListQueryVariables = {};


export type AllotmentListQuery = (
  { __typename?: 'Query' }
  & { allotments: Array<(
    { __typename?: 'Allotment' }
    & AllotmentListItemFragment
  )> }
);

export type AllotmentDetailQueryVariables = {
  id: Scalars['ID']
};


export type AllotmentDetailQuery = (
  { __typename?: 'Query' }
  & { allotment: Maybe<(
    { __typename?: 'Allotment' }
    & AllotmentDetailPageFragment
  )> }
);

export type AllotmentEditQueryVariables = {
  id: Scalars['ID']
};


export type AllotmentEditQuery = (
  { __typename?: 'Query' }
  & { allotment: Maybe<(
    { __typename?: 'Allotment' }
    & AllotmentEditFormFragment
  )> }
);

export type CreateAllotmentMutationVariables = {
  input: CreateAllotmentInput
};


export type CreateAllotmentMutation = (
  { __typename?: 'Mutation' }
  & { createAllotment: Maybe<(
    { __typename?: 'Allotment' }
    & AllotmentDetailPageFragment
  )> }
);

export type UpdateAllotmentMutationVariables = {
  input: UpdateAllotmentInput
};


export type UpdateAllotmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAllotment: Maybe<(
    { __typename?: 'Allotment' }
    & AllotmentDetailPageFragment
  )> }
);

export type DeleteAllotmentMutationVariables = {
  id: Scalars['ID']
};


export type DeleteAllotmentMutation = (
  { __typename?: 'Mutation' }
  & { deleteAllotment: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id'>
  )> }
);

export type AttendeeGroupFragment = (
  { __typename?: 'Signup' }
  & { group: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )> }
  & AttendeeListItemFragment
);

export type AcceptSignupsFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id'>
  & { signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id' | 'token' | 'has_accepted' | 'has_rejected'>
  )> }
);

export type AttendeeListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected' | 'notes'>
  & { emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status' | 'opened_first_at'>
  )>, companion: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile'>
    ) }
  )>, soul: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'notes' | 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile'>
    ) }
  )> }
);

export type AttendeeLoneCompanionFragment = (
  { __typename?: 'Signup' }
  & AttendeeListItemFragment
);

export type AttendeeLoneSoulFragment = (
  { __typename?: 'Signup' }
  & AttendeeListItemFragment
);

export type AttendeeTeamFragment = (
  { __typename?: 'Signup' }
  & { group: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )> }
  & AttendeeListItemFragment
);

export type IsUserAuthenticatedQueryVariables = {};


export type IsUserAuthenticatedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isAuthenticated'>
);

export type LoginMutationVariables = {
  input: LoginInput
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'access_token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'branch_id' | 'email' | 'display_name'>
    ) }
  ) }
);

export type CategoryCreateFormFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'name' | 'description' | 'color'>
);

export type CategoryEditFormFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'description' | 'color'>
);

export type CategoryListItemFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'description' | 'color' | 'deleted_at'>
);

export type CategoryDetailPageFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'description' | 'color' | 'deleted_at'>
);

export type CategoryListQueryVariables = {};


export type CategoryListQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & CategoryListItemFragment
  )> }
);

export type CategoryDetailQueryVariables = {
  id: Scalars['ID']
};


export type CategoryDetailQuery = (
  { __typename?: 'Query' }
  & { category: Maybe<(
    { __typename?: 'Category' }
    & CategoryDetailPageFragment
  )> }
);

export type CategoryEditQueryVariables = {
  id: Scalars['ID']
};


export type CategoryEditQuery = (
  { __typename?: 'Query' }
  & { category: Maybe<(
    { __typename?: 'Category' }
    & CategoryEditFormFragment
  )> }
);

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput
};


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory: Maybe<(
    { __typename?: 'Category' }
    & CategoryDetailPageFragment
  )> }
);

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput
};


export type UpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory: Maybe<(
    { __typename?: 'Category' }
    & CategoryDetailPageFragment
  )> }
);

export type DeleteCategoryMutationVariables = {
  id: Scalars['ID']
};


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteCategory: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'deleted_at'>
  )> }
);

export type RestoreCategoryMutationVariables = {
  id: Scalars['ID']
};


export type RestoreCategoryMutation = (
  { __typename?: 'Mutation' }
  & { restoreCategory: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteCategoryMutationVariables = {
  id: Scalars['ID']
};


export type ForceDeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteCategory: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )> }
);

export type CompanionCreateFormFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'notes' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'first_name' | 'last_name' | 'status' | 'gender' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type CompanionEditFormFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'notes' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'gender' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type CompanionListItemFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'display_name' | 'email' | 'phone' | 'mobile'>
  ), organisation: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type CompanionDetailPageFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'notes' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind' | 'deleted_at'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'display_name' | 'first_name' | 'last_name' | 'gender' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type CompanionListQueryVariables = {
  status: UserStatus,
  cursor?: Maybe<Scalars['String']>
};


export type CompanionListQuery = (
  { __typename?: 'Query' }
  & { companions: Maybe<(
    { __typename?: 'CompanionConnection' }
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'CompanionEdge' }
      & { node: Maybe<(
        { __typename?: 'Companion' }
        & CompanionListItemFragment
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type CompanionDetailQueryVariables = {
  id: Scalars['ID']
};


export type CompanionDetailQuery = (
  { __typename?: 'Query' }
  & { companion: Maybe<(
    { __typename?: 'Companion' }
    & CompanionDetailPageFragment
  )> }
);

export type CompanionEditQueryVariables = {
  id: Scalars['ID']
};


export type CompanionEditQuery = (
  { __typename?: 'Query' }
  & { companion: Maybe<(
    { __typename?: 'Companion' }
    & CompanionEditFormFragment
  )> }
);

export type CreateCompanionMutationVariables = {
  input: CreateCompanionInput
};


export type CreateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { createCompanion: (
    { __typename?: 'Companion' }
    & CompanionDetailPageFragment
  ) }
);

export type UpdateCompanionMutationVariables = {
  input: UpdateCompanionInput
};


export type UpdateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanion: Maybe<(
    { __typename?: 'Companion' }
    & CompanionDetailPageFragment
  )> }
);

export type ApproveCompanionMutationVariables = {
  id: Scalars['ID']
};


export type ApproveCompanionMutation = (
  { __typename?: 'Mutation' }
  & { approveCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DenyCompanionMutationVariables = {
  id: Scalars['ID']
};


export type DenyCompanionMutation = (
  { __typename?: 'Mutation' }
  & { denyCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type ActivateCompanionMutationVariables = {
  id: Scalars['ID']
};


export type ActivateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { activateCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DeactivateCompanionMutationVariables = {
  id: Scalars['ID']
};


export type DeactivateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { deactivateCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DeleteCompanionMutationVariables = {
  id: Scalars['ID']
};


export type DeleteCompanionMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanion: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'deleted_at'>
  )> }
);

export type RestoreCompanionMutationVariables = {
  id: Scalars['ID']
};


export type RestoreCompanionMutation = (
  { __typename?: 'Mutation' }
  & { restoreCompanion: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteCompanionMutationVariables = {
  id: Scalars['ID']
};


export type ForceDeleteCompanionMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteCompanion: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
  )> }
);

export type ContactEditFormFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'notes'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'gender' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ) }
);

export type ContactDetailPageFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'notes' | 'deleted_at'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name' | 'first_name' | 'last_name' | 'gender' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ) }
);

export type ContactDetailQueryVariables = {
  id: Scalars['ID']
};


export type ContactDetailQuery = (
  { __typename?: 'Query' }
  & { contact: Maybe<(
    { __typename?: 'Contact' }
    & ContactDetailPageFragment
  )> }
);

export type ContactEditQueryVariables = {
  id: Scalars['ID']
};


export type ContactEditQuery = (
  { __typename?: 'Query' }
  & { contact: Maybe<(
    { __typename?: 'Contact' }
    & ContactEditFormFragment
  )> }
);

export type CreateContactMutationVariables = {
  input: CreateContactInput
};


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact: Maybe<(
    { __typename?: 'Contact' }
    & ContactDetailPageFragment
  )> }
);

export type UpdateContactMutationVariables = {
  input: UpdateContactInput
};


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact: Maybe<(
    { __typename?: 'Contact' }
    & ContactDetailPageFragment
  )> }
);

export type DeleteContactMutationVariables = {
  id: Scalars['ID']
};


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'deleted_at'>
  )> }
);

export type RestoreContactMutationVariables = {
  id: Scalars['ID']
};


export type RestoreContactMutation = (
  { __typename?: 'Mutation' }
  & { restoreContact: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteContactMutationVariables = {
  id: Scalars['ID']
};


export type ForceDeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteContact: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
  )> }
);

export type DonorContactListItemFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile'>
  ) }
);

export type DonorCreateFormFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'name' | 'street' | 'city' | 'postal_code' | 'notes'>
);

export type DonorEditFormFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'notes'>
);

export type DonorListItemFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'id' | 'name'>
  & { contacts: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  )> }
);

export type DonorDetailPageFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'notes' | 'deleted_at'>
  & { contacts: Array<(
    { __typename?: 'Contact' }
    & DonorContactListItemFragment
  )> }
);

export type DonorListQueryVariables = {
  cursor?: Maybe<Scalars['String']>
};


export type DonorListQuery = (
  { __typename?: 'Query' }
  & { donors: Maybe<(
    { __typename?: 'DonorConnection' }
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'DonorEdge' }
      & { node: Maybe<(
        { __typename?: 'Donor' }
        & DonorListItemFragment
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type DonorDetailQueryVariables = {
  id: Scalars['ID']
};


export type DonorDetailQuery = (
  { __typename?: 'Query' }
  & { donor: Maybe<(
    { __typename?: 'Donor' }
    & DonorDetailPageFragment
  )> }
);

export type DonorContactsQueryVariables = {
  id: Scalars['ID']
};


export type DonorContactsQuery = (
  { __typename?: 'Query' }
  & { donor: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { contacts: Array<(
      { __typename?: 'Contact' }
      & ContactSelectFragment
    )> }
  )> }
);

export type DonorLocationsQueryVariables = {
  id: Scalars['ID']
};


export type DonorLocationsQuery = (
  { __typename?: 'Query' }
  & { donor: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { locations: Array<(
      { __typename?: 'Location' }
      & LocationSelectFragment
    )> }
  )> }
);

export type CreateDonorMutationVariables = {
  input: CreateDonorInput
};


export type CreateDonorMutation = (
  { __typename?: 'Mutation' }
  & { createDonor: (
    { __typename?: 'Donor' }
    & DonorDetailPageFragment
  ) }
);

export type DonorEditQueryVariables = {
  id: Scalars['ID']
};


export type DonorEditQuery = (
  { __typename?: 'Query' }
  & { donor: Maybe<(
    { __typename?: 'Donor' }
    & DonorEditFormFragment
  )> }
);

export type UpdateDonorMutationVariables = {
  input: UpdateDonorInput
};


export type UpdateDonorMutation = (
  { __typename?: 'Mutation' }
  & { updateDonor: Maybe<(
    { __typename?: 'Donor' }
    & DonorDetailPageFragment
  )> }
);

export type DeleteDonorMutationVariables = {
  id: Scalars['ID']
};


export type DeleteDonorMutation = (
  { __typename?: 'Mutation' }
  & { deleteDonor: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id' | 'deleted_at'>
  )> }
);

export type RestoreDonorMutationVariables = {
  id: Scalars['ID']
};


export type RestoreDonorMutation = (
  { __typename?: 'Mutation' }
  & { restoreDonor: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteDonorMutationVariables = {
  id: Scalars['ID']
};


export type ForceDeleteDonorMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteDonor: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
  )> }
);

export type EmployeeEditFormFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'position'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'gender' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  ) }
);

export type EmployeeListItemFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'position'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  ) }
);

export type EmployeeRoleMultiSelectFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
);

export type EmployeeDetailPageFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'position' | 'deleted_at'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name' | 'first_name' | 'last_name' | 'gender' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  ) }
);

export type EmployeeListQueryVariables = {
  status: UserStatus,
  cursor?: Maybe<Scalars['String']>
};


export type EmployeeListQuery = (
  { __typename?: 'Query' }
  & { employees: Maybe<(
    { __typename?: 'EmployeeConnection' }
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'EmployeeEdge' }
      & { node: Maybe<(
        { __typename?: 'Employee' }
        & EmployeeListItemFragment
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type EmployeeDetailQueryVariables = {
  id: Scalars['ID']
};


export type EmployeeDetailQuery = (
  { __typename?: 'Query' }
  & { employee: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeDetailPageFragment
  )> }
);

export type EmployeeEditQueryVariables = {
  id: Scalars['ID']
};


export type EmployeeEditQuery = (
  { __typename?: 'Query' }
  & { employee: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeEditFormFragment
  )> }
);

export type UpdateEmployeeMutationVariables = {
  input: UpdateEmployeeInput
};


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployee: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeDetailPageFragment
  )> }
);

export type DeleteEmployeeMutationVariables = {
  id: Scalars['ID']
};


export type DeleteEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { deleteEmployee: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'deleted_at'>
  )> }
);

export type RestoreEmployeeMutationVariables = {
  id: Scalars['ID']
};


export type RestoreEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { restoreEmployee: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteEmployeeMutationVariables = {
  id: Scalars['ID']
};


export type ForceDeleteEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteEmployee: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )> }
);

export type AllotmentSelectFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name'>
);

export type CategoryMultiSelectFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name'>
);

export type ContactSelectFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name'>
  ) }
);

export type EventCreateFormFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'name' | 'description' | 'start' | 'end' | 'tickets' | 'ticket_time' | 'ticket_type' | 'reservation_number' | 'ticket_retrieval_type' | 'ticket_retrieval_location' | 'notes'>
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )>, donor: (
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
  ), contact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
  ), location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ), allotment: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id'>
  )> }
);

export type EventEditFormFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'description' | 'start' | 'end' | 'tickets' | 'ticket_time' | 'ticket_type' | 'reservation_number' | 'ticket_retrieval_type' | 'ticket_retrieval_location' | 'notes'>
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>, donor: (
    { __typename?: 'Donor' }
    & Pick<Donor, 'id' | 'name'>
  ), contact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  ), location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  ), allotment: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id' | 'name'>
  )> }
);

export type EventListItemFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'status' | 'start' | 'end' | 'available_tickets' | 'taken_tickets' | 'candidateCount' | 'attendeeCount'>
  & { signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected'>
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'accessible'>
  ) }
);

export type LocationSelectFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name'>
);

export type RemoveSignupFragment = (
  { __typename?: 'Event' }
  & { prospects: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
  )>, signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
  )> }
);

export type SignupListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'notes'>
  & { companion: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  )>, soul: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'notes' | 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  )> }
);

export type EventDetailPageFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'status' | 'start' | 'end' | 'notes' | 'description' | 'taken_tickets' | 'available_tickets' | 'candidates_chosen' | 'ticket_time' | 'ticket_retrieval_type' | 'ticket_retrieval_location' | 'reservation_number' | 'candidateCount' | 'attendeeCount'>
  & { prospects: Array<(
    { __typename?: 'Signup' }
    & SignupListItemFragment
  )>, candidates: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )>, attendees: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )>, groups: Array<(
    { __typename?: 'Signup' }
    & AttendeeGroupFragment
  )>, teams: Array<(
    { __typename?: 'Signup' }
    & AttendeeTeamFragment
  )>, loneSouls: Array<(
    { __typename?: 'Signup' }
    & AttendeeLoneSoulFragment
  )>, loneCompanions: Array<(
    { __typename?: 'Signup' }
    & AttendeeLoneCompanionFragment
  )>, cardholder: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'mobile' | 'phone' | 'email'>
    ) }
  )>, signups: Array<(
    { __typename?: 'Signup' }
    & { group: Array<(
      { __typename?: 'Signup' }
      & AttendeeListItemFragment
    )>, leader: Array<(
      { __typename?: 'Signup' }
      & AttendeeListItemFragment
    )> }
    & AttendeeListItemFragment
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'accessible' | 'meeting_point' | 'public_transport' | 'street' | 'postal_code' | 'city'>
  ) }
);

export type EventListQueryVariables = {
  cursor?: Maybe<Scalars['String']>,
  status?: Maybe<EventStatus>
};


export type EventListQuery = (
  { __typename?: 'Query' }
  & { events: Maybe<(
    { __typename?: 'EventConnection' }
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'EventEdge' }
      & { node: Maybe<(
        { __typename?: 'Event' }
        & EventListItemFragment
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type EventDetailQueryVariables = {
  id: Scalars['ID']
};


export type EventDetailQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type EventEditQueryVariables = {
  id: Scalars['ID']
};


export type EventEditQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & EventEditFormFragment
  )> }
);

export type UpdateEventMutationVariables = {
  input: UpdateEventInput
};


export type UpdateEventMutation = (
  { __typename?: 'Mutation' }
  & { updateEvent: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type CreateEventMutationVariables = {
  input: CreateEventInput
};


export type CreateEventMutation = (
  { __typename?: 'Mutation' }
  & { createEvent: (
    { __typename?: 'Event' }
    & EventDetailPageFragment
  ) }
);

export type DeleteEventMutationVariables = {
  id: Scalars['ID']
};


export type DeleteEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteEvent: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )> }
);

export type DeleteSignupMutationVariables = {
  id: Scalars['ID']
};


export type DeleteSignupMutation = (
  { __typename?: 'Mutation' }
  & { deleteSignup: Maybe<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
  )> }
);

export type AcceptSignupsMutationVariables = {
  id: Scalars['ID'],
  token: Scalars['String']
};


export type AcceptSignupsMutation = (
  { __typename?: 'Mutation' }
  & { acceptSignups: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type AddSignupsMutationVariables = {
  input: AddSignupsInput
};


export type AddSignupsMutation = (
  { __typename?: 'Mutation' }
  & { addSignups: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type LocationCreateFormFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible'>
);

export type LocationEditFormFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible'>
);

export type LocationListItemFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible' | 'deleted_at'>
);

export type LocationDetailPageFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible' | 'deleted_at'>
);

export type LocationListQueryVariables = {
  cursor?: Maybe<Scalars['String']>
};


export type LocationListQuery = (
  { __typename?: 'Query' }
  & { locations: Maybe<(
    { __typename?: 'LocationConnection' }
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'LocationEdge' }
      & { node: Maybe<(
        { __typename?: 'Location' }
        & LocationListItemFragment
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type LocationDetailQueryVariables = {
  id: Scalars['ID']
};


export type LocationDetailQuery = (
  { __typename?: 'Query' }
  & { location: Maybe<(
    { __typename?: 'Location' }
    & LocationDetailPageFragment
  )> }
);

export type CreateLocationMutationVariables = {
  input: CreateLocationInput
};


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLocation: Maybe<(
    { __typename?: 'Location' }
    & LocationDetailPageFragment
  )> }
);

export type LocationEditQueryVariables = {
  id: Scalars['ID']
};


export type LocationEditQuery = (
  { __typename?: 'Query' }
  & { location: Maybe<(
    { __typename?: 'Location' }
    & LocationEditFormFragment
  )> }
);

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput
};


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation: Maybe<(
    { __typename?: 'Location' }
    & LocationDetailPageFragment
  )> }
);

export type DeleteLocationMutationVariables = {
  id: Scalars['ID']
};


export type DeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & { deleteLocation: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'deleted_at'>
  )> }
);

export type RestoreLocationMutationVariables = {
  id: Scalars['ID']
};


export type RestoreLocationMutation = (
  { __typename?: 'Mutation' }
  & { restoreLocation: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteLocationMutationVariables = {
  id: Scalars['ID']
};


export type ForceDeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteLocation: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  )> }
);

export type AttendListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id'>
  & { event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'start'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'color'>
    )> }
  ) }
);

export type CandidateListFragment = (
  { __typename?: 'Signup' }
  & { group: Array<(
    { __typename?: 'Signup' }
    & CandidateListItemFragment
  )>, leader: Array<(
    { __typename?: 'Signup' }
    & CandidateListItemFragment
  )> }
  & CandidateListItemFragment
);

export type CandidateListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected' | 'notes' | 'priority'>
  & { emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status' | 'opened_first_at'>
  )>, companion: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng'>
    ) }
  )>, soul: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'notes' | 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng'>
    ) }
  )> }
);

export type CompanionMatchListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected' | 'notes' | 'priority'>
  & { companion: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng'>
    ) }
  )> }
);

export type FutureSignupListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected'>
  & { emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status' | 'opened_first_at'>
  )>, event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'status' | 'candidates_chosen' | 'start'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'color'>
    )> }
  ) }
);

export type CompanionMatchingTabFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id'>
  & { signups: Array<(
    { __typename?: 'Signup' }
    & CompanionMatchListItemFragment
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'street' | 'postal_code' | 'city' | 'lat' | 'lng'>
  ) }
);

export type ProspectGroupListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'notes' | 'priority'>
  & { companion: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  )>, group: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id' | 'notes' | 'priority'>
    & { soul: Maybe<(
      { __typename?: 'Soul' }
      & Pick<Soul, 'id' | 'notes' | 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
      )>, user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'display_name'>
      ) }
    )> }
  )> }
);

export type ProspectListFragment = (
  { __typename?: 'Signup' }
  & { group: Array<(
    { __typename?: 'Signup' }
    & ProspectListItemFragment
  )>, leader: Array<(
    { __typename?: 'Signup' }
    & ProspectListItemFragment
  )> }
  & ProspectListItemFragment
);

export type ProspectListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected' | 'notes' | 'priority'>
  & { emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status' | 'opened_first_at'>
  )>, companion: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng'>
    ) }
  )>, soul: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'notes' | 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng'>
    ) }
  )> }
);

export type MatchingPageFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'status' | 'start' | 'end' | 'taken_tickets' | 'available_tickets' | 'candidates_chosen'>
  & { cardholder: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'mobile' | 'phone' | 'email'>
    ) }
  )>, categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'color'>
  )>, signups: Array<(
    { __typename?: 'Signup' }
    & CandidateListFragment
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'accessible' | 'meeting_point' | 'public_transport' | 'street' | 'postal_code' | 'city' | 'lat' | 'lng'>
  ) }
);

export type EventMatchingQueryVariables = {
  id: Scalars['ID']
};


export type EventMatchingQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & MatchingPageFragment
  )> }
);

export type UpdateEventCandidatesMutationVariables = {
  input: UpdateCandidatesInput
};


export type UpdateEventCandidatesMutation = (
  { __typename?: 'Mutation' }
  & { updateCandidates: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type CandidateSignupQueryVariables = {
  id: Scalars['ID']
};


export type CandidateSignupQuery = (
  { __typename?: 'Query' }
  & { signup: Maybe<(
    { __typename?: 'Signup' }
    & CandidateListItemFragment
  )> }
);

export type ProspectSignupQueryVariables = {
  id: Scalars['ID']
};


export type ProspectSignupQuery = (
  { __typename?: 'Query' }
  & { signup: Maybe<(
    { __typename?: 'Signup' }
    & ProspectListItemFragment
  )> }
);

export type ProspectGroupSignupQueryVariables = {
  id: Scalars['ID']
};


export type ProspectGroupSignupQuery = (
  { __typename?: 'Query' }
  & { signup: Maybe<(
    { __typename?: 'Signup' }
    & ProspectGroupListItemFragment
  )> }
);

export type CompanionMatchingQueryVariables = {
  id: Scalars['ID']
};


export type CompanionMatchingQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & CompanionMatchingTabFragment
  )> }
);

export type SoulAttendsQueryVariables = {
  id: Scalars['ID'],
  cursor?: Maybe<Scalars['String']>
};


export type SoulAttendsQuery = (
  { __typename?: 'Query' }
  & { soul: Maybe<(
    { __typename?: 'Soul' }
    & { attends: Maybe<(
      { __typename?: 'SignupConnection' }
      & { edges: Maybe<Array<Maybe<(
        { __typename?: 'SignupEdge' }
        & { node: Maybe<(
          { __typename?: 'Signup' }
          & AttendListItemFragment
        )> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  )> }
);

export type CompanionAttendsQueryVariables = {
  id: Scalars['ID'],
  cursor?: Maybe<Scalars['String']>
};


export type CompanionAttendsQuery = (
  { __typename?: 'Query' }
  & { companion: Maybe<(
    { __typename?: 'Companion' }
    & { attends: Maybe<(
      { __typename?: 'SignupConnection' }
      & { edges: Maybe<Array<Maybe<(
        { __typename?: 'SignupEdge' }
        & { node: Maybe<(
          { __typename?: 'Signup' }
          & AttendListItemFragment
        )> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  )> }
);

export type SoulFutureSignupsQueryVariables = {
  id: Scalars['ID'],
  cursor?: Maybe<Scalars['String']>
};


export type SoulFutureSignupsQuery = (
  { __typename?: 'Query' }
  & { soul: Maybe<(
    { __typename?: 'Soul' }
    & { future_signups: Maybe<(
      { __typename?: 'SignupConnection' }
      & { edges: Maybe<Array<Maybe<(
        { __typename?: 'SignupEdge' }
        & { node: Maybe<(
          { __typename?: 'Signup' }
          & FutureSignupListItemFragment
        )> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  )> }
);

export type CompanionFutureSignupsQueryVariables = {
  id: Scalars['ID'],
  cursor?: Maybe<Scalars['String']>
};


export type CompanionFutureSignupsQuery = (
  { __typename?: 'Query' }
  & { companion: Maybe<(
    { __typename?: 'Companion' }
    & { future_signups: Maybe<(
      { __typename?: 'SignupConnection' }
      & { edges: Maybe<Array<Maybe<(
        { __typename?: 'SignupEdge' }
        & { node: Maybe<(
          { __typename?: 'Signup' }
          & FutureSignupListItemFragment
        )> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  )> }
);

export type OrganisationCompanionListItemFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type OrganisationCreateFormFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'name' | 'description'>
  & { souls: Array<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
  )>, companions: Array<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
  )> }
);

export type OrganisationEditFormFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name' | 'description' | 'deleted_at'>
  & { souls: Array<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  )>, companions: Array<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  )> }
);

export type OrganisationListItemFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name' | 'deleted_at'>
);

export type OrganisationSoulListItemFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type OrganisationDetailPageFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name' | 'description' | 'deleted_at'>
  & { souls: Array<(
    { __typename?: 'Soul' }
    & OrganisationSoulListItemFragment
  )>, companions: Array<(
    { __typename?: 'Companion' }
    & OrganisationCompanionListItemFragment
  )> }
);

export type OrganisationListQueryVariables = {};


export type OrganisationListQuery = (
  { __typename?: 'Query' }
  & { organisations: Array<(
    { __typename?: 'Organisation' }
    & OrganisationListItemFragment
  )> }
);

export type OrganisationDetailQueryVariables = {
  id: Scalars['ID']
};


export type OrganisationDetailQuery = (
  { __typename?: 'Query' }
  & { organisation: Maybe<(
    { __typename?: 'Organisation' }
    & OrganisationDetailPageFragment
  )> }
);

export type OrganisationEditQueryVariables = {
  id: Scalars['ID']
};


export type OrganisationEditQuery = (
  { __typename?: 'Query' }
  & { organisation: Maybe<(
    { __typename?: 'Organisation' }
    & OrganisationEditFormFragment
  )> }
);

export type CreateOrganisationMutationVariables = {
  input: CreateOrganisationInput
};


export type CreateOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { createOrganisation: (
    { __typename?: 'Organisation' }
    & OrganisationDetailPageFragment
  ) }
);

export type UpdateOrganisationMutationVariables = {
  input: UpdateOrganisationInput
};


export type UpdateOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganisation: Maybe<(
    { __typename?: 'Organisation' }
    & OrganisationDetailPageFragment
  )> }
);

export type DeleteOrganisationMutationVariables = {
  id: Scalars['ID']
};


export type DeleteOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrganisation: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'deleted_at'>
  )> }
);

export type RestoreOrganisationMutationVariables = {
  id: Scalars['ID']
};


export type RestoreOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { restoreOrganisation: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteOrganisationMutationVariables = {
  id: Scalars['ID']
};


export type ForceDeleteOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteOrganisation: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id'>
  )> }
);

export type SoulCreateFormFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'target_group' | 'notes' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'first_name' | 'last_name' | 'status' | 'gender' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type SoulEditFormFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'target_group' | 'notes' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'gender' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type SoulListItemFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'display_name' | 'email' | 'phone' | 'mobile'>
  ), organisation: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type SoulDetailPageFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'target_group' | 'notes' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind' | 'deleted_at'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'display_name' | 'first_name' | 'last_name' | 'gender' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type SoulListQueryVariables = {
  status: UserStatus,
  cursor?: Maybe<Scalars['String']>
};


export type SoulListQuery = (
  { __typename?: 'Query' }
  & { souls: Maybe<(
    { __typename?: 'SoulConnection' }
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'SoulEdge' }
      & { node: Maybe<(
        { __typename?: 'Soul' }
        & SoulListItemFragment
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type SoulDetailQueryVariables = {
  id: Scalars['ID']
};


export type SoulDetailQuery = (
  { __typename?: 'Query' }
  & { soul: Maybe<(
    { __typename?: 'Soul' }
    & SoulDetailPageFragment
  )> }
);

export type SoulEditQueryVariables = {
  id: Scalars['ID']
};


export type SoulEditQuery = (
  { __typename?: 'Query' }
  & { soul: Maybe<(
    { __typename?: 'Soul' }
    & SoulEditFormFragment
  )> }
);

export type CreateSoulMutationVariables = {
  input: CreateSoulInput
};


export type CreateSoulMutation = (
  { __typename?: 'Mutation' }
  & { createSoul: (
    { __typename?: 'Soul' }
    & SoulDetailPageFragment
  ) }
);

export type UpdateSoulMutationVariables = {
  input: UpdateSoulInput
};


export type UpdateSoulMutation = (
  { __typename?: 'Mutation' }
  & { updateSoul: Maybe<(
    { __typename?: 'Soul' }
    & SoulDetailPageFragment
  )> }
);

export type ApproveSoulMutationVariables = {
  id: Scalars['ID']
};


export type ApproveSoulMutation = (
  { __typename?: 'Mutation' }
  & { approveSoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DenySoulMutationVariables = {
  id: Scalars['ID']
};


export type DenySoulMutation = (
  { __typename?: 'Mutation' }
  & { denySoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type ActivateSoulMutationVariables = {
  id: Scalars['ID']
};


export type ActivateSoulMutation = (
  { __typename?: 'Mutation' }
  & { activateSoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DeactivateSoulMutationVariables = {
  id: Scalars['ID']
};


export type DeactivateSoulMutation = (
  { __typename?: 'Mutation' }
  & { deactivateSoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DeleteSoulMutationVariables = {
  id: Scalars['ID']
};


export type DeleteSoulMutation = (
  { __typename?: 'Mutation' }
  & { deleteSoul: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'deleted_at'>
  )> }
);

export type RestoreSoulMutationVariables = {
  id: Scalars['ID']
};


export type RestoreSoulMutation = (
  { __typename?: 'Mutation' }
  & { restoreSoul: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteSoulMutationVariables = {
  id: Scalars['ID']
};


export type ForceDeleteSoulMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteSoul: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
  )> }
);

export type TagsQueryVariables = {};


export type TagsQuery = (
  { __typename?: 'Query' }
  & { tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagMultiSelectFragment
  )> }
);

export type RolesQueryVariables = {};


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & EmployeeRoleMultiSelectFragment
  )> }
);

export type CategoriesQueryVariables = {};


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & CategoryMultiSelectFragment
  )> }
);

export type AllotmentsQueryVariables = {};


export type AllotmentsQuery = (
  { __typename?: 'Query' }
  & { allotments: Array<(
    { __typename?: 'Allotment' }
    & AllotmentSelectFragment
  )> }
);

export type OrganisationsQueryVariables = {};


export type OrganisationsQuery = (
  { __typename?: 'Query' }
  & { organisations: Array<(
    { __typename?: 'Organisation' }
    & PersonOrganisationSelectFragment
  )> }
);

export const PersonOrganisationSelectFragmentDoc = gql`
    fragment PersonOrganisationSelect on Organisation {
  id
  name
}
    `;
export const PersonTagMultiSelectFragmentDoc = gql`
    fragment PersonTagMultiSelect on Tag {
  id
  name
  icon
  intent
}
    `;
export const AllotmentCreateFormFragmentDoc = gql`
    fragment AllotmentCreateForm on Allotment {
  name
  amount
  start
  end
}
    `;
export const AllotmentEditFormFragmentDoc = gql`
    fragment AllotmentEditForm on Allotment {
  id
  name
  status
  amount
  amount_left
  start
  end
}
    `;
export const AllotmentListItemFragmentDoc = gql`
    fragment AllotmentListItem on Allotment {
  id
  name
  status
  amount
  amount_left
  start
  end
}
    `;
export const AllotmentDetailPageFragmentDoc = gql`
    fragment AllotmentDetailPage on Allotment {
  id
  name
  status
  amount
  amount_left
  start
  end
}
    `;
export const AcceptSignupsFragmentDoc = gql`
    fragment AcceptSignups on Event {
  id
  signups {
    id
    token
    has_accepted
    has_rejected
  }
}
    `;
export const CategoryCreateFormFragmentDoc = gql`
    fragment CategoryCreateForm on Category {
  name
  description
  color
}
    `;
export const CategoryEditFormFragmentDoc = gql`
    fragment CategoryEditForm on Category {
  id
  name
  description
  color
}
    `;
export const CategoryListItemFragmentDoc = gql`
    fragment CategoryListItem on Category {
  id
  name
  description
  color
  deleted_at
}
    `;
export const CategoryDetailPageFragmentDoc = gql`
    fragment CategoryDetailPage on Category {
  id
  name
  description
  color
  deleted_at
}
    `;
export const CompanionCreateFormFragmentDoc = gql`
    fragment CompanionCreateForm on Companion {
  notes
  can_wheelchair
  can_wheeled_walker
  can_drive
  can_blind
  user {
    first_name
    last_name
    status
    gender
    email
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const CompanionEditFormFragmentDoc = gql`
    fragment CompanionEditForm on Companion {
  id
  notes
  can_wheelchair
  can_wheeled_walker
  can_drive
  can_blind
  user {
    id
    first_name
    last_name
    gender
    email
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const PersonTagIconFragmentDoc = gql`
    fragment PersonTagIcon on Tag {
  id
  name
  icon
  intent
}
    `;
export const CompanionListItemFragmentDoc = gql`
    fragment CompanionListItem on Companion {
  id
  can_wheelchair
  can_wheeled_walker
  can_drive
  can_blind
  user {
    id
    status
    display_name
    email
    phone
    mobile
  }
  organisation {
    id
    name
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const CompanionDetailPageFragmentDoc = gql`
    fragment CompanionDetailPage on Companion {
  id
  notes
  can_wheelchair
  can_wheeled_walker
  can_drive
  can_blind
  deleted_at
  user {
    id
    status
    display_name
    first_name
    last_name
    gender
    email
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const ContactEditFormFragmentDoc = gql`
    fragment ContactEditForm on Contact {
  id
  notes
  user {
    id
    first_name
    last_name
    gender
    email
    phone
    mobile
    street
    city
    postal_code
  }
}
    `;
export const ContactDetailPageFragmentDoc = gql`
    fragment ContactDetailPage on Contact {
  id
  notes
  deleted_at
  user {
    id
    display_name
    first_name
    last_name
    gender
    email
    phone
    mobile
    street
    city
    postal_code
  }
}
    `;
export const DonorCreateFormFragmentDoc = gql`
    fragment DonorCreateForm on Donor {
  name
  street
  city
  postal_code
  notes
}
    `;
export const DonorEditFormFragmentDoc = gql`
    fragment DonorEditForm on Donor {
  id
  name
  street
  city
  postal_code
  notes
}
    `;
export const DonorListItemFragmentDoc = gql`
    fragment DonorListItem on Donor {
  id
  name
  contacts {
    id
    user {
      id
      display_name
    }
  }
}
    `;
export const DonorContactListItemFragmentDoc = gql`
    fragment DonorContactListItem on Contact {
  id
  user {
    id
    display_name
    email
    phone
    mobile
  }
}
    `;
export const DonorDetailPageFragmentDoc = gql`
    fragment DonorDetailPage on Donor {
  id
  name
  street
  city
  postal_code
  notes
  deleted_at
  contacts {
    ...DonorContactListItem
  }
}
    ${DonorContactListItemFragmentDoc}`;
export const EmployeeEditFormFragmentDoc = gql`
    fragment EmployeeEditForm on Employee {
  id
  position
  user {
    id
    first_name
    last_name
    gender
    email
    phone
    mobile
    street
    city
    postal_code
    roles {
      id
      name
    }
  }
}
    `;
export const EmployeeListItemFragmentDoc = gql`
    fragment EmployeeListItem on Employee {
  id
  position
  user {
    id
    display_name
    email
    phone
    mobile
    roles {
      id
      name
    }
  }
}
    `;
export const EmployeeRoleMultiSelectFragmentDoc = gql`
    fragment EmployeeRoleMultiSelect on Role {
  id
  name
}
    `;
export const EmployeeDetailPageFragmentDoc = gql`
    fragment EmployeeDetailPage on Employee {
  id
  position
  deleted_at
  user {
    id
    display_name
    first_name
    last_name
    gender
    email
    phone
    mobile
    street
    city
    postal_code
    roles {
      id
      name
    }
  }
}
    `;
export const AllotmentSelectFragmentDoc = gql`
    fragment AllotmentSelect on Allotment {
  id
  name
}
    `;
export const CategoryMultiSelectFragmentDoc = gql`
    fragment CategoryMultiSelect on Category {
  id
  name
}
    `;
export const ContactSelectFragmentDoc = gql`
    fragment ContactSelect on Contact {
  id
  user {
    id
    display_name
  }
}
    `;
export const EventCreateFormFragmentDoc = gql`
    fragment EventCreateForm on Event {
  name
  description
  start
  end
  tickets
  ticket_time
  ticket_type
  reservation_number
  ticket_retrieval_type
  ticket_retrieval_location
  notes
  categories {
    id
  }
  donor {
    id
  }
  contact {
    id
  }
  location {
    id
  }
  allotment {
    id
  }
}
    `;
export const EventEditFormFragmentDoc = gql`
    fragment EventEditForm on Event {
  id
  name
  description
  start
  end
  tickets
  ticket_time
  ticket_type
  reservation_number
  ticket_retrieval_type
  ticket_retrieval_location
  notes
  categories {
    id
    name
  }
  donor {
    id
    name
  }
  contact {
    id
    user {
      id
      display_name
    }
  }
  location {
    id
    name
  }
  allotment {
    id
    name
  }
}
    `;
export const EventListItemFragmentDoc = gql`
    fragment EventListItem on Event {
  id
  name
  status
  start
  end
  available_tickets
  taken_tickets
  candidateCount @client
  attendeeCount @client
  signups {
    id
    is_candidate
    is_invited
    has_accepted
    has_rejected
  }
  location {
    id
    name
    accessible
  }
}
    `;
export const LocationSelectFragmentDoc = gql`
    fragment LocationSelect on Location {
  id
  name
}
    `;
export const RemoveSignupFragmentDoc = gql`
    fragment RemoveSignup on Event {
  prospects @client {
    id
  }
  signups {
    id
  }
}
    `;
export const SignupListItemFragmentDoc = gql`
    fragment SignupListItem on Signup {
  id
  event_id
  notes
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
    }
  }
  soul {
    id
    notes
    buys_ticket
    needs_blind
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
    }
  }
}
    `;
export const AttendeeListItemFragmentDoc = gql`
    fragment AttendeeListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  has_accepted
  has_rejected
  notes
  emails {
    id
    status
    opened_first_at
  }
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      email
      phone
      mobile
    }
  }
  soul {
    id
    notes
    buys_ticket
    needs_blind
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      email
      phone
      mobile
    }
  }
}
    `;
export const AttendeeGroupFragmentDoc = gql`
    fragment AttendeeGroup on Signup {
  ...AttendeeListItem
  group {
    ...AttendeeListItem
  }
}
    ${AttendeeListItemFragmentDoc}`;
export const AttendeeTeamFragmentDoc = gql`
    fragment AttendeeTeam on Signup {
  ...AttendeeListItem
  group {
    ...AttendeeListItem
  }
}
    ${AttendeeListItemFragmentDoc}`;
export const AttendeeLoneSoulFragmentDoc = gql`
    fragment AttendeeLoneSoul on Signup {
  ...AttendeeListItem
}
    ${AttendeeListItemFragmentDoc}`;
export const AttendeeLoneCompanionFragmentDoc = gql`
    fragment AttendeeLoneCompanion on Signup {
  ...AttendeeListItem
}
    ${AttendeeListItemFragmentDoc}`;
export const EventDetailPageFragmentDoc = gql`
    fragment EventDetailPage on Event {
  id
  name
  status
  start
  end
  notes
  description
  taken_tickets
  available_tickets
  candidates_chosen
  ticket_time
  ticket_retrieval_type
  ticket_retrieval_location
  reservation_number
  candidateCount @client
  attendeeCount @client
  prospects @client {
    ...SignupListItem
  }
  candidates @client {
    ...AttendeeListItem
  }
  attendees @client {
    ...AttendeeListItem
  }
  groups @client {
    ...AttendeeGroup
  }
  teams @client {
    ...AttendeeTeam
  }
  loneSouls @client {
    ...AttendeeLoneSoul
  }
  loneCompanions @client {
    ...AttendeeLoneCompanion
  }
  cardholder {
    id
    user {
      id
      display_name
      mobile
      phone
      email
    }
  }
  signups {
    ...AttendeeListItem
    group {
      ...AttendeeListItem
    }
    leader {
      ...AttendeeListItem
    }
  }
  location {
    id
    name
    accessible
    meeting_point
    public_transport
    street
    postal_code
    city
  }
}
    ${SignupListItemFragmentDoc}
${AttendeeListItemFragmentDoc}
${AttendeeGroupFragmentDoc}
${AttendeeTeamFragmentDoc}
${AttendeeLoneSoulFragmentDoc}
${AttendeeLoneCompanionFragmentDoc}`;
export const LocationCreateFormFragmentDoc = gql`
    fragment LocationCreateForm on Location {
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
}
    `;
export const LocationEditFormFragmentDoc = gql`
    fragment LocationEditForm on Location {
  id
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
}
    `;
export const LocationListItemFragmentDoc = gql`
    fragment LocationListItem on Location {
  id
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
  deleted_at
}
    `;
export const LocationDetailPageFragmentDoc = gql`
    fragment LocationDetailPage on Location {
  id
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
  deleted_at
}
    `;
export const AttendListItemFragmentDoc = gql`
    fragment AttendListItem on Signup {
  id
  event {
    id
    name
    start
    categories {
      id
      name
      color
    }
  }
}
    `;
export const FutureSignupListItemFragmentDoc = gql`
    fragment FutureSignupListItem on Signup {
  id
  is_candidate
  is_invited
  has_accepted
  has_rejected
  emails {
    id
    status
    opened_first_at
  }
  event {
    id
    name
    status
    candidates_chosen
    start
    categories {
      id
      name
      color
    }
  }
}
    `;
export const CompanionMatchListItemFragmentDoc = gql`
    fragment CompanionMatchListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  has_accepted
  has_rejected
  notes
  priority
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      email
      phone
      mobile
      lat
      lng
    }
  }
}
    `;
export const CompanionMatchingTabFragmentDoc = gql`
    fragment CompanionMatchingTab on Event {
  id
  signups {
    ...CompanionMatchListItem
  }
  location {
    id
    street
    postal_code
    city
    lat
    lng
  }
}
    ${CompanionMatchListItemFragmentDoc}`;
export const ProspectGroupListItemFragmentDoc = gql`
    fragment ProspectGroupListItem on Signup {
  id
  notes
  priority
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
    }
  }
  group {
    id
    notes
    priority
    soul {
      id
      notes
      buys_ticket
      needs_blind
      needs_wheelchair
      needs_wheeled_walker
      needs_drive
      needs_trainstation_fetch
      tags {
        id
        name
        icon
        intent
      }
      user {
        id
        display_name
      }
    }
  }
}
    `;
export const ProspectListItemFragmentDoc = gql`
    fragment ProspectListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  has_accepted
  has_rejected
  notes
  priority
  emails {
    id
    status
    opened_first_at
  }
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      email
      phone
      mobile
      lat
      lng
    }
  }
  soul {
    id
    notes
    buys_ticket
    needs_blind
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      email
      phone
      mobile
      lat
      lng
    }
  }
}
    `;
export const ProspectListFragmentDoc = gql`
    fragment ProspectList on Signup {
  ...ProspectListItem
  group {
    ...ProspectListItem
  }
  leader {
    ...ProspectListItem
  }
}
    ${ProspectListItemFragmentDoc}`;
export const CandidateListItemFragmentDoc = gql`
    fragment CandidateListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  has_accepted
  has_rejected
  notes
  priority
  emails {
    id
    status
    opened_first_at
  }
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      email
      phone
      mobile
      lat
      lng
    }
  }
  soul {
    id
    notes
    buys_ticket
    needs_blind
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      email
      phone
      mobile
      lat
      lng
    }
  }
}
    `;
export const CandidateListFragmentDoc = gql`
    fragment CandidateList on Signup {
  ...CandidateListItem
  group {
    ...CandidateListItem
  }
  leader {
    ...CandidateListItem
  }
}
    ${CandidateListItemFragmentDoc}`;
export const MatchingPageFragmentDoc = gql`
    fragment MatchingPage on Event {
  id
  name
  status
  start
  end
  taken_tickets
  available_tickets
  candidates_chosen
  cardholder {
    id
    user {
      id
      display_name
      mobile
      phone
      email
    }
  }
  categories {
    id
    name
    color
  }
  signups {
    ...CandidateList
  }
  location {
    id
    name
    accessible
    meeting_point
    public_transport
    street
    postal_code
    city
    lat
    lng
  }
}
    ${CandidateListFragmentDoc}`;
export const OrganisationCreateFormFragmentDoc = gql`
    fragment OrganisationCreateForm on Organisation {
  name
  description
  souls {
    id
  }
  companions {
    id
  }
}
    `;
export const OrganisationEditFormFragmentDoc = gql`
    fragment OrganisationEditForm on Organisation {
  id
  name
  description
  souls {
    id
    user {
      id
      display_name
    }
  }
  companions {
    id
    user {
      id
      display_name
    }
  }
  deleted_at
}
    `;
export const OrganisationListItemFragmentDoc = gql`
    fragment OrganisationListItem on Organisation {
  id
  name
  deleted_at
}
    `;
export const OrganisationSoulListItemFragmentDoc = gql`
    fragment OrganisationSoulListItem on Soul {
  id
  buys_ticket
  needs_wheelchair
  needs_wheeled_walker
  needs_drive
  needs_trainstation_fetch
  needs_blind
  user {
    id
    display_name
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const OrganisationCompanionListItemFragmentDoc = gql`
    fragment OrganisationCompanionListItem on Companion {
  id
  can_wheelchair
  can_wheeled_walker
  can_drive
  can_blind
  user {
    id
    display_name
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const OrganisationDetailPageFragmentDoc = gql`
    fragment OrganisationDetailPage on Organisation {
  id
  name
  description
  souls {
    ...OrganisationSoulListItem
  }
  companions {
    ...OrganisationCompanionListItem
  }
  deleted_at
}
    ${OrganisationSoulListItemFragmentDoc}
${OrganisationCompanionListItemFragmentDoc}`;
export const SoulCreateFormFragmentDoc = gql`
    fragment SoulCreateForm on Soul {
  target_group
  notes
  buys_ticket
  needs_wheelchair
  needs_wheeled_walker
  needs_drive
  needs_trainstation_fetch
  needs_blind
  user {
    first_name
    last_name
    status
    gender
    email
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const SoulEditFormFragmentDoc = gql`
    fragment SoulEditForm on Soul {
  id
  target_group
  notes
  buys_ticket
  needs_wheelchair
  needs_wheeled_walker
  needs_drive
  needs_trainstation_fetch
  needs_blind
  user {
    id
    first_name
    last_name
    gender
    email
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const SoulListItemFragmentDoc = gql`
    fragment SoulListItem on Soul {
  id
  buys_ticket
  needs_wheelchair
  needs_wheeled_walker
  needs_drive
  needs_trainstation_fetch
  needs_blind
  user {
    id
    status
    display_name
    email
    phone
    mobile
  }
  organisation {
    id
    name
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const SoulDetailPageFragmentDoc = gql`
    fragment SoulDetailPage on Soul {
  id
  target_group
  notes
  buys_ticket
  needs_wheelchair
  needs_wheeled_walker
  needs_drive
  needs_trainstation_fetch
  needs_blind
  deleted_at
  user {
    id
    status
    display_name
    first_name
    last_name
    gender
    email
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const AllotmentListDocument = gql`
    query AllotmentList {
  allotments {
    ...AllotmentListItem
  }
}
    ${AllotmentListItemFragmentDoc}`;

/**
 * __useAllotmentListQuery__
 *
 * To run a query within a React component, call `useAllotmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllotmentListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllotmentListQuery, AllotmentListQueryVariables>) {
        return ApolloReactHooks.useQuery<AllotmentListQuery, AllotmentListQueryVariables>(AllotmentListDocument, baseOptions);
      }
export function useAllotmentListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllotmentListQuery, AllotmentListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllotmentListQuery, AllotmentListQueryVariables>(AllotmentListDocument, baseOptions);
        }
export type AllotmentListQueryHookResult = ReturnType<typeof useAllotmentListQuery>;
export type AllotmentListLazyQueryHookResult = ReturnType<typeof useAllotmentListLazyQuery>;
export type AllotmentListQueryResult = ApolloReactCommon.QueryResult<AllotmentListQuery, AllotmentListQueryVariables>;
export const AllotmentDetailDocument = gql`
    query AllotmentDetail($id: ID!) {
  allotment(id: $id) {
    ...AllotmentDetailPage
  }
}
    ${AllotmentDetailPageFragmentDoc}`;

/**
 * __useAllotmentDetailQuery__
 *
 * To run a query within a React component, call `useAllotmentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllotmentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllotmentDetailQuery, AllotmentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<AllotmentDetailQuery, AllotmentDetailQueryVariables>(AllotmentDetailDocument, baseOptions);
      }
export function useAllotmentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllotmentDetailQuery, AllotmentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllotmentDetailQuery, AllotmentDetailQueryVariables>(AllotmentDetailDocument, baseOptions);
        }
export type AllotmentDetailQueryHookResult = ReturnType<typeof useAllotmentDetailQuery>;
export type AllotmentDetailLazyQueryHookResult = ReturnType<typeof useAllotmentDetailLazyQuery>;
export type AllotmentDetailQueryResult = ApolloReactCommon.QueryResult<AllotmentDetailQuery, AllotmentDetailQueryVariables>;
export const AllotmentEditDocument = gql`
    query AllotmentEdit($id: ID!) {
  allotment(id: $id) {
    ...AllotmentEditForm
  }
}
    ${AllotmentEditFormFragmentDoc}`;

/**
 * __useAllotmentEditQuery__
 *
 * To run a query within a React component, call `useAllotmentEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllotmentEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllotmentEditQuery, AllotmentEditQueryVariables>) {
        return ApolloReactHooks.useQuery<AllotmentEditQuery, AllotmentEditQueryVariables>(AllotmentEditDocument, baseOptions);
      }
export function useAllotmentEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllotmentEditQuery, AllotmentEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllotmentEditQuery, AllotmentEditQueryVariables>(AllotmentEditDocument, baseOptions);
        }
export type AllotmentEditQueryHookResult = ReturnType<typeof useAllotmentEditQuery>;
export type AllotmentEditLazyQueryHookResult = ReturnType<typeof useAllotmentEditLazyQuery>;
export type AllotmentEditQueryResult = ApolloReactCommon.QueryResult<AllotmentEditQuery, AllotmentEditQueryVariables>;
export const CreateAllotmentDocument = gql`
    mutation CreateAllotment($input: CreateAllotmentInput!) {
  createAllotment(input: $input) {
    ...AllotmentDetailPage
  }
}
    ${AllotmentDetailPageFragmentDoc}`;
export type CreateAllotmentMutationFn = ApolloReactCommon.MutationFunction<CreateAllotmentMutation, CreateAllotmentMutationVariables>;

/**
 * __useCreateAllotmentMutation__
 *
 * To run a mutation, you first call `useCreateAllotmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAllotmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAllotmentMutation, { data, loading, error }] = useCreateAllotmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAllotmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAllotmentMutation, CreateAllotmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAllotmentMutation, CreateAllotmentMutationVariables>(CreateAllotmentDocument, baseOptions);
      }
export type CreateAllotmentMutationHookResult = ReturnType<typeof useCreateAllotmentMutation>;
export type CreateAllotmentMutationResult = ApolloReactCommon.MutationResult<CreateAllotmentMutation>;
export type CreateAllotmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAllotmentMutation, CreateAllotmentMutationVariables>;
export const UpdateAllotmentDocument = gql`
    mutation UpdateAllotment($input: UpdateAllotmentInput!) {
  updateAllotment(input: $input) {
    ...AllotmentDetailPage
  }
}
    ${AllotmentDetailPageFragmentDoc}`;
export type UpdateAllotmentMutationFn = ApolloReactCommon.MutationFunction<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>;

/**
 * __useUpdateAllotmentMutation__
 *
 * To run a mutation, you first call `useUpdateAllotmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllotmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllotmentMutation, { data, loading, error }] = useUpdateAllotmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAllotmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>(UpdateAllotmentDocument, baseOptions);
      }
export type UpdateAllotmentMutationHookResult = ReturnType<typeof useUpdateAllotmentMutation>;
export type UpdateAllotmentMutationResult = ApolloReactCommon.MutationResult<UpdateAllotmentMutation>;
export type UpdateAllotmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>;
export const DeleteAllotmentDocument = gql`
    mutation DeleteAllotment($id: ID!) {
  deleteAllotment(id: $id) {
    id
  }
}
    `;
export type DeleteAllotmentMutationFn = ApolloReactCommon.MutationFunction<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>;

/**
 * __useDeleteAllotmentMutation__
 *
 * To run a mutation, you first call `useDeleteAllotmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllotmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllotmentMutation, { data, loading, error }] = useDeleteAllotmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAllotmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>(DeleteAllotmentDocument, baseOptions);
      }
export type DeleteAllotmentMutationHookResult = ReturnType<typeof useDeleteAllotmentMutation>;
export type DeleteAllotmentMutationResult = ApolloReactCommon.MutationResult<DeleteAllotmentMutation>;
export type DeleteAllotmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>;
export const IsUserAuthenticatedDocument = gql`
    query IsUserAuthenticated {
  isAuthenticated @client
}
    `;

/**
 * __useIsUserAuthenticatedQuery__
 *
 * To run a query within a React component, call `useIsUserAuthenticatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserAuthenticatedQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserAuthenticatedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUserAuthenticatedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>) {
        return ApolloReactHooks.useQuery<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>(IsUserAuthenticatedDocument, baseOptions);
      }
export function useIsUserAuthenticatedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>(IsUserAuthenticatedDocument, baseOptions);
        }
export type IsUserAuthenticatedQueryHookResult = ReturnType<typeof useIsUserAuthenticatedQuery>;
export type IsUserAuthenticatedLazyQueryHookResult = ReturnType<typeof useIsUserAuthenticatedLazyQuery>;
export type IsUserAuthenticatedQueryResult = ApolloReactCommon.QueryResult<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    access_token
    user {
      id
      branch_id
      email
      display_name
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CategoryListDocument = gql`
    query CategoryList {
  categories {
    ...CategoryListItem
  }
}
    ${CategoryListItemFragmentDoc}`;

/**
 * __useCategoryListQuery__
 *
 * To run a query within a React component, call `useCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoryListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, baseOptions);
      }
export function useCategoryListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, baseOptions);
        }
export type CategoryListQueryHookResult = ReturnType<typeof useCategoryListQuery>;
export type CategoryListLazyQueryHookResult = ReturnType<typeof useCategoryListLazyQuery>;
export type CategoryListQueryResult = ApolloReactCommon.QueryResult<CategoryListQuery, CategoryListQueryVariables>;
export const CategoryDetailDocument = gql`
    query CategoryDetail($id: ID!) {
  category(id: $id, trashed: WITH) {
    ...CategoryDetailPage
  }
}
    ${CategoryDetailPageFragmentDoc}`;

/**
 * __useCategoryDetailQuery__
 *
 * To run a query within a React component, call `useCategoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoryDetailQuery, CategoryDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryDetailQuery, CategoryDetailQueryVariables>(CategoryDetailDocument, baseOptions);
      }
export function useCategoryDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryDetailQuery, CategoryDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryDetailQuery, CategoryDetailQueryVariables>(CategoryDetailDocument, baseOptions);
        }
export type CategoryDetailQueryHookResult = ReturnType<typeof useCategoryDetailQuery>;
export type CategoryDetailLazyQueryHookResult = ReturnType<typeof useCategoryDetailLazyQuery>;
export type CategoryDetailQueryResult = ApolloReactCommon.QueryResult<CategoryDetailQuery, CategoryDetailQueryVariables>;
export const CategoryEditDocument = gql`
    query CategoryEdit($id: ID!) {
  category(id: $id, trashed: WITH) {
    ...CategoryEditForm
  }
}
    ${CategoryEditFormFragmentDoc}`;

/**
 * __useCategoryEditQuery__
 *
 * To run a query within a React component, call `useCategoryEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoryEditQuery, CategoryEditQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryEditQuery, CategoryEditQueryVariables>(CategoryEditDocument, baseOptions);
      }
export function useCategoryEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryEditQuery, CategoryEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryEditQuery, CategoryEditQueryVariables>(CategoryEditDocument, baseOptions);
        }
export type CategoryEditQueryHookResult = ReturnType<typeof useCategoryEditQuery>;
export type CategoryEditLazyQueryHookResult = ReturnType<typeof useCategoryEditLazyQuery>;
export type CategoryEditQueryResult = ApolloReactCommon.QueryResult<CategoryEditQuery, CategoryEditQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(input: $input) {
    ...CategoryDetailPage
  }
}
    ${CategoryDetailPageFragmentDoc}`;
export type CreateCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, baseOptions);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = ApolloReactCommon.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($input: UpdateCategoryInput!) {
  updateCategory(input: $input) {
    ...CategoryDetailPage
  }
}
    ${CategoryDetailPageFragmentDoc}`;
export type UpdateCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, baseOptions);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($id: ID!) {
  deleteCategory(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, baseOptions);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const RestoreCategoryDocument = gql`
    mutation RestoreCategory($id: ID!) {
  restoreCategory(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreCategoryMutationFn = ApolloReactCommon.MutationFunction<RestoreCategoryMutation, RestoreCategoryMutationVariables>;

/**
 * __useRestoreCategoryMutation__
 *
 * To run a mutation, you first call `useRestoreCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCategoryMutation, { data, loading, error }] = useRestoreCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreCategoryMutation, RestoreCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreCategoryMutation, RestoreCategoryMutationVariables>(RestoreCategoryDocument, baseOptions);
      }
export type RestoreCategoryMutationHookResult = ReturnType<typeof useRestoreCategoryMutation>;
export type RestoreCategoryMutationResult = ApolloReactCommon.MutationResult<RestoreCategoryMutation>;
export type RestoreCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreCategoryMutation, RestoreCategoryMutationVariables>;
export const ForceDeleteCategoryDocument = gql`
    mutation ForceDeleteCategory($id: ID!) {
  forceDeleteCategory(id: $id) {
    id
  }
}
    `;
export type ForceDeleteCategoryMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteCategoryMutation, ForceDeleteCategoryMutationVariables>;

/**
 * __useForceDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useForceDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteCategoryMutation, { data, loading, error }] = useForceDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteCategoryMutation, ForceDeleteCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteCategoryMutation, ForceDeleteCategoryMutationVariables>(ForceDeleteCategoryDocument, baseOptions);
      }
export type ForceDeleteCategoryMutationHookResult = ReturnType<typeof useForceDeleteCategoryMutation>;
export type ForceDeleteCategoryMutationResult = ApolloReactCommon.MutationResult<ForceDeleteCategoryMutation>;
export type ForceDeleteCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteCategoryMutation, ForceDeleteCategoryMutationVariables>;
export const CompanionListDocument = gql`
    query CompanionList($status: UserStatus!, $cursor: String) {
  companions(status: $status, first: 20, after: $cursor) {
    edges {
      node {
        ...CompanionListItem
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${CompanionListItemFragmentDoc}`;

/**
 * __useCompanionListQuery__
 *
 * To run a query within a React component, call `useCompanionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCompanionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionListQuery, CompanionListQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionListQuery, CompanionListQueryVariables>(CompanionListDocument, baseOptions);
      }
export function useCompanionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionListQuery, CompanionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionListQuery, CompanionListQueryVariables>(CompanionListDocument, baseOptions);
        }
export type CompanionListQueryHookResult = ReturnType<typeof useCompanionListQuery>;
export type CompanionListLazyQueryHookResult = ReturnType<typeof useCompanionListLazyQuery>;
export type CompanionListQueryResult = ApolloReactCommon.QueryResult<CompanionListQuery, CompanionListQueryVariables>;
export const CompanionDetailDocument = gql`
    query CompanionDetail($id: ID!) {
  companion(id: $id, trashed: WITH) {
    ...CompanionDetailPage
  }
}
    ${CompanionDetailPageFragmentDoc}`;

/**
 * __useCompanionDetailQuery__
 *
 * To run a query within a React component, call `useCompanionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanionDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionDetailQuery, CompanionDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionDetailQuery, CompanionDetailQueryVariables>(CompanionDetailDocument, baseOptions);
      }
export function useCompanionDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionDetailQuery, CompanionDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionDetailQuery, CompanionDetailQueryVariables>(CompanionDetailDocument, baseOptions);
        }
export type CompanionDetailQueryHookResult = ReturnType<typeof useCompanionDetailQuery>;
export type CompanionDetailLazyQueryHookResult = ReturnType<typeof useCompanionDetailLazyQuery>;
export type CompanionDetailQueryResult = ApolloReactCommon.QueryResult<CompanionDetailQuery, CompanionDetailQueryVariables>;
export const CompanionEditDocument = gql`
    query CompanionEdit($id: ID!) {
  companion(id: $id, trashed: WITH) {
    ...CompanionEditForm
  }
}
    ${CompanionEditFormFragmentDoc}`;

/**
 * __useCompanionEditQuery__
 *
 * To run a query within a React component, call `useCompanionEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanionEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionEditQuery, CompanionEditQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionEditQuery, CompanionEditQueryVariables>(CompanionEditDocument, baseOptions);
      }
export function useCompanionEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionEditQuery, CompanionEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionEditQuery, CompanionEditQueryVariables>(CompanionEditDocument, baseOptions);
        }
export type CompanionEditQueryHookResult = ReturnType<typeof useCompanionEditQuery>;
export type CompanionEditLazyQueryHookResult = ReturnType<typeof useCompanionEditLazyQuery>;
export type CompanionEditQueryResult = ApolloReactCommon.QueryResult<CompanionEditQuery, CompanionEditQueryVariables>;
export const CreateCompanionDocument = gql`
    mutation CreateCompanion($input: CreateCompanionInput!) {
  createCompanion(input: $input) {
    ...CompanionDetailPage
  }
}
    ${CompanionDetailPageFragmentDoc}`;
export type CreateCompanionMutationFn = ApolloReactCommon.MutationFunction<CreateCompanionMutation, CreateCompanionMutationVariables>;

/**
 * __useCreateCompanionMutation__
 *
 * To run a mutation, you first call `useCreateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanionMutation, { data, loading, error }] = useCreateCompanionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanionMutation, CreateCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanionMutation, CreateCompanionMutationVariables>(CreateCompanionDocument, baseOptions);
      }
export type CreateCompanionMutationHookResult = ReturnType<typeof useCreateCompanionMutation>;
export type CreateCompanionMutationResult = ApolloReactCommon.MutationResult<CreateCompanionMutation>;
export type CreateCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanionMutation, CreateCompanionMutationVariables>;
export const UpdateCompanionDocument = gql`
    mutation UpdateCompanion($input: UpdateCompanionInput!) {
  updateCompanion(input: $input) {
    ...CompanionDetailPage
  }
}
    ${CompanionDetailPageFragmentDoc}`;
export type UpdateCompanionMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanionMutation, UpdateCompanionMutationVariables>;

/**
 * __useUpdateCompanionMutation__
 *
 * To run a mutation, you first call `useUpdateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanionMutation, { data, loading, error }] = useUpdateCompanionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanionMutation, UpdateCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanionMutation, UpdateCompanionMutationVariables>(UpdateCompanionDocument, baseOptions);
      }
export type UpdateCompanionMutationHookResult = ReturnType<typeof useUpdateCompanionMutation>;
export type UpdateCompanionMutationResult = ApolloReactCommon.MutationResult<UpdateCompanionMutation>;
export type UpdateCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanionMutation, UpdateCompanionMutationVariables>;
export const ApproveCompanionDocument = gql`
    mutation ApproveCompanion($id: ID!) {
  approveCompanion(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type ApproveCompanionMutationFn = ApolloReactCommon.MutationFunction<ApproveCompanionMutation, ApproveCompanionMutationVariables>;

/**
 * __useApproveCompanionMutation__
 *
 * To run a mutation, you first call `useApproveCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCompanionMutation, { data, loading, error }] = useApproveCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveCompanionMutation, ApproveCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveCompanionMutation, ApproveCompanionMutationVariables>(ApproveCompanionDocument, baseOptions);
      }
export type ApproveCompanionMutationHookResult = ReturnType<typeof useApproveCompanionMutation>;
export type ApproveCompanionMutationResult = ApolloReactCommon.MutationResult<ApproveCompanionMutation>;
export type ApproveCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveCompanionMutation, ApproveCompanionMutationVariables>;
export const DenyCompanionDocument = gql`
    mutation DenyCompanion($id: ID!) {
  denyCompanion(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type DenyCompanionMutationFn = ApolloReactCommon.MutationFunction<DenyCompanionMutation, DenyCompanionMutationVariables>;

/**
 * __useDenyCompanionMutation__
 *
 * To run a mutation, you first call `useDenyCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenyCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denyCompanionMutation, { data, loading, error }] = useDenyCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDenyCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DenyCompanionMutation, DenyCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<DenyCompanionMutation, DenyCompanionMutationVariables>(DenyCompanionDocument, baseOptions);
      }
export type DenyCompanionMutationHookResult = ReturnType<typeof useDenyCompanionMutation>;
export type DenyCompanionMutationResult = ApolloReactCommon.MutationResult<DenyCompanionMutation>;
export type DenyCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<DenyCompanionMutation, DenyCompanionMutationVariables>;
export const ActivateCompanionDocument = gql`
    mutation ActivateCompanion($id: ID!) {
  activateCompanion(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type ActivateCompanionMutationFn = ApolloReactCommon.MutationFunction<ActivateCompanionMutation, ActivateCompanionMutationVariables>;

/**
 * __useActivateCompanionMutation__
 *
 * To run a mutation, you first call `useActivateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompanionMutation, { data, loading, error }] = useActivateCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateCompanionMutation, ActivateCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateCompanionMutation, ActivateCompanionMutationVariables>(ActivateCompanionDocument, baseOptions);
      }
export type ActivateCompanionMutationHookResult = ReturnType<typeof useActivateCompanionMutation>;
export type ActivateCompanionMutationResult = ApolloReactCommon.MutationResult<ActivateCompanionMutation>;
export type ActivateCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateCompanionMutation, ActivateCompanionMutationVariables>;
export const DeactivateCompanionDocument = gql`
    mutation DeactivateCompanion($id: ID!) {
  deactivateCompanion(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type DeactivateCompanionMutationFn = ApolloReactCommon.MutationFunction<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>;

/**
 * __useDeactivateCompanionMutation__
 *
 * To run a mutation, you first call `useDeactivateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateCompanionMutation, { data, loading, error }] = useDeactivateCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>(DeactivateCompanionDocument, baseOptions);
      }
export type DeactivateCompanionMutationHookResult = ReturnType<typeof useDeactivateCompanionMutation>;
export type DeactivateCompanionMutationResult = ApolloReactCommon.MutationResult<DeactivateCompanionMutation>;
export type DeactivateCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>;
export const DeleteCompanionDocument = gql`
    mutation DeleteCompanion($id: ID!) {
  deleteCompanion(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteCompanionMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanionMutation, DeleteCompanionMutationVariables>;

/**
 * __useDeleteCompanionMutation__
 *
 * To run a mutation, you first call `useDeleteCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanionMutation, { data, loading, error }] = useDeleteCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanionMutation, DeleteCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanionMutation, DeleteCompanionMutationVariables>(DeleteCompanionDocument, baseOptions);
      }
export type DeleteCompanionMutationHookResult = ReturnType<typeof useDeleteCompanionMutation>;
export type DeleteCompanionMutationResult = ApolloReactCommon.MutationResult<DeleteCompanionMutation>;
export type DeleteCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanionMutation, DeleteCompanionMutationVariables>;
export const RestoreCompanionDocument = gql`
    mutation RestoreCompanion($id: ID!) {
  restoreCompanion(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreCompanionMutationFn = ApolloReactCommon.MutationFunction<RestoreCompanionMutation, RestoreCompanionMutationVariables>;

/**
 * __useRestoreCompanionMutation__
 *
 * To run a mutation, you first call `useRestoreCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCompanionMutation, { data, loading, error }] = useRestoreCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreCompanionMutation, RestoreCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreCompanionMutation, RestoreCompanionMutationVariables>(RestoreCompanionDocument, baseOptions);
      }
export type RestoreCompanionMutationHookResult = ReturnType<typeof useRestoreCompanionMutation>;
export type RestoreCompanionMutationResult = ApolloReactCommon.MutationResult<RestoreCompanionMutation>;
export type RestoreCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreCompanionMutation, RestoreCompanionMutationVariables>;
export const ForceDeleteCompanionDocument = gql`
    mutation ForceDeleteCompanion($id: ID!) {
  forceDeleteCompanion(id: $id) {
    id
  }
}
    `;
export type ForceDeleteCompanionMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteCompanionMutation, ForceDeleteCompanionMutationVariables>;

/**
 * __useForceDeleteCompanionMutation__
 *
 * To run a mutation, you first call `useForceDeleteCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteCompanionMutation, { data, loading, error }] = useForceDeleteCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteCompanionMutation, ForceDeleteCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteCompanionMutation, ForceDeleteCompanionMutationVariables>(ForceDeleteCompanionDocument, baseOptions);
      }
export type ForceDeleteCompanionMutationHookResult = ReturnType<typeof useForceDeleteCompanionMutation>;
export type ForceDeleteCompanionMutationResult = ApolloReactCommon.MutationResult<ForceDeleteCompanionMutation>;
export type ForceDeleteCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteCompanionMutation, ForceDeleteCompanionMutationVariables>;
export const ContactDetailDocument = gql`
    query ContactDetail($id: ID!) {
  contact(id: $id, trashed: WITH) {
    ...ContactDetailPage
  }
}
    ${ContactDetailPageFragmentDoc}`;

/**
 * __useContactDetailQuery__
 *
 * To run a query within a React component, call `useContactDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactDetailQuery, ContactDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<ContactDetailQuery, ContactDetailQueryVariables>(ContactDetailDocument, baseOptions);
      }
export function useContactDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactDetailQuery, ContactDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContactDetailQuery, ContactDetailQueryVariables>(ContactDetailDocument, baseOptions);
        }
export type ContactDetailQueryHookResult = ReturnType<typeof useContactDetailQuery>;
export type ContactDetailLazyQueryHookResult = ReturnType<typeof useContactDetailLazyQuery>;
export type ContactDetailQueryResult = ApolloReactCommon.QueryResult<ContactDetailQuery, ContactDetailQueryVariables>;
export const ContactEditDocument = gql`
    query ContactEdit($id: ID!) {
  contact(id: $id, trashed: WITH) {
    ...ContactEditForm
  }
}
    ${ContactEditFormFragmentDoc}`;

/**
 * __useContactEditQuery__
 *
 * To run a query within a React component, call `useContactEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactEditQuery, ContactEditQueryVariables>) {
        return ApolloReactHooks.useQuery<ContactEditQuery, ContactEditQueryVariables>(ContactEditDocument, baseOptions);
      }
export function useContactEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactEditQuery, ContactEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContactEditQuery, ContactEditQueryVariables>(ContactEditDocument, baseOptions);
        }
export type ContactEditQueryHookResult = ReturnType<typeof useContactEditQuery>;
export type ContactEditLazyQueryHookResult = ReturnType<typeof useContactEditLazyQuery>;
export type ContactEditQueryResult = ApolloReactCommon.QueryResult<ContactEditQuery, ContactEditQueryVariables>;
export const CreateContactDocument = gql`
    mutation CreateContact($input: CreateContactInput!) {
  createContact(input: $input) {
    ...ContactDetailPage
  }
}
    ${ContactDetailPageFragmentDoc}`;
export type CreateContactMutationFn = ApolloReactCommon.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, baseOptions);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = ApolloReactCommon.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    ...ContactDetailPage
  }
}
    ${ContactDetailPageFragmentDoc}`;
export type UpdateContactMutationFn = ApolloReactCommon.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, baseOptions);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = ApolloReactCommon.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: ID!) {
  deleteContact(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteContactMutationFn = ApolloReactCommon.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, baseOptions);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = ApolloReactCommon.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const RestoreContactDocument = gql`
    mutation RestoreContact($id: ID!) {
  restoreContact(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreContactMutationFn = ApolloReactCommon.MutationFunction<RestoreContactMutation, RestoreContactMutationVariables>;

/**
 * __useRestoreContactMutation__
 *
 * To run a mutation, you first call `useRestoreContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreContactMutation, { data, loading, error }] = useRestoreContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreContactMutation, RestoreContactMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreContactMutation, RestoreContactMutationVariables>(RestoreContactDocument, baseOptions);
      }
export type RestoreContactMutationHookResult = ReturnType<typeof useRestoreContactMutation>;
export type RestoreContactMutationResult = ApolloReactCommon.MutationResult<RestoreContactMutation>;
export type RestoreContactMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreContactMutation, RestoreContactMutationVariables>;
export const ForceDeleteContactDocument = gql`
    mutation ForceDeleteContact($id: ID!) {
  forceDeleteContact(id: $id) {
    id
  }
}
    `;
export type ForceDeleteContactMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteContactMutation, ForceDeleteContactMutationVariables>;

/**
 * __useForceDeleteContactMutation__
 *
 * To run a mutation, you first call `useForceDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteContactMutation, { data, loading, error }] = useForceDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteContactMutation, ForceDeleteContactMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteContactMutation, ForceDeleteContactMutationVariables>(ForceDeleteContactDocument, baseOptions);
      }
export type ForceDeleteContactMutationHookResult = ReturnType<typeof useForceDeleteContactMutation>;
export type ForceDeleteContactMutationResult = ApolloReactCommon.MutationResult<ForceDeleteContactMutation>;
export type ForceDeleteContactMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteContactMutation, ForceDeleteContactMutationVariables>;
export const DonorListDocument = gql`
    query DonorList($cursor: String) {
  donors(first: 20, after: $cursor) {
    edges {
      node {
        ...DonorListItem
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${DonorListItemFragmentDoc}`;

/**
 * __useDonorListQuery__
 *
 * To run a query within a React component, call `useDonorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useDonorListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorListQuery, DonorListQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorListQuery, DonorListQueryVariables>(DonorListDocument, baseOptions);
      }
export function useDonorListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorListQuery, DonorListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorListQuery, DonorListQueryVariables>(DonorListDocument, baseOptions);
        }
export type DonorListQueryHookResult = ReturnType<typeof useDonorListQuery>;
export type DonorListLazyQueryHookResult = ReturnType<typeof useDonorListLazyQuery>;
export type DonorListQueryResult = ApolloReactCommon.QueryResult<DonorListQuery, DonorListQueryVariables>;
export const DonorDetailDocument = gql`
    query DonorDetail($id: ID!) {
  donor(id: $id, trashed: WITH) {
    ...DonorDetailPage
  }
}
    ${DonorDetailPageFragmentDoc}`;

/**
 * __useDonorDetailQuery__
 *
 * To run a query within a React component, call `useDonorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorDetailQuery, DonorDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorDetailQuery, DonorDetailQueryVariables>(DonorDetailDocument, baseOptions);
      }
export function useDonorDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorDetailQuery, DonorDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorDetailQuery, DonorDetailQueryVariables>(DonorDetailDocument, baseOptions);
        }
export type DonorDetailQueryHookResult = ReturnType<typeof useDonorDetailQuery>;
export type DonorDetailLazyQueryHookResult = ReturnType<typeof useDonorDetailLazyQuery>;
export type DonorDetailQueryResult = ApolloReactCommon.QueryResult<DonorDetailQuery, DonorDetailQueryVariables>;
export const DonorContactsDocument = gql`
    query DonorContacts($id: ID!) {
  donor(id: $id, trashed: WITH) {
    id
    contacts {
      ...ContactSelect
    }
  }
}
    ${ContactSelectFragmentDoc}`;

/**
 * __useDonorContactsQuery__
 *
 * To run a query within a React component, call `useDonorContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorContactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorContactsQuery, DonorContactsQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorContactsQuery, DonorContactsQueryVariables>(DonorContactsDocument, baseOptions);
      }
export function useDonorContactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorContactsQuery, DonorContactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorContactsQuery, DonorContactsQueryVariables>(DonorContactsDocument, baseOptions);
        }
export type DonorContactsQueryHookResult = ReturnType<typeof useDonorContactsQuery>;
export type DonorContactsLazyQueryHookResult = ReturnType<typeof useDonorContactsLazyQuery>;
export type DonorContactsQueryResult = ApolloReactCommon.QueryResult<DonorContactsQuery, DonorContactsQueryVariables>;
export const DonorLocationsDocument = gql`
    query DonorLocations($id: ID!) {
  donor(id: $id, trashed: WITH) {
    id
    locations {
      ...LocationSelect
    }
  }
}
    ${LocationSelectFragmentDoc}`;

/**
 * __useDonorLocationsQuery__
 *
 * To run a query within a React component, call `useDonorLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorLocationsQuery, DonorLocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorLocationsQuery, DonorLocationsQueryVariables>(DonorLocationsDocument, baseOptions);
      }
export function useDonorLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorLocationsQuery, DonorLocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorLocationsQuery, DonorLocationsQueryVariables>(DonorLocationsDocument, baseOptions);
        }
export type DonorLocationsQueryHookResult = ReturnType<typeof useDonorLocationsQuery>;
export type DonorLocationsLazyQueryHookResult = ReturnType<typeof useDonorLocationsLazyQuery>;
export type DonorLocationsQueryResult = ApolloReactCommon.QueryResult<DonorLocationsQuery, DonorLocationsQueryVariables>;
export const CreateDonorDocument = gql`
    mutation CreateDonor($input: CreateDonorInput!) {
  createDonor(input: $input) {
    ...DonorDetailPage
  }
}
    ${DonorDetailPageFragmentDoc}`;
export type CreateDonorMutationFn = ApolloReactCommon.MutationFunction<CreateDonorMutation, CreateDonorMutationVariables>;

/**
 * __useCreateDonorMutation__
 *
 * To run a mutation, you first call `useCreateDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonorMutation, { data, loading, error }] = useCreateDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDonorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDonorMutation, CreateDonorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDonorMutation, CreateDonorMutationVariables>(CreateDonorDocument, baseOptions);
      }
export type CreateDonorMutationHookResult = ReturnType<typeof useCreateDonorMutation>;
export type CreateDonorMutationResult = ApolloReactCommon.MutationResult<CreateDonorMutation>;
export type CreateDonorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDonorMutation, CreateDonorMutationVariables>;
export const DonorEditDocument = gql`
    query DonorEdit($id: ID!) {
  donor(id: $id, trashed: WITH) {
    ...DonorEditForm
  }
}
    ${DonorEditFormFragmentDoc}`;

/**
 * __useDonorEditQuery__
 *
 * To run a query within a React component, call `useDonorEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorEditQuery, DonorEditQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorEditQuery, DonorEditQueryVariables>(DonorEditDocument, baseOptions);
      }
export function useDonorEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorEditQuery, DonorEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorEditQuery, DonorEditQueryVariables>(DonorEditDocument, baseOptions);
        }
export type DonorEditQueryHookResult = ReturnType<typeof useDonorEditQuery>;
export type DonorEditLazyQueryHookResult = ReturnType<typeof useDonorEditLazyQuery>;
export type DonorEditQueryResult = ApolloReactCommon.QueryResult<DonorEditQuery, DonorEditQueryVariables>;
export const UpdateDonorDocument = gql`
    mutation UpdateDonor($input: UpdateDonorInput!) {
  updateDonor(input: $input) {
    ...DonorDetailPage
  }
}
    ${DonorDetailPageFragmentDoc}`;
export type UpdateDonorMutationFn = ApolloReactCommon.MutationFunction<UpdateDonorMutation, UpdateDonorMutationVariables>;

/**
 * __useUpdateDonorMutation__
 *
 * To run a mutation, you first call `useUpdateDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDonorMutation, { data, loading, error }] = useUpdateDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDonorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDonorMutation, UpdateDonorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDonorMutation, UpdateDonorMutationVariables>(UpdateDonorDocument, baseOptions);
      }
export type UpdateDonorMutationHookResult = ReturnType<typeof useUpdateDonorMutation>;
export type UpdateDonorMutationResult = ApolloReactCommon.MutationResult<UpdateDonorMutation>;
export type UpdateDonorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDonorMutation, UpdateDonorMutationVariables>;
export const DeleteDonorDocument = gql`
    mutation DeleteDonor($id: ID!) {
  deleteDonor(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteDonorMutationFn = ApolloReactCommon.MutationFunction<DeleteDonorMutation, DeleteDonorMutationVariables>;

/**
 * __useDeleteDonorMutation__
 *
 * To run a mutation, you first call `useDeleteDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDonorMutation, { data, loading, error }] = useDeleteDonorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDonorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDonorMutation, DeleteDonorMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDonorMutation, DeleteDonorMutationVariables>(DeleteDonorDocument, baseOptions);
      }
export type DeleteDonorMutationHookResult = ReturnType<typeof useDeleteDonorMutation>;
export type DeleteDonorMutationResult = ApolloReactCommon.MutationResult<DeleteDonorMutation>;
export type DeleteDonorMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDonorMutation, DeleteDonorMutationVariables>;
export const RestoreDonorDocument = gql`
    mutation RestoreDonor($id: ID!) {
  restoreDonor(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreDonorMutationFn = ApolloReactCommon.MutationFunction<RestoreDonorMutation, RestoreDonorMutationVariables>;

/**
 * __useRestoreDonorMutation__
 *
 * To run a mutation, you first call `useRestoreDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDonorMutation, { data, loading, error }] = useRestoreDonorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreDonorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreDonorMutation, RestoreDonorMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreDonorMutation, RestoreDonorMutationVariables>(RestoreDonorDocument, baseOptions);
      }
export type RestoreDonorMutationHookResult = ReturnType<typeof useRestoreDonorMutation>;
export type RestoreDonorMutationResult = ApolloReactCommon.MutationResult<RestoreDonorMutation>;
export type RestoreDonorMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreDonorMutation, RestoreDonorMutationVariables>;
export const ForceDeleteDonorDocument = gql`
    mutation ForceDeleteDonor($id: ID!) {
  forceDeleteDonor(id: $id) {
    id
  }
}
    `;
export type ForceDeleteDonorMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteDonorMutation, ForceDeleteDonorMutationVariables>;

/**
 * __useForceDeleteDonorMutation__
 *
 * To run a mutation, you first call `useForceDeleteDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteDonorMutation, { data, loading, error }] = useForceDeleteDonorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteDonorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteDonorMutation, ForceDeleteDonorMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteDonorMutation, ForceDeleteDonorMutationVariables>(ForceDeleteDonorDocument, baseOptions);
      }
export type ForceDeleteDonorMutationHookResult = ReturnType<typeof useForceDeleteDonorMutation>;
export type ForceDeleteDonorMutationResult = ApolloReactCommon.MutationResult<ForceDeleteDonorMutation>;
export type ForceDeleteDonorMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteDonorMutation, ForceDeleteDonorMutationVariables>;
export const EmployeeListDocument = gql`
    query EmployeeList($status: UserStatus!, $cursor: String) {
  employees(status: $status, first: 20, after: $cursor) {
    edges {
      node {
        ...EmployeeListItem
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${EmployeeListItemFragmentDoc}`;

/**
 * __useEmployeeListQuery__
 *
 * To run a query within a React component, call `useEmployeeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useEmployeeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListQuery, EmployeeListQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListQuery, EmployeeListQueryVariables>(EmployeeListDocument, baseOptions);
      }
export function useEmployeeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListQuery, EmployeeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListQuery, EmployeeListQueryVariables>(EmployeeListDocument, baseOptions);
        }
export type EmployeeListQueryHookResult = ReturnType<typeof useEmployeeListQuery>;
export type EmployeeListLazyQueryHookResult = ReturnType<typeof useEmployeeListLazyQuery>;
export type EmployeeListQueryResult = ApolloReactCommon.QueryResult<EmployeeListQuery, EmployeeListQueryVariables>;
export const EmployeeDetailDocument = gql`
    query EmployeeDetail($id: ID!) {
  employee(id: $id, trashed: WITH) {
    ...EmployeeDetailPage
  }
}
    ${EmployeeDetailPageFragmentDoc}`;

/**
 * __useEmployeeDetailQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeDetailQuery, EmployeeDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeDetailQuery, EmployeeDetailQueryVariables>(EmployeeDetailDocument, baseOptions);
      }
export function useEmployeeDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeDetailQuery, EmployeeDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeDetailQuery, EmployeeDetailQueryVariables>(EmployeeDetailDocument, baseOptions);
        }
export type EmployeeDetailQueryHookResult = ReturnType<typeof useEmployeeDetailQuery>;
export type EmployeeDetailLazyQueryHookResult = ReturnType<typeof useEmployeeDetailLazyQuery>;
export type EmployeeDetailQueryResult = ApolloReactCommon.QueryResult<EmployeeDetailQuery, EmployeeDetailQueryVariables>;
export const EmployeeEditDocument = gql`
    query EmployeeEdit($id: ID!) {
  employee(id: $id, trashed: WITH) {
    ...EmployeeEditForm
  }
}
    ${EmployeeEditFormFragmentDoc}`;

/**
 * __useEmployeeEditQuery__
 *
 * To run a query within a React component, call `useEmployeeEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeEditQuery, EmployeeEditQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeEditQuery, EmployeeEditQueryVariables>(EmployeeEditDocument, baseOptions);
      }
export function useEmployeeEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeEditQuery, EmployeeEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeEditQuery, EmployeeEditQueryVariables>(EmployeeEditDocument, baseOptions);
        }
export type EmployeeEditQueryHookResult = ReturnType<typeof useEmployeeEditQuery>;
export type EmployeeEditLazyQueryHookResult = ReturnType<typeof useEmployeeEditLazyQuery>;
export type EmployeeEditQueryResult = ApolloReactCommon.QueryResult<EmployeeEditQuery, EmployeeEditQueryVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(input: $input) {
    ...EmployeeDetailPage
  }
}
    ${EmployeeDetailPageFragmentDoc}`;
export type UpdateEmployeeMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, baseOptions);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const DeleteEmployeeDocument = gql`
    mutation DeleteEmployee($id: ID!) {
  deleteEmployee(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteEmployeeMutationFn = ApolloReactCommon.MutationFunction<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;

/**
 * __useDeleteEmployeeMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeMutation, { data, loading, error }] = useDeleteEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>(DeleteEmployeeDocument, baseOptions);
      }
export type DeleteEmployeeMutationHookResult = ReturnType<typeof useDeleteEmployeeMutation>;
export type DeleteEmployeeMutationResult = ApolloReactCommon.MutationResult<DeleteEmployeeMutation>;
export type DeleteEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;
export const RestoreEmployeeDocument = gql`
    mutation RestoreEmployee($id: ID!) {
  restoreEmployee(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreEmployeeMutationFn = ApolloReactCommon.MutationFunction<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>;

/**
 * __useRestoreEmployeeMutation__
 *
 * To run a mutation, you first call `useRestoreEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreEmployeeMutation, { data, loading, error }] = useRestoreEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>(RestoreEmployeeDocument, baseOptions);
      }
export type RestoreEmployeeMutationHookResult = ReturnType<typeof useRestoreEmployeeMutation>;
export type RestoreEmployeeMutationResult = ApolloReactCommon.MutationResult<RestoreEmployeeMutation>;
export type RestoreEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>;
export const ForceDeleteEmployeeDocument = gql`
    mutation ForceDeleteEmployee($id: ID!) {
  forceDeleteEmployee(id: $id) {
    id
  }
}
    `;
export type ForceDeleteEmployeeMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteEmployeeMutation, ForceDeleteEmployeeMutationVariables>;

/**
 * __useForceDeleteEmployeeMutation__
 *
 * To run a mutation, you first call `useForceDeleteEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteEmployeeMutation, { data, loading, error }] = useForceDeleteEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteEmployeeMutation, ForceDeleteEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteEmployeeMutation, ForceDeleteEmployeeMutationVariables>(ForceDeleteEmployeeDocument, baseOptions);
      }
export type ForceDeleteEmployeeMutationHookResult = ReturnType<typeof useForceDeleteEmployeeMutation>;
export type ForceDeleteEmployeeMutationResult = ApolloReactCommon.MutationResult<ForceDeleteEmployeeMutation>;
export type ForceDeleteEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteEmployeeMutation, ForceDeleteEmployeeMutationVariables>;
export const EventListDocument = gql`
    query EventList($cursor: String, $status: EventStatus) {
  events(first: 20, after: $cursor, status: $status, orderBy: [{field: "start", order: DESC}]) {
    edges {
      node {
        ...EventListItem
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${EventListItemFragmentDoc}`;

/**
 * __useEventListQuery__
 *
 * To run a query within a React component, call `useEventListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEventListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventListQuery, EventListQueryVariables>) {
        return ApolloReactHooks.useQuery<EventListQuery, EventListQueryVariables>(EventListDocument, baseOptions);
      }
export function useEventListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventListQuery, EventListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventListQuery, EventListQueryVariables>(EventListDocument, baseOptions);
        }
export type EventListQueryHookResult = ReturnType<typeof useEventListQuery>;
export type EventListLazyQueryHookResult = ReturnType<typeof useEventListLazyQuery>;
export type EventListQueryResult = ApolloReactCommon.QueryResult<EventListQuery, EventListQueryVariables>;
export const EventDetailDocument = gql`
    query EventDetail($id: ID!) {
  event(id: $id) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;

/**
 * __useEventDetailQuery__
 *
 * To run a query within a React component, call `useEventDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventDetailQuery, EventDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<EventDetailQuery, EventDetailQueryVariables>(EventDetailDocument, baseOptions);
      }
export function useEventDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventDetailQuery, EventDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventDetailQuery, EventDetailQueryVariables>(EventDetailDocument, baseOptions);
        }
export type EventDetailQueryHookResult = ReturnType<typeof useEventDetailQuery>;
export type EventDetailLazyQueryHookResult = ReturnType<typeof useEventDetailLazyQuery>;
export type EventDetailQueryResult = ApolloReactCommon.QueryResult<EventDetailQuery, EventDetailQueryVariables>;
export const EventEditDocument = gql`
    query EventEdit($id: ID!) {
  event(id: $id) {
    ...EventEditForm
  }
}
    ${EventEditFormFragmentDoc}`;

/**
 * __useEventEditQuery__
 *
 * To run a query within a React component, call `useEventEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventEditQuery, EventEditQueryVariables>) {
        return ApolloReactHooks.useQuery<EventEditQuery, EventEditQueryVariables>(EventEditDocument, baseOptions);
      }
export function useEventEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventEditQuery, EventEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventEditQuery, EventEditQueryVariables>(EventEditDocument, baseOptions);
        }
export type EventEditQueryHookResult = ReturnType<typeof useEventEditQuery>;
export type EventEditLazyQueryHookResult = ReturnType<typeof useEventEditLazyQuery>;
export type EventEditQueryResult = ApolloReactCommon.QueryResult<EventEditQuery, EventEditQueryVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type UpdateEventMutationFn = ApolloReactCommon.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, baseOptions);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = ApolloReactCommon.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type CreateEventMutationFn = ApolloReactCommon.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, baseOptions);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = ApolloReactCommon.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($id: ID!) {
  deleteEvent(id: $id) {
    id
  }
}
    `;
export type DeleteEventMutationFn = ApolloReactCommon.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, baseOptions);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = ApolloReactCommon.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const DeleteSignupDocument = gql`
    mutation DeleteSignup($id: ID!) {
  deleteSignup(id: $id) {
    id
  }
}
    `;
export type DeleteSignupMutationFn = ApolloReactCommon.MutationFunction<DeleteSignupMutation, DeleteSignupMutationVariables>;

/**
 * __useDeleteSignupMutation__
 *
 * To run a mutation, you first call `useDeleteSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSignupMutation, { data, loading, error }] = useDeleteSignupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSignupMutation, DeleteSignupMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSignupMutation, DeleteSignupMutationVariables>(DeleteSignupDocument, baseOptions);
      }
export type DeleteSignupMutationHookResult = ReturnType<typeof useDeleteSignupMutation>;
export type DeleteSignupMutationResult = ApolloReactCommon.MutationResult<DeleteSignupMutation>;
export type DeleteSignupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSignupMutation, DeleteSignupMutationVariables>;
export const AcceptSignupsDocument = gql`
    mutation AcceptSignups($id: ID!, $token: String!) {
  acceptSignups(id: $id, token: $token) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type AcceptSignupsMutationFn = ApolloReactCommon.MutationFunction<AcceptSignupsMutation, AcceptSignupsMutationVariables>;

/**
 * __useAcceptSignupsMutation__
 *
 * To run a mutation, you first call `useAcceptSignupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSignupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSignupsMutation, { data, loading, error }] = useAcceptSignupsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAcceptSignupsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptSignupsMutation, AcceptSignupsMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptSignupsMutation, AcceptSignupsMutationVariables>(AcceptSignupsDocument, baseOptions);
      }
export type AcceptSignupsMutationHookResult = ReturnType<typeof useAcceptSignupsMutation>;
export type AcceptSignupsMutationResult = ApolloReactCommon.MutationResult<AcceptSignupsMutation>;
export type AcceptSignupsMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptSignupsMutation, AcceptSignupsMutationVariables>;
export const AddSignupsDocument = gql`
    mutation AddSignups($input: AddSignupsInput!) {
  addSignups(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type AddSignupsMutationFn = ApolloReactCommon.MutationFunction<AddSignupsMutation, AddSignupsMutationVariables>;

/**
 * __useAddSignupsMutation__
 *
 * To run a mutation, you first call `useAddSignupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSignupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSignupsMutation, { data, loading, error }] = useAddSignupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSignupsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSignupsMutation, AddSignupsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSignupsMutation, AddSignupsMutationVariables>(AddSignupsDocument, baseOptions);
      }
export type AddSignupsMutationHookResult = ReturnType<typeof useAddSignupsMutation>;
export type AddSignupsMutationResult = ApolloReactCommon.MutationResult<AddSignupsMutation>;
export type AddSignupsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSignupsMutation, AddSignupsMutationVariables>;
export const LocationListDocument = gql`
    query LocationList($cursor: String) {
  locations(first: 20, after: $cursor) {
    edges {
      node {
        ...LocationListItem
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${LocationListItemFragmentDoc}`;

/**
 * __useLocationListQuery__
 *
 * To run a query within a React component, call `useLocationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useLocationListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationListQuery, LocationListQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationListQuery, LocationListQueryVariables>(LocationListDocument, baseOptions);
      }
export function useLocationListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationListQuery, LocationListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationListQuery, LocationListQueryVariables>(LocationListDocument, baseOptions);
        }
export type LocationListQueryHookResult = ReturnType<typeof useLocationListQuery>;
export type LocationListLazyQueryHookResult = ReturnType<typeof useLocationListLazyQuery>;
export type LocationListQueryResult = ApolloReactCommon.QueryResult<LocationListQuery, LocationListQueryVariables>;
export const LocationDetailDocument = gql`
    query LocationDetail($id: ID!) {
  location(id: $id, trashed: WITH) {
    ...LocationDetailPage
  }
}
    ${LocationDetailPageFragmentDoc}`;

/**
 * __useLocationDetailQuery__
 *
 * To run a query within a React component, call `useLocationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationDetailQuery, LocationDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationDetailQuery, LocationDetailQueryVariables>(LocationDetailDocument, baseOptions);
      }
export function useLocationDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationDetailQuery, LocationDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationDetailQuery, LocationDetailQueryVariables>(LocationDetailDocument, baseOptions);
        }
export type LocationDetailQueryHookResult = ReturnType<typeof useLocationDetailQuery>;
export type LocationDetailLazyQueryHookResult = ReturnType<typeof useLocationDetailLazyQuery>;
export type LocationDetailQueryResult = ApolloReactCommon.QueryResult<LocationDetailQuery, LocationDetailQueryVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    ...LocationDetailPage
  }
}
    ${LocationDetailPageFragmentDoc}`;
export type CreateLocationMutationFn = ApolloReactCommon.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, baseOptions);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = ApolloReactCommon.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const LocationEditDocument = gql`
    query LocationEdit($id: ID!) {
  location(id: $id, trashed: WITH) {
    ...LocationEditForm
  }
}
    ${LocationEditFormFragmentDoc}`;

/**
 * __useLocationEditQuery__
 *
 * To run a query within a React component, call `useLocationEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationEditQuery, LocationEditQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationEditQuery, LocationEditQueryVariables>(LocationEditDocument, baseOptions);
      }
export function useLocationEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationEditQuery, LocationEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationEditQuery, LocationEditQueryVariables>(LocationEditDocument, baseOptions);
        }
export type LocationEditQueryHookResult = ReturnType<typeof useLocationEditQuery>;
export type LocationEditLazyQueryHookResult = ReturnType<typeof useLocationEditLazyQuery>;
export type LocationEditQueryResult = ApolloReactCommon.QueryResult<LocationEditQuery, LocationEditQueryVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($input: UpdateLocationInput!) {
  updateLocation(input: $input) {
    ...LocationDetailPage
  }
}
    ${LocationDetailPageFragmentDoc}`;
export type UpdateLocationMutationFn = ApolloReactCommon.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, baseOptions);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = ApolloReactCommon.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($id: ID!) {
  deleteLocation(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteLocationMutationFn = ApolloReactCommon.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, baseOptions);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = ApolloReactCommon.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const RestoreLocationDocument = gql`
    mutation RestoreLocation($id: ID!) {
  restoreLocation(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreLocationMutationFn = ApolloReactCommon.MutationFunction<RestoreLocationMutation, RestoreLocationMutationVariables>;

/**
 * __useRestoreLocationMutation__
 *
 * To run a mutation, you first call `useRestoreLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreLocationMutation, { data, loading, error }] = useRestoreLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreLocationMutation, RestoreLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreLocationMutation, RestoreLocationMutationVariables>(RestoreLocationDocument, baseOptions);
      }
export type RestoreLocationMutationHookResult = ReturnType<typeof useRestoreLocationMutation>;
export type RestoreLocationMutationResult = ApolloReactCommon.MutationResult<RestoreLocationMutation>;
export type RestoreLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreLocationMutation, RestoreLocationMutationVariables>;
export const ForceDeleteLocationDocument = gql`
    mutation ForceDeleteLocation($id: ID!) {
  forceDeleteLocation(id: $id) {
    id
  }
}
    `;
export type ForceDeleteLocationMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteLocationMutation, ForceDeleteLocationMutationVariables>;

/**
 * __useForceDeleteLocationMutation__
 *
 * To run a mutation, you first call `useForceDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteLocationMutation, { data, loading, error }] = useForceDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteLocationMutation, ForceDeleteLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteLocationMutation, ForceDeleteLocationMutationVariables>(ForceDeleteLocationDocument, baseOptions);
      }
export type ForceDeleteLocationMutationHookResult = ReturnType<typeof useForceDeleteLocationMutation>;
export type ForceDeleteLocationMutationResult = ApolloReactCommon.MutationResult<ForceDeleteLocationMutation>;
export type ForceDeleteLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteLocationMutation, ForceDeleteLocationMutationVariables>;
export const EventMatchingDocument = gql`
    query EventMatching($id: ID!) {
  event(id: $id) {
    ...MatchingPage
  }
}
    ${MatchingPageFragmentDoc}`;

/**
 * __useEventMatchingQuery__
 *
 * To run a query within a React component, call `useEventMatchingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventMatchingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventMatchingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventMatchingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventMatchingQuery, EventMatchingQueryVariables>) {
        return ApolloReactHooks.useQuery<EventMatchingQuery, EventMatchingQueryVariables>(EventMatchingDocument, baseOptions);
      }
export function useEventMatchingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventMatchingQuery, EventMatchingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventMatchingQuery, EventMatchingQueryVariables>(EventMatchingDocument, baseOptions);
        }
export type EventMatchingQueryHookResult = ReturnType<typeof useEventMatchingQuery>;
export type EventMatchingLazyQueryHookResult = ReturnType<typeof useEventMatchingLazyQuery>;
export type EventMatchingQueryResult = ApolloReactCommon.QueryResult<EventMatchingQuery, EventMatchingQueryVariables>;
export const UpdateEventCandidatesDocument = gql`
    mutation UpdateEventCandidates($input: UpdateCandidatesInput!) {
  updateCandidates(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type UpdateEventCandidatesMutationFn = ApolloReactCommon.MutationFunction<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>;

/**
 * __useUpdateEventCandidatesMutation__
 *
 * To run a mutation, you first call `useUpdateEventCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventCandidatesMutation, { data, loading, error }] = useUpdateEventCandidatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventCandidatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>(UpdateEventCandidatesDocument, baseOptions);
      }
export type UpdateEventCandidatesMutationHookResult = ReturnType<typeof useUpdateEventCandidatesMutation>;
export type UpdateEventCandidatesMutationResult = ApolloReactCommon.MutationResult<UpdateEventCandidatesMutation>;
export type UpdateEventCandidatesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>;
export const CandidateSignupDocument = gql`
    query CandidateSignup($id: ID!) {
  signup(id: $id) {
    ...CandidateListItem
  }
}
    ${CandidateListItemFragmentDoc}`;

/**
 * __useCandidateSignupQuery__
 *
 * To run a query within a React component, call `useCandidateSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateSignupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CandidateSignupQuery, CandidateSignupQueryVariables>) {
        return ApolloReactHooks.useQuery<CandidateSignupQuery, CandidateSignupQueryVariables>(CandidateSignupDocument, baseOptions);
      }
export function useCandidateSignupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CandidateSignupQuery, CandidateSignupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CandidateSignupQuery, CandidateSignupQueryVariables>(CandidateSignupDocument, baseOptions);
        }
export type CandidateSignupQueryHookResult = ReturnType<typeof useCandidateSignupQuery>;
export type CandidateSignupLazyQueryHookResult = ReturnType<typeof useCandidateSignupLazyQuery>;
export type CandidateSignupQueryResult = ApolloReactCommon.QueryResult<CandidateSignupQuery, CandidateSignupQueryVariables>;
export const ProspectSignupDocument = gql`
    query ProspectSignup($id: ID!) {
  signup(id: $id) {
    ...ProspectListItem
  }
}
    ${ProspectListItemFragmentDoc}`;

/**
 * __useProspectSignupQuery__
 *
 * To run a query within a React component, call `useProspectSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectSignupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProspectSignupQuery, ProspectSignupQueryVariables>) {
        return ApolloReactHooks.useQuery<ProspectSignupQuery, ProspectSignupQueryVariables>(ProspectSignupDocument, baseOptions);
      }
export function useProspectSignupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProspectSignupQuery, ProspectSignupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProspectSignupQuery, ProspectSignupQueryVariables>(ProspectSignupDocument, baseOptions);
        }
export type ProspectSignupQueryHookResult = ReturnType<typeof useProspectSignupQuery>;
export type ProspectSignupLazyQueryHookResult = ReturnType<typeof useProspectSignupLazyQuery>;
export type ProspectSignupQueryResult = ApolloReactCommon.QueryResult<ProspectSignupQuery, ProspectSignupQueryVariables>;
export const ProspectGroupSignupDocument = gql`
    query ProspectGroupSignup($id: ID!) {
  signup(id: $id) {
    ...ProspectGroupListItem
  }
}
    ${ProspectGroupListItemFragmentDoc}`;

/**
 * __useProspectGroupSignupQuery__
 *
 * To run a query within a React component, call `useProspectGroupSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectGroupSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectGroupSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectGroupSignupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>) {
        return ApolloReactHooks.useQuery<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>(ProspectGroupSignupDocument, baseOptions);
      }
export function useProspectGroupSignupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>(ProspectGroupSignupDocument, baseOptions);
        }
export type ProspectGroupSignupQueryHookResult = ReturnType<typeof useProspectGroupSignupQuery>;
export type ProspectGroupSignupLazyQueryHookResult = ReturnType<typeof useProspectGroupSignupLazyQuery>;
export type ProspectGroupSignupQueryResult = ApolloReactCommon.QueryResult<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>;
export const CompanionMatchingDocument = gql`
    query CompanionMatching($id: ID!) {
  event(id: $id) {
    ...CompanionMatchingTab
  }
}
    ${CompanionMatchingTabFragmentDoc}`;

/**
 * __useCompanionMatchingQuery__
 *
 * To run a query within a React component, call `useCompanionMatchingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionMatchingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionMatchingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanionMatchingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionMatchingQuery, CompanionMatchingQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionMatchingQuery, CompanionMatchingQueryVariables>(CompanionMatchingDocument, baseOptions);
      }
export function useCompanionMatchingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionMatchingQuery, CompanionMatchingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionMatchingQuery, CompanionMatchingQueryVariables>(CompanionMatchingDocument, baseOptions);
        }
export type CompanionMatchingQueryHookResult = ReturnType<typeof useCompanionMatchingQuery>;
export type CompanionMatchingLazyQueryHookResult = ReturnType<typeof useCompanionMatchingLazyQuery>;
export type CompanionMatchingQueryResult = ApolloReactCommon.QueryResult<CompanionMatchingQuery, CompanionMatchingQueryVariables>;
export const SoulAttendsDocument = gql`
    query SoulAttends($id: ID!, $cursor: String) {
  soul(id: $id) {
    attends(first: 20, after: $cursor) {
      edges {
        node {
          ...AttendListItem
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${AttendListItemFragmentDoc}`;

/**
 * __useSoulAttendsQuery__
 *
 * To run a query within a React component, call `useSoulAttendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulAttendsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulAttendsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSoulAttendsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoulAttendsQuery, SoulAttendsQueryVariables>) {
        return ApolloReactHooks.useQuery<SoulAttendsQuery, SoulAttendsQueryVariables>(SoulAttendsDocument, baseOptions);
      }
export function useSoulAttendsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoulAttendsQuery, SoulAttendsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoulAttendsQuery, SoulAttendsQueryVariables>(SoulAttendsDocument, baseOptions);
        }
export type SoulAttendsQueryHookResult = ReturnType<typeof useSoulAttendsQuery>;
export type SoulAttendsLazyQueryHookResult = ReturnType<typeof useSoulAttendsLazyQuery>;
export type SoulAttendsQueryResult = ApolloReactCommon.QueryResult<SoulAttendsQuery, SoulAttendsQueryVariables>;
export const CompanionAttendsDocument = gql`
    query CompanionAttends($id: ID!, $cursor: String) {
  companion(id: $id) {
    attends(first: 20, after: $cursor) {
      edges {
        node {
          ...AttendListItem
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${AttendListItemFragmentDoc}`;

/**
 * __useCompanionAttendsQuery__
 *
 * To run a query within a React component, call `useCompanionAttendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionAttendsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionAttendsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCompanionAttendsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionAttendsQuery, CompanionAttendsQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionAttendsQuery, CompanionAttendsQueryVariables>(CompanionAttendsDocument, baseOptions);
      }
export function useCompanionAttendsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionAttendsQuery, CompanionAttendsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionAttendsQuery, CompanionAttendsQueryVariables>(CompanionAttendsDocument, baseOptions);
        }
export type CompanionAttendsQueryHookResult = ReturnType<typeof useCompanionAttendsQuery>;
export type CompanionAttendsLazyQueryHookResult = ReturnType<typeof useCompanionAttendsLazyQuery>;
export type CompanionAttendsQueryResult = ApolloReactCommon.QueryResult<CompanionAttendsQuery, CompanionAttendsQueryVariables>;
export const SoulFutureSignupsDocument = gql`
    query SoulFutureSignups($id: ID!, $cursor: String) {
  soul(id: $id) {
    future_signups(first: 20, after: $cursor) {
      edges {
        node {
          ...FutureSignupListItem
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${FutureSignupListItemFragmentDoc}`;

/**
 * __useSoulFutureSignupsQuery__
 *
 * To run a query within a React component, call `useSoulFutureSignupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulFutureSignupsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulFutureSignupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSoulFutureSignupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>) {
        return ApolloReactHooks.useQuery<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>(SoulFutureSignupsDocument, baseOptions);
      }
export function useSoulFutureSignupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>(SoulFutureSignupsDocument, baseOptions);
        }
export type SoulFutureSignupsQueryHookResult = ReturnType<typeof useSoulFutureSignupsQuery>;
export type SoulFutureSignupsLazyQueryHookResult = ReturnType<typeof useSoulFutureSignupsLazyQuery>;
export type SoulFutureSignupsQueryResult = ApolloReactCommon.QueryResult<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>;
export const CompanionFutureSignupsDocument = gql`
    query CompanionFutureSignups($id: ID!, $cursor: String) {
  companion(id: $id) {
    future_signups(first: 20, after: $cursor) {
      edges {
        node {
          ...FutureSignupListItem
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${FutureSignupListItemFragmentDoc}`;

/**
 * __useCompanionFutureSignupsQuery__
 *
 * To run a query within a React component, call `useCompanionFutureSignupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionFutureSignupsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionFutureSignupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCompanionFutureSignupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>(CompanionFutureSignupsDocument, baseOptions);
      }
export function useCompanionFutureSignupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>(CompanionFutureSignupsDocument, baseOptions);
        }
export type CompanionFutureSignupsQueryHookResult = ReturnType<typeof useCompanionFutureSignupsQuery>;
export type CompanionFutureSignupsLazyQueryHookResult = ReturnType<typeof useCompanionFutureSignupsLazyQuery>;
export type CompanionFutureSignupsQueryResult = ApolloReactCommon.QueryResult<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>;
export const OrganisationListDocument = gql`
    query OrganisationList {
  organisations {
    ...OrganisationListItem
  }
}
    ${OrganisationListItemFragmentDoc}`;

/**
 * __useOrganisationListQuery__
 *
 * To run a query within a React component, call `useOrganisationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganisationListQuery, OrganisationListQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganisationListQuery, OrganisationListQueryVariables>(OrganisationListDocument, baseOptions);
      }
export function useOrganisationListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganisationListQuery, OrganisationListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganisationListQuery, OrganisationListQueryVariables>(OrganisationListDocument, baseOptions);
        }
export type OrganisationListQueryHookResult = ReturnType<typeof useOrganisationListQuery>;
export type OrganisationListLazyQueryHookResult = ReturnType<typeof useOrganisationListLazyQuery>;
export type OrganisationListQueryResult = ApolloReactCommon.QueryResult<OrganisationListQuery, OrganisationListQueryVariables>;
export const OrganisationDetailDocument = gql`
    query OrganisationDetail($id: ID!) {
  organisation(id: $id, trashed: WITH) {
    ...OrganisationDetailPage
  }
}
    ${OrganisationDetailPageFragmentDoc}`;

/**
 * __useOrganisationDetailQuery__
 *
 * To run a query within a React component, call `useOrganisationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganisationDetailQuery, OrganisationDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganisationDetailQuery, OrganisationDetailQueryVariables>(OrganisationDetailDocument, baseOptions);
      }
export function useOrganisationDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganisationDetailQuery, OrganisationDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganisationDetailQuery, OrganisationDetailQueryVariables>(OrganisationDetailDocument, baseOptions);
        }
export type OrganisationDetailQueryHookResult = ReturnType<typeof useOrganisationDetailQuery>;
export type OrganisationDetailLazyQueryHookResult = ReturnType<typeof useOrganisationDetailLazyQuery>;
export type OrganisationDetailQueryResult = ApolloReactCommon.QueryResult<OrganisationDetailQuery, OrganisationDetailQueryVariables>;
export const OrganisationEditDocument = gql`
    query OrganisationEdit($id: ID!) {
  organisation(id: $id, trashed: WITH) {
    ...OrganisationEditForm
  }
}
    ${OrganisationEditFormFragmentDoc}`;

/**
 * __useOrganisationEditQuery__
 *
 * To run a query within a React component, call `useOrganisationEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganisationEditQuery, OrganisationEditQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganisationEditQuery, OrganisationEditQueryVariables>(OrganisationEditDocument, baseOptions);
      }
export function useOrganisationEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganisationEditQuery, OrganisationEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganisationEditQuery, OrganisationEditQueryVariables>(OrganisationEditDocument, baseOptions);
        }
export type OrganisationEditQueryHookResult = ReturnType<typeof useOrganisationEditQuery>;
export type OrganisationEditLazyQueryHookResult = ReturnType<typeof useOrganisationEditLazyQuery>;
export type OrganisationEditQueryResult = ApolloReactCommon.QueryResult<OrganisationEditQuery, OrganisationEditQueryVariables>;
export const CreateOrganisationDocument = gql`
    mutation CreateOrganisation($input: CreateOrganisationInput!) {
  createOrganisation(input: $input) {
    ...OrganisationDetailPage
  }
}
    ${OrganisationDetailPageFragmentDoc}`;
export type CreateOrganisationMutationFn = ApolloReactCommon.MutationFunction<CreateOrganisationMutation, CreateOrganisationMutationVariables>;

/**
 * __useCreateOrganisationMutation__
 *
 * To run a mutation, you first call `useCreateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisationMutation, { data, loading, error }] = useCreateOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganisationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrganisationMutation, CreateOrganisationMutationVariables>(CreateOrganisationDocument, baseOptions);
      }
export type CreateOrganisationMutationHookResult = ReturnType<typeof useCreateOrganisationMutation>;
export type CreateOrganisationMutationResult = ApolloReactCommon.MutationResult<CreateOrganisationMutation>;
export type CreateOrganisationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>;
export const UpdateOrganisationDocument = gql`
    mutation UpdateOrganisation($input: UpdateOrganisationInput!) {
  updateOrganisation(input: $input) {
    ...OrganisationDetailPage
  }
}
    ${OrganisationDetailPageFragmentDoc}`;
export type UpdateOrganisationMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>(UpdateOrganisationDocument, baseOptions);
      }
export type UpdateOrganisationMutationHookResult = ReturnType<typeof useUpdateOrganisationMutation>;
export type UpdateOrganisationMutationResult = ApolloReactCommon.MutationResult<UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;
export const DeleteOrganisationDocument = gql`
    mutation DeleteOrganisation($id: ID!) {
  deleteOrganisation(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteOrganisationMutationFn = ApolloReactCommon.MutationFunction<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>;

/**
 * __useDeleteOrganisationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganisationMutation, { data, loading, error }] = useDeleteOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganisationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>(DeleteOrganisationDocument, baseOptions);
      }
export type DeleteOrganisationMutationHookResult = ReturnType<typeof useDeleteOrganisationMutation>;
export type DeleteOrganisationMutationResult = ApolloReactCommon.MutationResult<DeleteOrganisationMutation>;
export type DeleteOrganisationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>;
export const RestoreOrganisationDocument = gql`
    mutation RestoreOrganisation($id: ID!) {
  restoreOrganisation(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreOrganisationMutationFn = ApolloReactCommon.MutationFunction<RestoreOrganisationMutation, RestoreOrganisationMutationVariables>;

/**
 * __useRestoreOrganisationMutation__
 *
 * To run a mutation, you first call `useRestoreOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreOrganisationMutation, { data, loading, error }] = useRestoreOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreOrganisationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreOrganisationMutation, RestoreOrganisationMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreOrganisationMutation, RestoreOrganisationMutationVariables>(RestoreOrganisationDocument, baseOptions);
      }
export type RestoreOrganisationMutationHookResult = ReturnType<typeof useRestoreOrganisationMutation>;
export type RestoreOrganisationMutationResult = ApolloReactCommon.MutationResult<RestoreOrganisationMutation>;
export type RestoreOrganisationMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreOrganisationMutation, RestoreOrganisationMutationVariables>;
export const ForceDeleteOrganisationDocument = gql`
    mutation ForceDeleteOrganisation($id: ID!) {
  forceDeleteOrganisation(id: $id) {
    id
  }
}
    `;
export type ForceDeleteOrganisationMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteOrganisationMutation, ForceDeleteOrganisationMutationVariables>;

/**
 * __useForceDeleteOrganisationMutation__
 *
 * To run a mutation, you first call `useForceDeleteOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteOrganisationMutation, { data, loading, error }] = useForceDeleteOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteOrganisationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteOrganisationMutation, ForceDeleteOrganisationMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteOrganisationMutation, ForceDeleteOrganisationMutationVariables>(ForceDeleteOrganisationDocument, baseOptions);
      }
export type ForceDeleteOrganisationMutationHookResult = ReturnType<typeof useForceDeleteOrganisationMutation>;
export type ForceDeleteOrganisationMutationResult = ApolloReactCommon.MutationResult<ForceDeleteOrganisationMutation>;
export type ForceDeleteOrganisationMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteOrganisationMutation, ForceDeleteOrganisationMutationVariables>;
export const SoulListDocument = gql`
    query SoulList($status: UserStatus!, $cursor: String) {
  souls(status: $status, first: 20, after: $cursor) {
    edges {
      node {
        ...SoulListItem
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${SoulListItemFragmentDoc}`;

/**
 * __useSoulListQuery__
 *
 * To run a query within a React component, call `useSoulListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSoulListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoulListQuery, SoulListQueryVariables>) {
        return ApolloReactHooks.useQuery<SoulListQuery, SoulListQueryVariables>(SoulListDocument, baseOptions);
      }
export function useSoulListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoulListQuery, SoulListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoulListQuery, SoulListQueryVariables>(SoulListDocument, baseOptions);
        }
export type SoulListQueryHookResult = ReturnType<typeof useSoulListQuery>;
export type SoulListLazyQueryHookResult = ReturnType<typeof useSoulListLazyQuery>;
export type SoulListQueryResult = ApolloReactCommon.QueryResult<SoulListQuery, SoulListQueryVariables>;
export const SoulDetailDocument = gql`
    query SoulDetail($id: ID!) {
  soul(id: $id, trashed: WITH) {
    ...SoulDetailPage
  }
}
    ${SoulDetailPageFragmentDoc}`;

/**
 * __useSoulDetailQuery__
 *
 * To run a query within a React component, call `useSoulDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoulDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoulDetailQuery, SoulDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<SoulDetailQuery, SoulDetailQueryVariables>(SoulDetailDocument, baseOptions);
      }
export function useSoulDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoulDetailQuery, SoulDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoulDetailQuery, SoulDetailQueryVariables>(SoulDetailDocument, baseOptions);
        }
export type SoulDetailQueryHookResult = ReturnType<typeof useSoulDetailQuery>;
export type SoulDetailLazyQueryHookResult = ReturnType<typeof useSoulDetailLazyQuery>;
export type SoulDetailQueryResult = ApolloReactCommon.QueryResult<SoulDetailQuery, SoulDetailQueryVariables>;
export const SoulEditDocument = gql`
    query SoulEdit($id: ID!) {
  soul(id: $id, trashed: WITH) {
    ...SoulEditForm
  }
}
    ${SoulEditFormFragmentDoc}`;

/**
 * __useSoulEditQuery__
 *
 * To run a query within a React component, call `useSoulEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoulEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoulEditQuery, SoulEditQueryVariables>) {
        return ApolloReactHooks.useQuery<SoulEditQuery, SoulEditQueryVariables>(SoulEditDocument, baseOptions);
      }
export function useSoulEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoulEditQuery, SoulEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoulEditQuery, SoulEditQueryVariables>(SoulEditDocument, baseOptions);
        }
export type SoulEditQueryHookResult = ReturnType<typeof useSoulEditQuery>;
export type SoulEditLazyQueryHookResult = ReturnType<typeof useSoulEditLazyQuery>;
export type SoulEditQueryResult = ApolloReactCommon.QueryResult<SoulEditQuery, SoulEditQueryVariables>;
export const CreateSoulDocument = gql`
    mutation CreateSoul($input: CreateSoulInput!) {
  createSoul(input: $input) {
    ...SoulDetailPage
  }
}
    ${SoulDetailPageFragmentDoc}`;
export type CreateSoulMutationFn = ApolloReactCommon.MutationFunction<CreateSoulMutation, CreateSoulMutationVariables>;

/**
 * __useCreateSoulMutation__
 *
 * To run a mutation, you first call `useCreateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSoulMutation, { data, loading, error }] = useCreateSoulMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSoulMutation, CreateSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSoulMutation, CreateSoulMutationVariables>(CreateSoulDocument, baseOptions);
      }
export type CreateSoulMutationHookResult = ReturnType<typeof useCreateSoulMutation>;
export type CreateSoulMutationResult = ApolloReactCommon.MutationResult<CreateSoulMutation>;
export type CreateSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSoulMutation, CreateSoulMutationVariables>;
export const UpdateSoulDocument = gql`
    mutation UpdateSoul($input: UpdateSoulInput!) {
  updateSoul(input: $input) {
    ...SoulDetailPage
  }
}
    ${SoulDetailPageFragmentDoc}`;
export type UpdateSoulMutationFn = ApolloReactCommon.MutationFunction<UpdateSoulMutation, UpdateSoulMutationVariables>;

/**
 * __useUpdateSoulMutation__
 *
 * To run a mutation, you first call `useUpdateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSoulMutation, { data, loading, error }] = useUpdateSoulMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSoulMutation, UpdateSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSoulMutation, UpdateSoulMutationVariables>(UpdateSoulDocument, baseOptions);
      }
export type UpdateSoulMutationHookResult = ReturnType<typeof useUpdateSoulMutation>;
export type UpdateSoulMutationResult = ApolloReactCommon.MutationResult<UpdateSoulMutation>;
export type UpdateSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSoulMutation, UpdateSoulMutationVariables>;
export const ApproveSoulDocument = gql`
    mutation ApproveSoul($id: ID!) {
  approveSoul(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type ApproveSoulMutationFn = ApolloReactCommon.MutationFunction<ApproveSoulMutation, ApproveSoulMutationVariables>;

/**
 * __useApproveSoulMutation__
 *
 * To run a mutation, you first call `useApproveSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSoulMutation, { data, loading, error }] = useApproveSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveSoulMutation, ApproveSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveSoulMutation, ApproveSoulMutationVariables>(ApproveSoulDocument, baseOptions);
      }
export type ApproveSoulMutationHookResult = ReturnType<typeof useApproveSoulMutation>;
export type ApproveSoulMutationResult = ApolloReactCommon.MutationResult<ApproveSoulMutation>;
export type ApproveSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveSoulMutation, ApproveSoulMutationVariables>;
export const DenySoulDocument = gql`
    mutation DenySoul($id: ID!) {
  denySoul(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type DenySoulMutationFn = ApolloReactCommon.MutationFunction<DenySoulMutation, DenySoulMutationVariables>;

/**
 * __useDenySoulMutation__
 *
 * To run a mutation, you first call `useDenySoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenySoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denySoulMutation, { data, loading, error }] = useDenySoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDenySoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DenySoulMutation, DenySoulMutationVariables>) {
        return ApolloReactHooks.useMutation<DenySoulMutation, DenySoulMutationVariables>(DenySoulDocument, baseOptions);
      }
export type DenySoulMutationHookResult = ReturnType<typeof useDenySoulMutation>;
export type DenySoulMutationResult = ApolloReactCommon.MutationResult<DenySoulMutation>;
export type DenySoulMutationOptions = ApolloReactCommon.BaseMutationOptions<DenySoulMutation, DenySoulMutationVariables>;
export const ActivateSoulDocument = gql`
    mutation ActivateSoul($id: ID!) {
  activateSoul(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type ActivateSoulMutationFn = ApolloReactCommon.MutationFunction<ActivateSoulMutation, ActivateSoulMutationVariables>;

/**
 * __useActivateSoulMutation__
 *
 * To run a mutation, you first call `useActivateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSoulMutation, { data, loading, error }] = useActivateSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateSoulMutation, ActivateSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateSoulMutation, ActivateSoulMutationVariables>(ActivateSoulDocument, baseOptions);
      }
export type ActivateSoulMutationHookResult = ReturnType<typeof useActivateSoulMutation>;
export type ActivateSoulMutationResult = ApolloReactCommon.MutationResult<ActivateSoulMutation>;
export type ActivateSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateSoulMutation, ActivateSoulMutationVariables>;
export const DeactivateSoulDocument = gql`
    mutation DeactivateSoul($id: ID!) {
  deactivateSoul(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type DeactivateSoulMutationFn = ApolloReactCommon.MutationFunction<DeactivateSoulMutation, DeactivateSoulMutationVariables>;

/**
 * __useDeactivateSoulMutation__
 *
 * To run a mutation, you first call `useDeactivateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateSoulMutation, { data, loading, error }] = useDeactivateSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeactivateSoulMutation, DeactivateSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<DeactivateSoulMutation, DeactivateSoulMutationVariables>(DeactivateSoulDocument, baseOptions);
      }
export type DeactivateSoulMutationHookResult = ReturnType<typeof useDeactivateSoulMutation>;
export type DeactivateSoulMutationResult = ApolloReactCommon.MutationResult<DeactivateSoulMutation>;
export type DeactivateSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<DeactivateSoulMutation, DeactivateSoulMutationVariables>;
export const DeleteSoulDocument = gql`
    mutation DeleteSoul($id: ID!) {
  deleteSoul(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteSoulMutationFn = ApolloReactCommon.MutationFunction<DeleteSoulMutation, DeleteSoulMutationVariables>;

/**
 * __useDeleteSoulMutation__
 *
 * To run a mutation, you first call `useDeleteSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSoulMutation, { data, loading, error }] = useDeleteSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSoulMutation, DeleteSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSoulMutation, DeleteSoulMutationVariables>(DeleteSoulDocument, baseOptions);
      }
export type DeleteSoulMutationHookResult = ReturnType<typeof useDeleteSoulMutation>;
export type DeleteSoulMutationResult = ApolloReactCommon.MutationResult<DeleteSoulMutation>;
export type DeleteSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSoulMutation, DeleteSoulMutationVariables>;
export const RestoreSoulDocument = gql`
    mutation RestoreSoul($id: ID!) {
  restoreSoul(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreSoulMutationFn = ApolloReactCommon.MutationFunction<RestoreSoulMutation, RestoreSoulMutationVariables>;

/**
 * __useRestoreSoulMutation__
 *
 * To run a mutation, you first call `useRestoreSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreSoulMutation, { data, loading, error }] = useRestoreSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreSoulMutation, RestoreSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreSoulMutation, RestoreSoulMutationVariables>(RestoreSoulDocument, baseOptions);
      }
export type RestoreSoulMutationHookResult = ReturnType<typeof useRestoreSoulMutation>;
export type RestoreSoulMutationResult = ApolloReactCommon.MutationResult<RestoreSoulMutation>;
export type RestoreSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreSoulMutation, RestoreSoulMutationVariables>;
export const ForceDeleteSoulDocument = gql`
    mutation ForceDeleteSoul($id: ID!) {
  forceDeleteSoul(id: $id) {
    id
  }
}
    `;
export type ForceDeleteSoulMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteSoulMutation, ForceDeleteSoulMutationVariables>;

/**
 * __useForceDeleteSoulMutation__
 *
 * To run a mutation, you first call `useForceDeleteSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteSoulMutation, { data, loading, error }] = useForceDeleteSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteSoulMutation, ForceDeleteSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteSoulMutation, ForceDeleteSoulMutationVariables>(ForceDeleteSoulDocument, baseOptions);
      }
export type ForceDeleteSoulMutationHookResult = ReturnType<typeof useForceDeleteSoulMutation>;
export type ForceDeleteSoulMutationResult = ApolloReactCommon.MutationResult<ForceDeleteSoulMutation>;
export type ForceDeleteSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteSoulMutation, ForceDeleteSoulMutationVariables>;
export const TagsDocument = gql`
    query Tags {
  tags {
    ...PersonTagMultiSelect
  }
}
    ${PersonTagMultiSelectFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        return ApolloReactHooks.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
      }
export function useTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = ApolloReactCommon.QueryResult<TagsQuery, TagsQueryVariables>;
export const RolesDocument = gql`
    query Roles {
  roles {
    ...EmployeeRoleMultiSelect
  }
}
    ${EmployeeRoleMultiSelectFragmentDoc}`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        return ApolloReactHooks.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
      }
export function useRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = ApolloReactCommon.QueryResult<RolesQuery, RolesQueryVariables>;
export const CategoriesDocument = gql`
    query Categories {
  categories {
    ...CategoryMultiSelect
  }
}
    ${CategoryMultiSelectFragmentDoc}`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
      }
export function useCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = ApolloReactCommon.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const AllotmentsDocument = gql`
    query Allotments {
  allotments {
    ...AllotmentSelect
  }
}
    ${AllotmentSelectFragmentDoc}`;

/**
 * __useAllotmentsQuery__
 *
 * To run a query within a React component, call `useAllotmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllotmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllotmentsQuery, AllotmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllotmentsQuery, AllotmentsQueryVariables>(AllotmentsDocument, baseOptions);
      }
export function useAllotmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllotmentsQuery, AllotmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllotmentsQuery, AllotmentsQueryVariables>(AllotmentsDocument, baseOptions);
        }
export type AllotmentsQueryHookResult = ReturnType<typeof useAllotmentsQuery>;
export type AllotmentsLazyQueryHookResult = ReturnType<typeof useAllotmentsLazyQuery>;
export type AllotmentsQueryResult = ApolloReactCommon.QueryResult<AllotmentsQuery, AllotmentsQueryVariables>;
export const OrganisationsDocument = gql`
    query Organisations {
  organisations {
    ...PersonOrganisationSelect
  }
}
    ${PersonOrganisationSelectFragmentDoc}`;

/**
 * __useOrganisationsQuery__
 *
 * To run a query within a React component, call `useOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, baseOptions);
      }
export function useOrganisationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, baseOptions);
        }
export type OrganisationsQueryHookResult = ReturnType<typeof useOrganisationsQuery>;
export type OrganisationsLazyQueryHookResult = ReturnType<typeof useOrganisationsLazyQuery>;
export type OrganisationsQueryResult = ApolloReactCommon.QueryResult<OrganisationsQuery, OrganisationsQueryVariables>;