import { useApolloClient, useQuery } from '@apollo/client';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { useLoginMutation } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import LoginForm, { ILoginFormValues } from '../components/LoginForm';
import { IS_AUTHENTICATED } from '../queries';

const defaultPage = { pathname: '/' };

const LoginPage = ({ location: { state } }: RouteComponentProps) => {
  const client = useApolloClient();
  const { data } = useQuery(IS_AUTHENTICATED);
  const [login] = useLoginMutation();

  const handleSubmit = useSubmit({
    mutate: (values: ILoginFormValues) =>
      login({
        variables: {
          input: values,
        },
      }),
    onCompleted: ({ data }) => {
      if (data && data.login) {
        localStorage.setItem('token', data.login.access_token);
        localStorage.setItem('branch_id', data.login.user.branch_id);
        client.writeData({ data: { isAuthenticated: true } });
      }
    },
    getErrors: getLaravelValidationErrors,
  });

  if (!data) return null;
  if (data.isAuthenticated) return <Redirect to={(state && state.from) || defaultPage} />;
  return (
    <div style={styles.container}>
      <LoginForm onSubmit={handleSubmit} />
    </div>
  );
};

export default LoginPage;

const styles = {
  container: {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
