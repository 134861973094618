import { format, formatDistanceStrict, formatDistanceToNow, isFuture, isPast, isValid, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import { useEffect, useRef } from 'react';

export const arrayUnique = <T>(arr: T[]) => arr.filter((elem, pos, arr) => arr.indexOf(elem) === pos);

export const assertNever = (x: never): never => {
  throw new Error('Unexpected object: ' + x);
};

// From: https://gomakethings.com/dynamically-changing-the-text-color-based-on-background-color-contrast-with-vanilla-js/
export const needsDarkText = (hexcolor: string) => {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split('')
      .map(hex => hex + hex)
      .join('');
  }

  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio (See: https://24ways.org/2010/calculating-color-contrast/)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128;
};

// from https://usehooks.com/usePrevious/
export const usePrevious = <T>(value: T) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const withoutBubbling = (callback?: () => void): React.MouseEventHandler<HTMLAnchorElement> => event => {
  event.stopPropagation();

  if (callback) {
    event.preventDefault();
    callback();
  }
};

export const classNames = (...params: string[]) => params.join(' ');

export const getSubdomain = () => {
  const parts = window.location.host.split('.');
  return parts.length >= 3 ? parts[0] : '';
};

export const formatToNowStrict = (str: string) => {
  const date = parseISO(str);
  if (!isValid(date)) {
    console.error('Invalid date: ', str);
    return '';
  }
  return formatDistanceStrict(date, new Date(), { locale: de, addSuffix: true });
};

export const formatToNow = (str: string) => {
  const date = parseISO(str);
  if (!isValid(date)) {
    console.error('Invalid date: ', str);
    return '';
  }
  return formatDistanceToNow(date, { locale: de, addSuffix: true });
};

export const formatDateTime = (str: string, pattern: string) => {
  const date = parseISO(str);
  if (!isValid(date)) {
    console.error('Invalid date: ', str);
    return '';
  }
  return format(date, pattern);
};

export const isInPast = (str: string) => {
  const date = parseISO(str);
  if (!isValid(date)) {
    console.error('Invalid date: ', str);
    return false;
  }
  return isPast(date);
};

export const isInFuture = (str: string) => {
  const date = parseISO(str);
  if (!isValid(date)) {
    console.error('Invalid date: ', str);
    return false;
  }
  return isFuture(date);
};

export const formatDay = (str: string) => formatDateTime(str, 'dd.MM.yyyy');

export const formatTime = (str: string) => formatDateTime(str, 'HH:mm');
