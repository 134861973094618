/** @jsx jsx */
import { Colors, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, MultiSelect } from '@blueprintjs/select';
import { css, jsx } from '@emotion/core';
import algoliasearch from 'algoliasearch/lite';
import { FieldArray, getIn } from 'formik';
import { Fragment, useRef } from 'react';
import { AutocompleteProvided, Configure, Hit } from 'react-instantsearch-core';
import { connectAutoComplete, Highlight, InstantSearch } from 'react-instantsearch-dom';
import config from '../helpers/config';
import { getBranchId } from '../helpers/constants';
import { PersonType, UserHit } from '../types';

const personTypeIndexMap = {
  [PersonType.Soul]: 'souls',
  [PersonType.Companion]: 'companions',
  [PersonType.Employee]: 'employees',
  [PersonType.Contact]: 'contacts',
};

const getSearchIndex = (personType: PersonType) => personTypeIndexMap[personType];

type SearchHit = Hit<UserHit>;

type PersonMultiSelectInputProps = {
  name: string;
} & AutocompleteProvided<UserHit>;

const renderTag = (person: SearchHit) => person.display_name;

const UserMultiSelect = MultiSelect.ofType<SearchHit>();

const PersonMultiSelectInput = ({ name, hits, refine, currentRefinement }: PersonMultiSelectInputProps) => (
  <FieldArray name={name}>
    {({ form, push, remove }) => {
      const selectedPersons: SearchHit[] | undefined = getIn(form.values, name);
      console.log('selectedPersons: ', selectedPersons);

      const isPersonSelected = (person: SearchHit) => getSelectedPersonIndex(person) !== -1;

      const getSelectedPersonIndex = (person: SearchHit) =>
        selectedPersons ? selectedPersons.findIndex(selectedPerson => selectedPerson.id === person.id) : -1;

      // NOTE: not using Films.itemRenderer here so we can set icons.
      const renderPersonItem: ItemRenderer<SearchHit> = (person, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
          return null;
        }
        return (
          <MenuItem
            active={modifiers.active}
            icon={isPersonSelected(person) ? 'tick' : 'blank'}
            key={person.id}
            onClick={handleClick}
            text={
              <Fragment>
                <Highlight hit={person} attribute="first_name" css={styles.highlight} />{' '}
                <Highlight hit={person} attribute="last_name" css={styles.highlight} />
              </Fragment>
            }
            shouldDismissPopover={false}
          />
        );
      };

      const handlePersonSelect = (person: SearchHit) => {
        if (!isPersonSelected(person)) {
          push(person);
        } else {
          remove(getSelectedPersonIndex(person));
        }
      };

      const handleTagRemove = (_tag: string, index: number) => {
        remove(index);
      };

      const convertHits = (hits: any[]): SearchHit[] =>
        hits.map(hit => ({
          ...hit,
          id: hit.id.toString(),
        }));

      return (
        <UserMultiSelect
          fill
          itemRenderer={renderPersonItem}
          itemListPredicate={(_, items) => items} // Filtering done by Algolia obviously
          tagRenderer={renderTag}
          query={currentRefinement}
          onQueryChange={refine}
          resetOnSelect
          itemsEqual="id"
          items={convertHits(hits)}
          selectedItems={selectedPersons}
          placeholder="Suchen..."
          onItemSelect={handlePersonSelect}
          noResults={<MenuItem disabled={true} text="Keine Resultate." />}
          popoverProps={{ minimal: true, boundary: 'viewport' }}
          tagInputProps={{
            onRemove: handleTagRemove,
            disabled: form.isSubmitting,
          }}
        />
      );
    }}
  </FieldArray>
);

const ConnectedPersonMultiSelectInput = connectAutoComplete<PersonMultiSelectInputProps, UserHit>(
  PersonMultiSelectInput,
);

type PersonMultiSelectProps = {
  name: string;
  type: PersonType;
  filters?: string;
};

const PersonMultiSelect = ({ name, type, filters }: PersonMultiSelectProps) => {
  const searchClient = useRef(algoliasearch(config.algolia.appId, config.algolia.appSecret)).current;

  return (
    <InstantSearch searchClient={searchClient} indexName={getSearchIndex(type)}>
      <ConnectedPersonMultiSelectInput name={name} />
      <Configure hitsPerPage={12} filters={`branch_id=${getBranchId()}` + filters ? ` AND ${filters}` : ''} />
    </InstantSearch>
  );
};

export default PersonMultiSelect;

const styles = {
  highlight: css`
    em {
      font-style: normal;
      color: ${Colors.BLUE1};
    }
  `,
};
