/** @jsx jsx */
import { NetworkStatus, useQuery } from '@apollo/client';
import { HTMLTable } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import Empty from '../../../components/Empty';
import Error from '../../../components/Error';
import InfiniteScrollWrapper from '../../../components/InfiniteScrollWrapper';
import TableHeaderCell from '../../../components/TableHeaderCell';
import {
  CompanionFutureSignupsDocument,
  CompanionFutureSignupsQuery,
  CompanionFutureSignupsQueryVariables,
  SoulFutureSignupsDocument,
  SoulFutureSignupsQuery,
  SoulFutureSignupsQueryVariables,
} from '../../../generated/graphql';
import { dummyFutureSignups } from '../dummy';
import FutureSignupListItem from './FutureSignupListItem';

type FutureSignupListProps = {
  isSoul: boolean;
  prospectId: string;
};

type QueryDataType = SoulFutureSignupsQuery | CompanionFutureSignupsQuery;
type QueryVariablesType = SoulFutureSignupsQueryVariables | CompanionFutureSignupsQueryVariables;

const isSoulFutureSignups = (data: QueryDataType): data is SoulFutureSignupsQuery =>
  !!(data as SoulFutureSignupsQuery).soul;

const TableWrapper = ({ children }: { children: React.ReactNode }) => (
  <HTMLTable condensed css={styles.table}>
    <thead>
      <tr>
        <TableHeaderCell>Veranstaltung</TableHeaderCell>
        <TableHeaderCell>Status</TableHeaderCell>
        <TableHeaderCell>Datum</TableHeaderCell>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </HTMLTable>
);

const FutureSignupList = ({ isSoul, prospectId }: FutureSignupListProps) => {
  const query = isSoul ? SoulFutureSignupsDocument : CompanionFutureSignupsDocument;
  const { data, loading, error, networkStatus, fetchMore } = useQuery<QueryDataType, QueryVariablesType>(query, {
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: prospectId,
      cursor: null,
    },
  });

  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  if (loading && !loadingMore) {
    return (
      <TableWrapper>
        {dummyFutureSignups.map(signup => (
          <FutureSignupListItem key={signup.id} signup={signup} skeleton />
        ))}
      </TableWrapper>
    );
  }

  if (error) {
    return <Error />;
  }

  if (!data) {
    return <Empty />;
  }

  const root = isSoulFutureSignups(data) ? data.soul : data.companion;

  if (!root || !root.future_signups || !root.future_signups.edges || !root.future_signups.edges.length) {
    return <Empty />;
  }

  const hasNextPage = root.future_signups.pageInfo.hasNextPage;
  const nextCursor = root.future_signups.pageInfo.endCursor;

  return (
    <InfiniteScrollWrapper
      loading={loadingMore}
      onLoadMore={() =>
        fetchMore({
          variables: {
            id: prospectId,
            cursor: nextCursor,
          },
        })
      }
      hasNextPage={hasNextPage}
    >
      <TableWrapper>
        {root.future_signups.edges.map(edge =>
          edge && edge.node ? <FutureSignupListItem key={edge.node.id} signup={edge.node} /> : null,
        )}
        {hasNextPage &&
          dummyFutureSignups.map(signup => <FutureSignupListItem key={signup.id} signup={signup} skeleton />)}
      </TableWrapper>
    </InfiniteScrollWrapper>
  );
};

export default FutureSignupList;

const styles = {
  table: css`
    width: 100%;
  `,
  loadingMoreContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  `,
  loadingMoreText: css`
    padding-left: 10px;
  `,
};
