/** @jsx jsx */
import { Button, Intent, Tag, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import PersonIconList from '../../../components/PersonIconList';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { CompanionMatchListItemFragment } from '../../../generated/graphql';

export type CompanionMatchListItemProps = {
  soulSignupId: string;
  companionSignup: CompanionMatchListItemFragment;
  isActive: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  addTeam: (companionSignupId: string, soulSignupId: string) => void;
};

const CompanionMatchListItem = ({
  soulSignupId,
  companionSignup,
  isActive,
  onMouseLeave,
  onMouseEnter,
  addTeam,
}: CompanionMatchListItemProps) => {
  const prospect = companionSignup.companion!;
  const { user, organisation } = prospect;

  return (
    <tr onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={isActive ? 'active' : ''}>
      <TableCell>
        {user!.display_name}

        <br />
        {!!organisation && (
          <Text small muted>
            {organisation.name}
          </Text>
        )}
      </TableCell>
      <TableCell>
        {!user.lat && (
          <Tag intent={Intent.WARNING} minimal css={styles.statusTag}>
            Adresse Fehlt
          </Tag>
        )}
        <PersonIconList person={prospect} />
        <br />
        <Text small muted>
          {companionSignup.notes}
        </Text>
      </TableCell>
      <TableCell>{companionSignup.priority}</TableCell>
      <TableCell>
        <Tooltip content={`Mit ${user.display_name} als Begleitung auf die Teilnehmerliste`}>
          <Button
            small
            icon={IconNames.SMALL_TICK}
            intent={Intent.SUCCESS}
            onClick={() => addTeam(companionSignup.id, soulSignupId)}
          />
        </Tooltip>
      </TableCell>
    </tr>
  );
};

CompanionMatchListItem.fragments = {
  companion: gql`
    fragment CompanionMatchListItem on Signup {
      id
      event_id
      token
      is_candidate
      is_invited
      has_accepted
      has_rejected
      notes
      priority
      companion {
        id
        notes
        can_blind
        can_wheelchair
        can_wheeled_walker
        can_drive
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
        user {
          id
          display_name
          email
          phone
          mobile
          lat
          lng
        }
      }
    }
  `,
};

export default CompanionMatchListItem;

const styles = {
  statusTag: css`
    margin-right: 5px;
  `,
};
