/** @jsx jsx */
import { IOverlayProps, Overlay } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

interface IContentOverlayProps extends IOverlayProps {
  children: React.ReactNode;
}

const ContentOverlay = (props: IContentOverlayProps) => {
  const { children, ...overlayProps } = props;

  return (
    // enforceFocus={false} is needed to allow Selects inside
    // See: https://github.com/palantir/blueprint/issues/3854
    <Overlay enforceFocus={false} {...overlayProps}>
      <div css={styles.container}>{children}</div>
    </Overlay>
  );
};

export default ContentOverlay;

const styles = {
  container: css`
    left: calc(50vw - 225px);
    margin: 10vh 0;
    width: 450px;
  `,
};
