import gql from 'graphql-tag';

export const IS_AUTHENTICATED = gql`
  query IsUserAuthenticated {
    isAuthenticated @client
  }
`;

export const LOGIN_USER = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      access_token
      user {
        id
        branch_id
        email
        display_name
      }
    }
  }
`;
