/** @jsx jsx */
import { Button, Elevation, HTMLSelect, Intent } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Fragment, useCallback, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import CategoryTag from '../../../components/CategoryTag';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { PropertyList, PropertyListDivider, PropertyListHeader } from '../../../components/PropertyList';
import {
  EventMatchingQuery,
  useEventMatchingQuery,
  useUpdateEventCandidatesMutation,
} from '../../../generated/graphql';
import CandidateList from '../components/CandidateList';
import CandidateListStatus from '../components/CandidateListStatus';
import ProspectList from '../components/ProspectList';
import { initialState, isCompanionCandidate, reducer } from '../state';

const warnInsufficientTickets = () => {
  AppToaster.danger('Nicht genügend Karten vorhanden!');
};

const MatchingPage = () => {
  const history = useHistory();
  const { eventId } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { takenTickets, cardholder, candidates, prospects } = state;

  const { data, loading, error } = useEventMatchingQuery({
    variables: {
      id: eventId || '',
    },
    onCompleted: ({ event }: EventMatchingQuery) => {
      if (event && event.signups && event.signups.length) {
        dispatch({
          type: 'init',
          payload: event.signups,
        });
      }
    },
  });

  const [updateCandidates, { loading: mutating }] = useUpdateEventCandidatesMutation({
    variables: {
      input: {
        id: eventId || '',
        candidates: {
          ...candidates,
          cardholder: cardholder || '',
        },
      },
    },
    onCompleted: () => {
      AppToaster.success('Teilnehmerliste gesichert!');
      history.push(`/events/${eventId}`);
    },
  });

  const save = () => {
    if (!cardholder) {
      AppToaster.danger('Kein Kartenverteiler gewählt');
      return;
    }

    updateCandidates();
  };

  const isUnlimited =
    !!data &&
    !!data.event &&
    (typeof data.event.available_tickets === 'undefined' || data.event.available_tickets === null);
  const remainingTickets = !data || !data.event || isUnlimited ? null : data.event.available_tickets! - takenTickets;
  console.log('remainingTickets: ', remainingTickets);

  const hasMinTickets = useCallback((num: number) => isUnlimited || (!!remainingTickets && remainingTickets >= num), [
    isUnlimited,
    remainingTickets,
  ]);

  const addLoneSoul = useCallback(
    (soulSignupId: string) => {
      if (!hasMinTickets(1)) {
        warnInsufficientTickets();
        return;
      }
      dispatch({
        type: 'addLoneSoul',
        payload: soulSignupId,
      });
    },
    [hasMinTickets],
  );

  const addLoneCompanion = useCallback(
    (CompanionSignupId: string) => {
      if (!hasMinTickets(1)) {
        warnInsufficientTickets();
        return;
      }
      dispatch({
        type: 'addLoneCompanion',
        payload: CompanionSignupId,
      });
    },
    [hasMinTickets],
  );

  const addTeam = useCallback(
    (companionSignupId: string, soulSignupId: string) => {
      if (!hasMinTickets(1) || (!isCompanionCandidate(state, companionSignupId) && !hasMinTickets(2))) {
        warnInsufficientTickets();
        return;
      }
      dispatch({
        type: 'addTeam',
        payload: {
          companion: companionSignupId,
          soul: soulSignupId,
        },
      });
    },
    [hasMinTickets, state],
  );

  const addGroup = useCallback(
    (companionSignupId: string, soulSignupIds: string[]) => {
      if (!hasMinTickets(soulSignupIds.length + 1)) {
        warnInsufficientTickets();
        return;
      }
      dispatch({
        type: 'addGroup',
        payload: {
          companion: companionSignupId,
          souls: soulSignupIds,
        },
      });
    },
    [hasMinTickets],
  );

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.event) return <NotFoundCard resource="Spender" />;

  const { event } = data;

  const priorityMap = event.signups.reduce<{ [k: string]: number }>(
    (acc, signup) => ({
      ...acc,
      [signup.id]: signup.priority,
    }),
    {},
  );

  const soulSignupIds = prospects.souls.sort((a, b) => priorityMap[b] - priorityMap[a]);
  const companionSignupIds = prospects.companions.sort((a, b) => priorityMap[b] - priorityMap[a]);
  const cardholderOptions = event.signups
    .filter(signup => !!signup.companion && !companionSignupIds.includes(signup.id))
    .map(signup => ({ label: signup.companion!.user.display_name, value: signup.id }));

  return (
    <Fragment>
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{event.name}</span>
              {event.categories.map(category => (
                <CategoryTag key={category.id} category={category} />
              ))}
            </Fragment>
          }
          rightElement={
            <Button intent={Intent.SUCCESS} text="Teilnehmerliste Sichern" loading={mutating} onClick={save} />
          }
        />
      </ContentCard>
      <div css={styles.listsContainer}>
        <div css={styles.leftList}>
          <ProspectList
            groupSignupData={prospects.groups}
            soulSignupIds={soulSignupIds}
            companionSignupIds={companionSignupIds}
            addLoneSoul={addLoneSoul}
            addLoneCompanion={addLoneCompanion}
            addTeam={addTeam}
            addGroup={addGroup}
          />
        </div>
        <div css={styles.rightList}>
          <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
            <ContentCardHeader leftElement={<span css={styles.heading}>Teilnehmerliste</span>} />
            <div css={styles.content}>
              <PropertyList>
                <PropertyListHeader>Karten</PropertyListHeader>
                <CandidateListStatus takenTickets={takenTickets} availableTickets={event.available_tickets} />
              </PropertyList>
              <PropertyListDivider />
              <PropertyList>
                <PropertyListHeader>Kartenverteiler</PropertyListHeader>
                <HTMLSelect
                  disabled={!cardholderOptions.length}
                  options={cardholderOptions}
                  fill
                  value={cardholder}
                  onChange={e => dispatch({ type: 'setCardholder', payload: e.target.value })}
                />
              </PropertyList>
            </div>
          </ContentCard>
          <CandidateList candidates={candidates} dispatch={dispatch} />
        </div>
      </div>
    </Fragment>
  );
};

MatchingPage.fragments = {
  matching: gql`
    fragment MatchingPage on Event {
      id
      name
      status
      start
      end
      taken_tickets
      available_tickets
      candidates_chosen
      cardholder {
        id
        user {
          id
          display_name
          mobile
          phone
          email
        }
      }
      categories {
        id
        name
        color
      }
      signups {
        ...CandidateList
      }
      location {
        id
        name
        accessible
        meeting_point
        public_transport
        street
        postal_code
        city
        lat
        lng
      }
    }
    ${CandidateList.fragments.signups}
  `,
};

export default MatchingPage;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
  listsContainer: css`
    display: flex;
  `,
  leftList: css`
    width: 50%;
    padding-right: 10px;
  `,
  rightList: css`
    width: 50%;
    padding-left: 10px;
  `,
};
