/** @jsx jsx */
import { Classes, Colors } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import EmailLink from '../../../components/EmailLink';
import { DonorContactListItemFragment } from '../../../generated/graphql';

export type DonorListProps = {
  item: DonorContactListItemFragment;
  onClick?: () => void;
};

const DonorContactListItem = ({ item, onClick }: DonorListProps) => (
  <tr onClick={onClick}>
    <td css={styles.tableCell}>{item.user.display_name}</td>
    <td css={styles.tableCell}>
      <EmailLink email={item.user.email} />
    </td>
    <td css={styles.tableCell} className={Classes.TEXT_MUTED}>
      {item.user.phone || item.user.mobile || '—'}
    </td>
  </tr>
);

DonorContactListItem.fragments = {
  contact: gql`
    fragment DonorContactListItem on Contact {
      id
      user {
        id
        display_name
        email
        phone
        mobile
      }
    }
  `,
};

export default DonorContactListItem;

const styles = {
  tableCell: css`
    vertical-align: middle !important;
    border-top: 1px ${Colors.LIGHT_GRAY1} solid;
    height: 55px;
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;
    }
    tr:first-of-type & {
      border-top: none;
    }
    tr:hover & {
      background-color: ${Colors.LIGHT_GRAY5} !important;
    }
  `,
  tableActionCell: css`
    text-align: right !important;
  `,
};
