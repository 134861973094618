import { Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { Fragment } from 'react';
import { PersonTagIconFragment } from '../generated/graphql';
import PersonNotesIcon from './PersonNotesIcon';
import PersonSpecialtyIcon from './PersonSpecialtyIcon';
import PersonTagIcon from './PersonTagIcon';

type PersonIcons = {
  notes?: string;
  tags: PersonTagIconFragment[];
};

type SoulIcons = {
  buys_ticket: boolean;
  needs_blind: boolean;
  needs_wheelchair: boolean;
  needs_wheeled_walker: boolean;
  needs_trainstation_fetch: boolean;
  needs_drive: boolean;
} & PersonIcons;

type CompanionIcons = {
  can_blind: boolean;
  can_wheelchair: boolean;
  can_wheeled_walker: boolean;
  can_drive: boolean;
} & PersonIcons;

type PersonIconListProps = {
  person: SoulIcons | CompanionIcons;
  position?: Position;
};

const isSoul = (person: SoulIcons | CompanionIcons): person is SoulIcons => 'buys_ticket' in person;

const PersonIconList = ({ person, position }: PersonIconListProps) => {
  if (isSoul(person)) {
    return (
      <Fragment>
        {person.notes && <PersonNotesIcon notes={person.notes} position={position} />}
        {person.buys_ticket && <PersonSpecialtyIcon label="Selbstzahler" icon={IconNames.EURO} position={position} />}
        {person.needs_wheelchair && (
          <PersonSpecialtyIcon label="Rollstuhl" icon={IconNames.SHOPPING_CART} position={position} />
        )}
        {person.needs_wheeled_walker && (
          <PersonSpecialtyIcon label="Rollator" icon={IconNames.WALK} position={position} />
        )}
        {person.needs_blind && <PersonSpecialtyIcon label="Blind" icon={IconNames.EYE_OFF} position={position} />}
        {person.needs_drive && (
          <PersonSpecialtyIcon
            label="Muss von Zuhause abgeholt werden"
            icon={IconNames.DRIVE_TIME}
            position={position}
          />
        )}
        {person.needs_trainstation_fetch && (
          <PersonSpecialtyIcon label="Muss vom Bahnhof abgeholt werden" icon={IconNames.TRAIN} position={position} />
        )}
        {person.tags.map(tag => (
          <PersonTagIcon key={tag.id} tag={tag} position={position} />
        ))}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {person.notes && <PersonNotesIcon notes={person.notes} position={position} />}
      {/* {person.can_wheelchair && <PersonSpecialtyIcon label="Kann Rollstuhl schieben" icon={IconNames.SHOPPING_CART} position={position} />}
      {person.can_wheeled_walker && (
        <PersonSpecialtyIcon label="Kann Genießer mit Rollator begleiten" icon={IconNames.WALK} position={position} />
      )}
      {person.can_blind && <PersonSpecialtyIcon label="Kann Blinde begleiten" icon={IconNames.EYE_OFF} position={position} />}
      {person.can_drive && (
        <PersonSpecialtyIcon label="Kann Genießer von Zuhause abholen" icon={IconNames.DRIVE_TIME} position={position} />
      )} */}
      {person.tags.map(tag => (
        <PersonTagIcon key={tag.id} tag={tag} position={position} />
      ))}
    </Fragment>
  );
};

export default PersonIconList;
