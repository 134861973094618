import { Classes, Icon, Tooltip } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import gql from 'graphql-tag';
import React from 'react';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { AllotmentListItemFragment, AllotmentStatus } from '../../../generated/graphql';
import { formatDay } from '../../../helpers/utils';

export type AllotmentListProps = {
  item: AllotmentListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

type StatusMap = Record<AllotmentStatus, { label: string; icon: IconName }>;

const statusMap: StatusMap = {
  [AllotmentStatus.Planned]: {
    label: 'Geplant',
    icon: IconNames.CALENDAR,
  },
  [AllotmentStatus.Active]: {
    label: 'Aktiv',
    icon: IconNames.TICK,
  },
  [AllotmentStatus.Expired]: {
    label: 'Abgelaufen',
    icon: IconNames.OUTDATED,
  },
  [AllotmentStatus.Depleted]: {
    label: 'Ausgeschöpft',
    icon: IconNames.DISABLE,
  },
};

const renderValidity = (status: AllotmentStatus, start: string, end: string, skeleton?: boolean) => {
  if (status === AllotmentStatus.Planned) {
    return <Text skeleton={skeleton} muted>{`Beginnt am ${formatDay(start)}`}</Text>;
  }

  if (status === AllotmentStatus.Expired) {
    return <Text skeleton={skeleton} muted>{`Abgelaufen am ${formatDay(end)}`}</Text>;
  }

  if (end) {
    return <Text skeleton={skeleton}>{`Läuft ab am ${formatDay(end)}`}</Text>;
  }

  return <Text skeleton={skeleton}>Unbegrenzt gültig!</Text>;
};

const AllotmentListItem = ({ item, skeleton, onClick }: AllotmentListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <Tooltip content={statusMap[item.status].label} disabled={skeleton}>
        <Icon icon={statusMap[item.status].icon} className={skeleton ? Classes.SKELETON : ''} />
      </Tooltip>
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{item.name}</Text>
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{item.amount_left}</Text>
      <Text skeleton={skeleton} muted>
        {' / '}
      </Text>
      <Text skeleton={skeleton} muted>
        {item.amount}
      </Text>
    </TableCell>
    <TableCell>{renderValidity(item.status, item.start, item.end, skeleton)}</TableCell>
  </tr>
);

AllotmentListItem.fragments = {
  allotment: gql`
    fragment AllotmentListItem on Allotment {
      id
      name
      status
      amount
      amount_left
      start
      end
    }
  `,
};

export default AllotmentListItem;
