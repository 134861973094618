import { IMenuItemProps, MenuItem } from '@blueprintjs/core';
import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

interface INavMenuItemProps extends IMenuItemProps {
  path: string;
  exact?: boolean;
}

const NavMenuItem: React.FC<INavMenuItemProps> = ({ path, exact, ...rest }) => {
  const history = useHistory();
  const match = useRouteMatch({ path, exact });
  const navigate = useCallback(() => history.push(path), [history, path]);

  return <MenuItem {...rest} onClick={navigate} active={!!match} />;
};

export default NavMenuItem;
