import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import {
  CategoryEditFormFragment,
  useCategoryEditLazyQuery,
  useUpdateCategoryMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import CategoryForm from './CategoryForm';

interface ICategoryEditOverlayProps extends IOverlayProps {
  id: string;
}

const CategoryEditOverlay = ({ id, ...overlayProps }: ICategoryEditOverlayProps) => {
  const [loadCategory, { data, called, loading, error: loadError }] = useCategoryEditLazyQuery({
    variables: {
      id,
    },
  });
  const [updateCategory] = useUpdateCategoryMutation();
  const handleSubmit = useSubmit({
    mutate: (values: CategoryEditFormFragment) =>
      updateCategory({
        variables: {
          input: values,
        },
      }),
    onCompleted: () => {
      loadCategory(); // Have to execute query again since it's a lazy one
      overlayProps.onClose && overlayProps.onClose();
    },
    getErrors: getLaravelValidationErrors,
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadCategory();
  }

  const renderContent = () => {
    if (loading) {
      return <LoadingCard />;
    }

    if (loadError || !data || !data.category) {
      return <ErrorCard />;
    }

    return (
      <CategoryForm<CategoryEditFormFragment>
        isEdit
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={data.category}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default CategoryEditOverlay;
