import { IconNames } from '@blueprintjs/icons';
import gql from 'graphql-tag';
import React from 'react';
import EmailLink from '../../../components/EmailLink';
import PersonSpecialtyIcon from '../../../components/PersonSpecialtyIcon';
import PersonTagIcon from '../../../components/PersonTagIcon';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { SoulListItemFragment } from '../../../generated/graphql';

export type SoulListProps = {
  item: SoulListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

const SoulListItem = ({ item, skeleton, onClick }: SoulListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <Text skeleton={skeleton}>{item.user.display_name}</Text> <br />
      <Text skeleton={skeleton} small muted>
        {item.organisation && item.organisation.name}
      </Text>
    </TableCell>
    <TableCell>
      {item.buys_ticket && <PersonSpecialtyIcon label="Selbstzahler" icon={IconNames.EURO} skeleton={skeleton} />}
      {item.needs_wheelchair && (
        <PersonSpecialtyIcon label="Rollstuhl" icon={IconNames.SHOPPING_CART} skeleton={skeleton} />
      )}
      {item.needs_wheeled_walker && <PersonSpecialtyIcon label="Rollator" icon={IconNames.WALK} skeleton={skeleton} />}
      {item.needs_blind && <PersonSpecialtyIcon label="Blind" icon={IconNames.EYE_OFF} skeleton={skeleton} />}
      {item.needs_drive && (
        <PersonSpecialtyIcon label="Muss von Zuhause abgeholt werden" icon={IconNames.DRIVE_TIME} skeleton={skeleton} />
      )}
      {item.needs_trainstation_fetch && (
        <PersonSpecialtyIcon label="Muss vom Bahnhof abgeholt werden" icon={IconNames.TRAIN} skeleton={skeleton} />
      )}
      {item.tags.map(tag => (
        <PersonTagIcon key={tag.id} tag={tag} skeleton={skeleton} />
      ))}
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton} muted>
        {item.user.phone || item.user.mobile || '—'}
      </Text>
    </TableCell>
    <TableCell>
      <EmailLink email={item.user.email} skeleton={skeleton} />
    </TableCell>
  </tr>
);

SoulListItem.fragments = {
  soul: gql`
    fragment SoulListItem on Soul {
      id
      buys_ticket
      needs_wheelchair
      needs_wheeled_walker
      needs_drive
      needs_trainstation_fetch
      needs_blind
      user {
        id
        status
        display_name
        email
        phone
        mobile
      }
      organisation {
        id
        name
      }
      tags {
        ...PersonTagIcon
      }
    }
    ${PersonTagIcon.fragments.tag}
  `,
};

export default SoulListItem;
