import { Colors, Icon, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import PersonIconList from '../../../components/PersonIconList';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { ProspectGroupListItemFragment } from '../../../generated/graphql';

type SignupType = ProspectGroupListItemFragment | ProspectGroupListItemFragment['group'][0];

export type GroupMemberListItemProps = {
  signup: SignupType;
};

const isLeaderSignup = (signup: SignupType): signup is ProspectGroupListItemFragment => 'group' in signup;

const GroupMemberListItem = ({ signup }: GroupMemberListItemProps) => {
  const prospect = isLeaderSignup(signup) ? signup.companion! : signup.soul!;
  const isSoul = !isLeaderSignup(signup);
  const { user } = prospect;

  return (
    <tr>
      <TableCell>
        <Tooltip content={isSoul ? 'Genießer' : 'Begleiter'}>
          <Icon style={{ marginRight: '5px' }} icon={isSoul ? IconNames.HEART : IconNames.HAND} color={Colors.GRAY1} />
        </Tooltip>
        {user!.display_name}
      </TableCell>
      <TableCell>
        <PersonIconList person={prospect} />
        <br />
        <Text small muted>
          {signup.notes}
        </Text>
      </TableCell>
      <TableCell>{signup.priority}</TableCell>
    </tr>
  );
};

export default GroupMemberListItem;
