/** @jsx jsx */
import { Card, Colors, ICardProps } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import React from 'react';

export interface IContentCardHeader {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  children?: React.ReactNode;
}

export const ContentCardHeader = ({ leftElement, rightElement, children }: IContentCardHeader) => {
  if (!leftElement && !rightElement && !children) {
    return null;
  }

  if (children) {
    return <div css={styles.header}>{children}</div>;
  }

  return (
    <div css={styles.header}>
      <div css={styles.headerLeft}>{leftElement}</div>
      <div css={styles.headerRight}>{rightElement}</div>
    </div>
  );
};

export interface IContentCardFooter {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  children?: React.ReactNode;
}

export const ContentCardFooter = ({ leftElement, rightElement, children }: IContentCardFooter) => {
  if (!leftElement && !rightElement && !children) {
    return null;
  }

  if (children) {
    return <div css={styles.footer}>{children}</div>;
  }

  return (
    <div css={styles.footer}>
      <div css={styles.footerLeft}>{leftElement}</div>
      <div css={styles.footerRight}>{rightElement}</div>
    </div>
  );
};

export interface IContentCardScroll {
  children?: React.ReactNode;
}

export const ContentCardScroll = ({ children }: IContentCardScroll) => <div css={styles.scrollContent}>{children}</div>;

export const ContentCard = ({ children, ...rest }: ICardProps) => (
  <Card css={styles.card} {...rest}>
    {children}
  </Card>
);

const styles = {
  card: css`
    padding: 0px;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px ${Colors.LIGHT_GRAY1} solid;
  `,
  headerLeft: css`
    display: flex;
  `,
  headerRight: css`
    display: flex;
    justify-content: flex-end;
  `,
  scrollContent: css`
    background-color: ${Colors.LIGHT_GRAY5};
    flex-shrink: 1;
    padding: 20px;
    overflow-y: auto;
    max-height: calc(80vh - 126px); // 100vh - (2*10vh + 2*63px)
  `,
  footer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-top: 1px ${Colors.LIGHT_GRAY1} solid;
  `,
  footerLeft: css`
    display: flex;
  `,
  footerRight: css`
    display: flex;
    justify-content: flex-end;
  `,
};
