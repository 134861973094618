import { ISwitchProps, Switch } from '@blueprintjs/core';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { useFocusOnError } from '../helpers/forms';

interface IToggleInputProps extends ISwitchProps {
  name: string;
  label: string;
}

const ToggleInput = (props: IToggleInputProps) => {
  const [{ value, ...field }] = useField({ name: props.name, type: 'checkbox' });
  const fieldRef = useRef<HTMLInputElement>();
  const formik = useFormikContext();
  useFocusOnError({ fieldRef, name: props.name });

  return (
    <Switch
      {...props}
      {...field}
      inputRef={ref => (fieldRef.current = ref || undefined)}
      disabled={props.disabled || formik.isSubmitting}
    />
  );
};

export default ToggleInput;
