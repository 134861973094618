import { useRef } from 'react';
import useInterval from './useInterval';
import useWindowSize from './useWindowSize';

export type ScrollContainer = 'window' | 'parent';

export type UseInfiniteScrollProps = {
  loading: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
  threshold?: number;
  checkInterval?: number;
  scrollContainer?: ScrollContainer;
};

function useInfiniteScroll<T extends HTMLElement>({
  loading,
  hasNextPage,
  onLoadMore,
  threshold = 150,
  checkInterval = 200,
  scrollContainer = 'window',
}: UseInfiniteScrollProps) {
  const ref = useRef<T>(null);
  const { height: windowHeight, width: windowWidth } = useWindowSize();

  function getParentElement(el: HTMLElement) {
    return el && el.parentNode && el.parentNode instanceof HTMLElement ? el.parentNode : undefined;
  }

  function getBottomOffset(innerEl: HTMLElement, outerEl?: HTMLElement) {
    if (!windowHeight) {
      return null;
    }

    const boundingHeight = outerEl ? outerEl.getBoundingClientRect().bottom : windowHeight;
    return innerEl.getBoundingClientRect().bottom - boundingHeight;
  }

  function isElementInView(el: HTMLElement) {
    if (!el || !windowWidth || !windowHeight) return false;

    const { left, right, top, bottom, width, height } = el.getBoundingClientRect();

    if (width === 0 && height === 0) return false;
    if (left > windowWidth) return false;
    if (right < 0) return false;
    if (top > windowHeight) return false;
    if (bottom < 0) return false;

    return true;
  }

  function listenBottomOffset() {
    if (ref.current && isElementInView(ref.current) && !loading && hasNextPage) {
      let bottomOffset = null;
      if (scrollContainer === 'parent') {
        const parent = getParentElement(ref.current);
        if (!parent || !isElementInView(parent)) {
          // Do nothing if the parent is out of screen
          return;
        }
        bottomOffset = getBottomOffset(ref.current, parent);
      } else {
        bottomOffset = getBottomOffset(ref.current);
      }

      // Check if the distance between bottom of the container and bottom of the window or parent
      // is less than "threshold"
      const validOffset = bottomOffset !== null && bottomOffset < threshold;

      if (validOffset) {
        onLoadMore();
      }
    }
  }

  useInterval(
    () => {
      listenBottomOffset();
    },
    // Stop interval when there is no next page.
    hasNextPage ? checkInterval : 0,
  );

  return ref;
}

export default useInfiniteScroll;
