/** @jsx jsx */
import { Colors } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import CategoryTag from '../../../components/CategoryTag';
import Text from '../../../components/Text';
import { AttendListItemFragment } from '../../../generated/graphql';
import { formatToNowStrict } from '../../../helpers/utils';

export type AttendListItemProps = {
  attend: AttendListItemFragment;
  skeleton?: boolean;
};

const AttendListItem = ({ attend, skeleton }: AttendListItemProps) => (
  <tr>
    <td css={styles.tableCell}>
      {attend.event.categories.map(category => (
        <CategoryTag key={category.id} category={category} skeleton={skeleton} />
      ))}
      <Text skeleton={skeleton}>{attend.event.name}</Text>
    </td>
    <td css={styles.tableCell}>
      <Text skeleton={skeleton} muted>
        {formatToNowStrict(attend.event.start)}
      </Text>
    </td>
  </tr>
);

AttendListItem.fragments = {
  attend: gql`
    fragment AttendListItem on Signup {
      id
      event {
        id
        name
        start
        categories {
          id
          name
          color
        }
      }
    }
  `,
};

export default AttendListItem;

const styles = {
  tableCell: css`
    vertical-align: middle !important;
    border-top: 1px ${Colors.LIGHT_GRAY1} solid;
    height: 55px;
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;
    }
    tr:first-of-type & {
      border-top: none;
    }
    tr:hover & {
      background-color: ${Colors.LIGHT_GRAY5} !important;
    }
  `,
  tableActionCell: css`
    text-align: right !important;
  `,
  resourceLink: css`
    margin-right: 5px;
  `,
  buttonGroup: css`
    display: flex;
  `,
  actionButton: css`
    margin-right: 5px;
  `,
  statusContainer: css`
    display: flex;
    justify-content: space-between;
  `,
};
