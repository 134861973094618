import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { SoulEditFormFragment, useSoulEditLazyQuery, useUpdateSoulMutation } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import SoulForm from './SoulForm';

interface ISoulEditOverlayProps extends IOverlayProps {
  id: string;
}

const SoulEditOverlay = ({ id, ...overlayProps }: ISoulEditOverlayProps) => {
  const [loadSoul, { data, called, loading, error }] = useSoulEditLazyQuery({
    variables: {
      id,
    },
  });
  const [updateSoul] = useUpdateSoulMutation();
  const handleSubmit = useSubmit({
    mutate: (values: SoulEditFormFragment) =>
      updateSoul({
        variables: {
          input: {
            ...values,
            user: {
              update: values.user,
            },
            tags: {
              sync: values.tags.map(tag => tag.id),
            },
            organisation: {
              connect: values.organisation ? values.organisation.id : null,
              disconnect: !values.organisation,
            },
          },
        },
      }),
    onCompleted: () => {
      loadSoul(); // Have to execute query again since it's a lazy one
      overlayProps.onClose && overlayProps.onClose();
    },
    getErrors: getLaravelValidationErrors,
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadSoul();
  }

  const renderContent = () => {
    if (loading) {
      return <LoadingCard />;
    }

    if (error || !data || !data.soul) {
      return <ErrorCard />;
    }

    return (
      <SoulForm<SoulEditFormFragment>
        isEdit
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={data.soul}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default SoulEditOverlay;
