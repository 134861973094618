/** @jsx jsx */
import { Button, Callout, Card, Intent, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import FormGroup from '../../../components/FormGroup';
import TextInput from '../../../components/TextInput';
import { AppEnv, ENV } from '../../../helpers/constants';
import { getSubdomain } from '../../../helpers/utils';

interface ILockButton {
  locked: boolean;
  disabled?: boolean;
  onClick: any;
}

const LockButton = ({ locked, disabled, onClick }: ILockButton) => (
  <Tooltip content={`Passwort ${locked ? 'zeigen' : 'verstecken'}`} disabled={disabled}>
    <Button
      disabled={disabled}
      icon={locked ? 'unlock' : 'lock'}
      intent={Intent.WARNING}
      minimal={true}
      onClick={onClick}
    />
  </Tooltip>
);

export interface ILoginFormValues {
  username: string;
  password: string;
  branch: string;
}

interface ILoginForm {
  onSubmit: (values: ILoginFormValues, formikHelpers: FormikHelpers<ILoginFormValues>) => void;
}

const Login = ({ isSubmitting, status }: ILoginForm & FormikProps<ILoginFormValues>) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordShown = useCallback(() => setPasswordShown(!passwordShown), [passwordShown]);

  return (
    <Card>
      {status && status.error && (
        <Callout intent={Intent.DANGER} css={styles.callout}>
          {status.error}
        </Callout>
      )}
      <Form>
        <FormGroup label="Email" labelFor="email" name="username">
          <TextInput name="username" type="email" placeholder="Email" />
        </FormGroup>
        <FormGroup label="Passwort" labelFor="password" name="password">
          <TextInput
            name="password"
            type={passwordShown ? 'text' : 'password'}
            rightElement={<LockButton locked={!passwordShown} disabled={isSubmitting} onClick={togglePasswordShown} />}
          />
        </FormGroup>
        <Field name="branch" type="hidden" />
        <Button
          type="submit"
          intent={Intent.SUCCESS}
          rightIcon={IconNames.LOG_IN}
          text="Login"
          loading={isSubmitting}
          fill
        />
      </Form>
    </Card>
  );
};

const LoginSchema = Yup.object({
  username: Yup.string()
    .email('Keine gültige Email')
    .required('Erforderlich'),
  password: Yup.string()
    .min(2, 'Zu Kurz')
    .max(50, 'Zu Lang')
    .required('Erforderlich'),
});

const initialLoginFormValues: ILoginFormValues = {
  username: 'admin@hamburg.kulturschluessel.org',
  password: 'testtest',
  branch: ENV === AppEnv.Dev ? 'hamburg' : getSubdomain(),
};

const LoginForm = ({ onSubmit }: ILoginForm) => (
  <Formik component={Login} initialValues={initialLoginFormValues} validationSchema={LoginSchema} onSubmit={onSubmit} />
);

export default LoginForm;

const styles = {
  callout: css`
    margin-bottom: 15px;
  `,
};
