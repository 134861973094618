/** @jsx jsx */
import { Classes, Colors, Icon, IconName, Intent, Position, Tooltip } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

type PersonSpecialtyIconProps = {
  icon: IconName;
  intent?: Intent;
  label: string;
  position?: Position;
  skeleton?: boolean;
};

const PersonSpecialtyIcon = ({ icon, intent, label, position, skeleton }: PersonSpecialtyIconProps) => (
  <Tooltip content={label} position={position} disabled={skeleton}>
    <Icon
      icon={icon}
      intent={intent}
      color={intent ? undefined : Colors.GRAY1}
      className={skeleton ? Classes.SKELETON : ''}
      css={styles.icon}
    />
  </Tooltip>
);

export default PersonSpecialtyIcon;

const styles = {
  icon: css`
    margin-right: 5px;
  `,
};
