/** @jsx jsx */
import { Card, Spinner } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

const LoadingCard = () => (
  <Card css={styles.loadingCard}>
    <Spinner />
  </Card>
);

export default LoadingCard;

const styles = {
  loadingCard: css`
    text-align: center;
    padding: 40px 0;
    margin-bottom: 12px;
  `,
};
