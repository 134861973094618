import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useOrganisationEditLazyQuery, useUpdateOrganisationMutation } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import OrganisationForm, { OrganisationFormValues } from './OrganisationForm';

interface IOrganisationEditOverlayProps extends IOverlayProps {
  id: string;
}

const OrganisationEditOverlay = ({ id, ...overlayProps }: IOrganisationEditOverlayProps) => {
  const [loadOrganisation, { data, called, loading, error }] = useOrganisationEditLazyQuery({
    variables: {
      id,
    },
  });
  const [updateOrganisation] = useUpdateOrganisationMutation();
  const handleSubmit = useSubmit({
    mutate: (values: OrganisationFormValues) =>
      updateOrganisation({
        variables: {
          input: {
            ...values,
            id: values.id || '',
            souls: {
              sync: values.souls.map(soul => soul.id.toString()),
            },
            companions: {
              sync: values.companions.map(companion => companion.id.toString()),
            },
          },
        },
      }),
    onCompleted: () => {
      loadOrganisation(); // Have to execute query again since it's a lazy one
      overlayProps.onClose && overlayProps.onClose();
    },
    getErrors: getLaravelValidationErrors,
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadOrganisation();
  }

  const renderContent = () => {
    if (loading) {
      return <LoadingCard />;
    }

    if (error || !data || !data.organisation) {
      return <ErrorCard />;
    }

    const initialValues = {
      id: data.organisation.id,
      name: data.organisation.name,
      description: data.organisation.description,
      souls: data.organisation.souls.map(soul => ({
        id: soul.id,
        display_name: soul.user.display_name,
      })),
      companions: data.organisation.companions.map(companion => ({
        id: companion.id,
        display_name: companion.user.display_name,
      })),
    };

    return <OrganisationForm onCancel={overlayProps.onClose} onSubmit={handleSubmit} initialValues={initialValues} />;
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default OrganisationEditOverlay;
