/** @jsx jsx */
import { Colors } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

const TableCell = ({ children }: { children: React.ReactNode }) => <td css={styles.tableCell}>{children}</td>;

export default TableCell;

const styles = {
  tableCell: css`
    vertical-align: middle !important;
    border-top: 1px ${Colors.LIGHT_GRAY1} solid;
    height: 55px;
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;
    }
    tr:first-of-type & {
      border-top: none;
    }
    tr.active & {
      background-color: ${Colors.LIGHT_GRAY5} !important;
    }
    tr:hover & {
      background-color: ${Colors.LIGHT_GRAY5} !important;
    }
  `,
};
