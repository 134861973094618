import gql from 'graphql-tag';
import React from 'react';
import ResourceLink from '../../../components/ResourceLink';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { DonorListItemFragment } from '../../../generated/graphql';

export type DonorListProps = {
  item: DonorListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

const DonorListItem = ({ item, skeleton, onClick }: DonorListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <Text skeleton={skeleton}>{item.name}</Text>
    </TableCell>
    <TableCell>
      {item.contacts.map(contact => (
        <ResourceLink
          key={contact.id}
          path={`/contacts/${contact.id}`}
          text={contact.user.display_name}
          skeleton={skeleton}
        />
      ))}
    </TableCell>
  </tr>
);

DonorListItem.fragments = {
  donor: gql`
    fragment DonorListItem on Donor {
      id
      name
      contacts {
        id
        user {
          id
          display_name
        }
      }
    }
  `,
};

export default DonorListItem;
