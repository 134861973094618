/** @jsx jsx */
import { Button, Callout, Classes, Drawer, Intent, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { ProspectGroupListItemFragment } from '../../../generated/graphql';
import GroupMemberList from './GroupMemberList';

type GroupInfoDrawerProps = {
  signup: ProspectGroupListItemFragment;
  isOpen: boolean;
  onClose: () => void;
  addGroup: (companionSignupId: string, soulSignupIds: string[]) => void;
};

const GroupInfoDrawer = ({ signup, isOpen, onClose, addGroup }: GroupInfoDrawerProps) => {
  const { companion, group } = signup;
  const { organisation } = companion!;

  return (
    <Drawer
      title={<span css={styles.title}>{organisation!.name}</span>}
      position={Position.LEFT}
      size="600px"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div css={styles.container} className={Classes.DRAWER_BODY}>
        {!!signup.notes && (
          <Callout css={styles.callout} intent={Intent.PRIMARY} icon={null}>
            {signup.notes}
          </Callout>
        )}
        <GroupMemberList signup={signup} />
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <Button
          icon={IconNames.SMALL_TICK}
          intent={Intent.SUCCESS}
          text="Gruppe auf die Teilnehmerliste"
          css={styles.addGroupButton}
          onClick={() =>
            addGroup(
              signup.id,
              group.map(soulSignup => soulSignup.id),
            )
          }
        />
      </div>
    </Drawer>
  );
};

export default GroupInfoDrawer;

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow: visible;
    padding-top: 16px;
  `,
  title: css`
    font-size: 16px;
    margin-right: 10px;
  `,
  callout: css`
    margin: 0 20px 16px;
    width: auto !important;
  `,
  addGroupButton: css`
    float: right;
  `,
};
