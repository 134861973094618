import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import {
  LocationEditFormFragment,
  useLocationEditLazyQuery,
  useUpdateLocationMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import LocationForm from './LocationForm';

interface ILocationEditOverlayProps extends IOverlayProps {
  id: string;
}

const LocationEditOverlay = ({ id, ...overlayProps }: ILocationEditOverlayProps) => {
  const [loadLocation, { data, called, loading, error }] = useLocationEditLazyQuery({
    variables: {
      id,
    },
  });
  const [updateLocation] = useUpdateLocationMutation();
  const handleSubmit = useSubmit({
    mutate: (values: LocationEditFormFragment) =>
      updateLocation({
        variables: {
          input: values,
        },
      }),
    onCompleted: () => {
      loadLocation(); // Have to execute query again since it's a lazy one
      overlayProps.onClose && overlayProps.onClose();
    },
    getErrors: getLaravelValidationErrors,
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadLocation();
  }

  const renderContent = () => {
    if (loading) {
      return <LoadingCard />;
    }

    if (error || !data || !data.location) {
      return <ErrorCard />;
    }

    return (
      <LocationForm<LocationEditFormFragment>
        isEdit
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={data.location}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default LocationEditOverlay;
