import { Classes, Colors, Intent, IProps } from '@blueprintjs/core';
import React from 'react';

type TextProps = {
  intent?: Intent;
  color?: string;
  small?: boolean;
  disabled?: boolean;
  muted?: boolean;
  running?: boolean;
  skeleton?: boolean;
  children: React.ReactNode;
};

const Text = ({
  intent,
  color: suppliedColor,
  small,
  disabled,
  muted,
  running,
  skeleton,
  className,
  children,
  ...otherProps
}: TextProps & IProps) => {
  const props = otherProps as any;
  const classes = [className];
  let color = suppliedColor;

  if (small) {
    classes.push(Classes.TEXT_SMALL);
  }

  if (muted && !disabled) {
    classes.push(Classes.TEXT_MUTED);
  }

  if (disabled) {
    classes.push(Classes.TEXT_DISABLED);
  }

  if (running) {
    classes.push(Classes.RUNNING_TEXT);
  }

  if (skeleton) {
    classes.push(Classes.SKELETON);
  }

  if (intent) {
    switch (intent) {
      case Intent.DANGER:
        color = Colors.RED3;
        break;
      case Intent.PRIMARY:
        color = Colors.BLUE3;
        break;
      case Intent.SUCCESS:
        color = Colors.GREEN3;
        break;
      case Intent.WARNING:
        color = Colors.ORANGE3;
    }
  }

  if (color) {
    props.style = { color };
  }

  return (
    <span className={classes.join(' ')} {...props}>
      {children}
    </span>
  );
};

export default Text;
