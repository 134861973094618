/** @jsx jsx */
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import { useHistory } from 'react-router';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import PersonStatusSelect from '../../../components/PersonStatusSelect';
import { UserStatus } from '../../../generated/graphql';
import { useQueryParams } from '../../../hooks/navigation';
import SoulCreateOverlay from '../components/SoulCreateOverlay';
import SoulList from '../components/SoulList';

const SoulListPage = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const status = (queryParams.get('status') as UserStatus) || UserStatus.Active;

  const changeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newStatus = event.currentTarget.value;
    queryParams.set('status', newStatus);
    history.push({ search: `?${queryParams.toString()}` });
  };

  const onCreated = (modelId: string) => {
    AppToaster.success('Genießer hinzugefügt!');
    history.push(`/souls/${modelId}`);
  };

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.list}>
      <SoulCreateOverlay
        isOpen={isCreateDialogOpen}
        onSuccess={onCreated}
        onClose={() => setIsCreateDialogOpen(false)}
      />
      <ContentCardHeader
        leftElement={
          <Fragment>
            <PersonStatusSelect value={status} onChange={changeStatus} />
            <Button text="Filter" icon={IconNames.FILTER} />
          </Fragment>
        }
        rightElement={
          <Fragment>
            <Button
              text="Hinzufügen"
              icon={IconNames.ADD}
              intent={Intent.PRIMARY}
              css={styles.headerButton}
              onClick={() => setIsCreateDialogOpen(true)}
            />
            <Button text="Exportieren" icon={IconNames.EXPORT} css={styles.headerButton} />
          </Fragment>
        }
      />
      <SoulList />
    </ContentCard>
  );
};

export default SoulListPage;

const styles = {
  headerButton: css`
    margin-left: 10px;
  `,
  list: css`
    margin-bottom: 20px;
  `,
};
