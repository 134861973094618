/** @jsx jsx */
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik, FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import { Fragment, useRef } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import SelectInput from '../../../components/SelectInput';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import { USER_GENDER_OPTIONS } from '../../../helpers/constants';

const buildValidationSchema = (verb: 'update' | 'create') =>
  Yup.object({
    user: Yup.object({
      [verb]: Yup.object({
        first_name: Yup.string().required('Erforderlich'),
        last_name: Yup.string().required('Erforderlich'),
        email: Yup.string()
          .email('Keine gültige Email')
          .nullable(),
      }),
    }),
  });

interface IContactFormProps<T> {
  isEdit?: boolean;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  onCancel?: (event?: any) => void;
  initialValues: T;
}

const ContactForm = <T extends {}>({ isEdit, onCancel, onSubmit, initialValues }: IContactFormProps<T>) => {
  const verb = isEdit ? 'update' : 'create';
  const validationSchema = useRef(buildValidationSchema(verb)).current;

  return (
    <Formik<T> initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, submitForm }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<span css={styles.heading}>Ansprechpartner {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Geschlecht" name={`user.${verb}.gender`}>
              <SelectInput name={`user.${verb}.gender`} options={USER_GENDER_OPTIONS} />
            </FormGroup>
            <FormGroup label="Vorname" labelInfo="(erforderlich)" name={`user.${verb}.first_name`}>
              <TextInput name={`user.${verb}.first_name`} placeholder="Vorname" />
            </FormGroup>
            <FormGroup label="Nachname" labelInfo="(erforderlich)" name={`user.${verb}.last_name`}>
              <TextInput name={`user.${verb}.last_name`} placeholder="Nachname" />
            </FormGroup>
            <FormGroup label="Email" name={`user.${verb}.email`}>
              <TextInput name={`user.${verb}.email`} type="email" placeholder="Email" />
            </FormGroup>
            <FormGroup label="Telefon" name={`user.${verb}.phone`}>
              <TextInput name={`user.${verb}.phone`} type="text" placeholder="Telefon" />
            </FormGroup>
            <FormGroup label="Mobil" name={`user.${verb}.mobile`}>
              <TextInput name={`user.${verb}.mobile`} type="text" placeholder="Mobil" />
            </FormGroup>
            <FormGroup label="Adresse" name={`user.${verb}.street`}>
              <TextInput name={`user.${verb}.street`} type="text" placeholder="Straße" />
            </FormGroup>
            <FormGroup name={`user.${verb}.postal_code`}>
              <TextInput name={`user.${verb}.postal_code`} type="text" placeholder="PLZ" />
            </FormGroup>
            <FormGroup name={`user.${verb}.city`}>
              <TextInput name={`user.${verb}.city`} type="text" placeholder="Ort" />
            </FormGroup>

            <Divider css={styles.formDivider} />
            <FormGroup name="notes">
              <TextAreaInput name="notes" placeholder="Interne Notizen" fill rows={10} />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  css={styles.footerButton}
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

ContactForm.fragments = {
  edit: gql`
    fragment ContactEditForm on Contact {
      id
      notes
      user {
        id
        first_name
        last_name
        gender
        email
        phone
        mobile
        street
        city
        postal_code
      }
    }
  `,
};

export default ContactForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
