import { FetchResult } from '@apollo/client';
import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import {
  EventEditFormFragment,
  UpdateEventMutation,
  useEventEditLazyQuery,
  useUpdateEventMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import EventForm from './EventForm';

interface IEventEditOverlayProps extends IOverlayProps {
  id: string;
}

const EventEditOverlay = ({ id, ...overlayProps }: IEventEditOverlayProps) => {
  const [loadEvent, { data, called, loading, error }] = useEventEditLazyQuery({
    variables: {
      id,
    },
  });
  const [updateEvent] = useUpdateEventMutation();
  const handleSubmit = useSubmit<EventEditFormFragment, FetchResult<UpdateEventMutation>>({
    mutate: values =>
      updateEvent({
        variables: {
          input: {
            ...values,
            categories: {
              sync: values.categories.map(category => category.id),
            },
            donor: {
              connect: values.donor.id,
            },
            contact: {
              connect: values.contact.id,
            },
            location: {
              connect: values.location.id,
            },
            allotment: {
              connect: values.allotment ? values.allotment.id : null,
            },
          },
        },
      }),
    onCompleted: () => {
      loadEvent(); // Have to execute query again since it's a lazy one
      overlayProps.onClose && overlayProps.onClose();
    },
    getErrors: getLaravelValidationErrors,
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadEvent();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data || !data.event) return <ErrorCard />;

    return (
      <EventForm<EventEditFormFragment>
        isEdit
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={data.event}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default EventEditOverlay;
