/** @jsx jsx */
import { HTMLTable } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { CompanionMatchListItemFragment } from '../../../generated/graphql';
import CompanionMatchListItem from './CompanionMatchListItem';

type CompanionMatchListProps = {
  soulSignupId: string;
  companionSignups: CompanionMatchListItemFragment[];
  highlightedCompanionId: string | null;
  onHighlightedCompanionIdChange: (id: string | null) => void;
  addTeam: (companionSignupId: string, soulSignupId: string) => void;
};

const CompanionMatchList = ({
  soulSignupId,
  companionSignups,
  highlightedCompanionId,
  onHighlightedCompanionIdChange,
  addTeam,
}: CompanionMatchListProps) => (
  <HTMLTable condensed css={styles.table}>
    <colgroup>
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: 60 }} />
      <col span={1} style={{ width: 60 }} />
    </colgroup>
    <thead>
      <tr>
        <TableHeaderCell>Name</TableHeaderCell>
        <TableHeaderCell>Infos</TableHeaderCell>
        <TableHeaderCell>Priorität</TableHeaderCell>
        <TableHeaderCell />
      </tr>
    </thead>
    <tbody>
      {companionSignups.map(companionSignup => (
        <CompanionMatchListItem
          key={companionSignup.id}
          isActive={highlightedCompanionId === companionSignup.id}
          onMouseEnter={() => onHighlightedCompanionIdChange(companionSignup.id)}
          onMouseLeave={() => onHighlightedCompanionIdChange(null)}
          soulSignupId={soulSignupId}
          companionSignup={companionSignup}
          addTeam={addTeam}
        />
      ))}
    </tbody>
  </HTMLTable>
);

export default CompanionMatchList;

const styles = {
  table: css`
    width: 100%;
    overflow: auto;
  `,
};
