import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { CategoryCreateFormFragment, useCreateCategoryMutation } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import CategoryForm from './CategoryForm';

type CategoryCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const CategoryCreateOverlay = ({ onSuccess, ...overlayProps }: CategoryCreateOverlayProps) => {
  const [createCategory] = useCreateCategoryMutation();
  const handleSubmit = useSubmit({
    mutate: (values: CategoryCreateFormFragment) =>
      createCategory({
        variables: {
          input: values,
        },
      }),
    onCompleted: ({ data }) => {
      if (data && data.createCategory) {
        onSuccess(data.createCategory.id);
      }
    },
    getErrors: getLaravelValidationErrors,
  });

  const initialValues: CategoryCreateFormFragment = {
    name: '',
    color: '#000000',
  };

  return (
    <ContentOverlay {...overlayProps}>
      <CategoryForm<CategoryCreateFormFragment>
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default CategoryCreateOverlay;
