import { IOverlayProps } from '@blueprintjs/core';
import { Nullable, useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { EventCreateFormFragment, EventTicketType, useCreateEventMutation } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import EventForm from './EventForm';

type EventCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const EventCreateOverlay = ({ onSuccess, ...overlayProps }: EventCreateOverlayProps) => {
  const [createEvent] = useCreateEventMutation();
  const handleSubmit = useSubmit({
    mutate: (values: EventCreateFormFragment) =>
      createEvent({
        variables: {
          input: {
            ...values,
            categories: {
              sync: values.categories.map(category => category.id),
            },
            donor: {
              connect: values.donor.id,
            },
            contact: {
              connect: values.contact.id,
            },
            location: {
              connect: values.location.id,
            },
            allotment: {
              connect: values.allotment ? values.allotment.id : null,
            },
          },
        },
      }),
    onCompleted: ({ data }) => {
      if (data && data.createEvent) {
        onSuccess(data.createEvent.id);
      }
    },
    getErrors: getLaravelValidationErrors,
  });

  const initialValues: Nullable<EventCreateFormFragment> = {
    name: '',
    start: '',
    ticket_type: EventTicketType.Fixed,
    ticket_time: 30,
    ticket_retrieval_location: 'An der Kasse',
    categories: [],
    donor: null,
    contact: null,
    location: null,
    allotment: null,
  };

  return (
    <ContentOverlay {...overlayProps}>
      <EventForm<EventCreateFormFragment>
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default EventCreateOverlay;
