/** @jsx jsx */
import { Classes, HTMLTable, Spinner } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { NetworkStatus } from 'apollo-client';
import { useHistory } from 'react-router';
import { ContentCardFooter } from '../../../components/ContentCard';
import Empty from '../../../components/Empty';
import Error from '../../../components/Error';
import InfiniteScrollWrapper from '../../../components/InfiniteScrollWrapper';
import NoResults from '../../../components/NoResults';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { EventStatus, useEventListQuery } from '../../../generated/graphql';
import { useQueryParams } from '../../../hooks/navigation';
import { dummyEvents } from '../dummy';
import EventListItem from './EventListItem';

const LoadingTable = () => (
  <HTMLTable condensed css={styles.table}>
    <thead>
      <tr>
        <TableHeaderCell>Status</TableHeaderCell>
        <TableHeaderCell>Titel</TableHeaderCell>
        <TableHeaderCell>Datum / Uhrzeit</TableHeaderCell>
        <TableHeaderCell>Anmeldungen / Zusagen</TableHeaderCell>
      </tr>
    </thead>
    <tbody>
      {dummyEvents.map((item, index) => (
        <EventListItem key={index} item={item} skeleton />
      ))}
    </tbody>
  </HTMLTable>
);

const EventList = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const status = queryParams.get('status') as EventStatus;
  const filtersUsed = status !== null;

  const { data, loading, error, fetchMore, networkStatus } = useEventListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      status: status || undefined,
    },
  });

  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  if (loading && !loadingMore) {
    return <LoadingTable />;
  }

  if (error) {
    return <Error />;
  }

  if (!data || !data.events || !data.events.edges || !data.events.edges.length) {
    return filtersUsed ? <NoResults /> : <Empty />;
  }

  const hasNextPage = data.events.pageInfo.hasNextPage;
  const nextCursor = data.events.pageInfo.endCursor;

  return (
    <InfiniteScrollWrapper
      loading={loadingMore}
      onLoadMore={() =>
        fetchMore({
          variables: {
            cursor: nextCursor,
          },
        })
      }
      hasNextPage={hasNextPage}
    >
      <HTMLTable interactive condensed css={styles.table}>
        <thead>
          <tr>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Titel</TableHeaderCell>
            <TableHeaderCell>Datum / Uhrzeit</TableHeaderCell>
            <TableHeaderCell>Anmeldungen / Zusagen</TableHeaderCell>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.events.edges.map(edge =>
              edge && edge.node ? (
                <EventListItem
                  key={edge.node.id}
                  item={edge.node}
                  onClick={() => history.push(`/events/${edge.node!.id}`)}
                />
              ) : null,
            )}
        </tbody>
      </HTMLTable>
      {loadingMore && (
        <ContentCardFooter>
          <div css={styles.loadingMoreContainer}>
            <Spinner size={16} />
            <div className={Classes.TEXT_MUTED} css={styles.loadingMoreText}>
              Lade mehr
            </div>
          </div>
        </ContentCardFooter>
      )}
    </InfiniteScrollWrapper>
  );
};

export default EventList;

const styles = {
  table: css`
    width: 100%;
  `,
  loadingMoreContainer: css`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  loadingMoreText: css`
    padding-left: 10px;
  `,
};
