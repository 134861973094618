/** @jsx jsx */
import { Button, Classes, Elevation, HTMLTable, Intent, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Fragment, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import TableHeaderCell from '../../../components/TableHeaderCell';
import Text from '../../../components/Text';
import TrashTag from '../../../components/TrashTag';
import { useDonorDetailQuery } from '../../../generated/graphql';
import ContactCreateOverlay from '../../contacts/components/ContactCreateOverlay';
import DonorContactListItem from '../components/DonorContactListItem';
import DonorDetailMenu from '../components/DonorDetailMenu';
import DonorEditOverlay from '../components/DonorEditOverlay';

const DonorDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const history = useHistory();
  const { donorId } = useParams();
  const [isContactCreateDialogOpen, setIsContactCreateDialogOpen] = useState(false);

  const onCreated = (modelId: string) => {
    AppToaster.success('Ansprechpartner hinzugefügt!');
    history.push(`/contacts/${modelId}`);
  };

  const options = {
    variables: {
      id: donorId || '',
    },
  };

  const { data, loading, error } = useDonorDetailQuery(options);

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.donor) return <NotFoundCard resource="Spender" />;

  const { donor } = data;

  const address: string | null =
    donor.street && donor.postal_code && donor.city ? `${donor.street}, ${donor.postal_code} ${donor.city}` : null;

  return (
    <Fragment>
      <DonorEditOverlay id={donor.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{donor.name}</span>
              <Tag minimal css={styles.personTag}>
                Spender
              </Tag>
              {!!donor.deleted_at && <TrashTag />}
            </Fragment>
          }
          rightElement={<DonorDetailMenu donor={donor} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div css={styles.content}>
          <PropertyList>
            <PropertyListHeader>Spender</PropertyListHeader>
            <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
          </PropertyList>
          <PropertyListDivider />
          <PropertyList>
            <PropertyListHeader>Spender Info</PropertyListHeader>
            <PropertyListItem label="Interne Notizen">
              {donor.notes ? (
                <div className={[Classes.RUNNING_TEXT, Classes.TEXT_MUTED].join(' ')}>{donor.notes}</div>
              ) : (
                <Text disabled>—</Text>
              )}
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>

      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContactCreateOverlay
          donorId={donor.id}
          isOpen={isContactCreateDialogOpen}
          onSuccess={onCreated}
          onClose={() => setIsContactCreateDialogOpen(false)}
        />
        <ContentCardHeader
          leftElement={<span css={styles.heading}>Ansprechpartner</span>}
          rightElement={
            <Button
              text="Hinzufügen"
              icon={IconNames.ADD}
              intent={Intent.PRIMARY}
              css={styles.headerButton}
              onClick={() => setIsContactCreateDialogOpen(true)}
            />
          }
        />
        <HTMLTable interactive condensed css={styles.table}>
          <thead>
            <tr>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Email</TableHeaderCell>
              <TableHeaderCell>Telefon</TableHeaderCell>
            </tr>
          </thead>
          <tbody>
            {donor.contacts.map(contact => (
              <DonorContactListItem
                key={contact.id}
                item={contact}
                onClick={() => history.push(`/contacts/${contact.id}`)}
              />
            ))}
          </tbody>
        </HTMLTable>
      </ContentCard>
    </Fragment>
  );
};

DonorDetailPage.fragments = {
  donor: gql`
    fragment DonorDetailPage on Donor {
      id
      name
      street
      city
      postal_code
      notes
      deleted_at
      contacts {
        ...DonorContactListItem
      }
    }
    ${DonorContactListItem.fragments.contact}
  `,
};

export default DonorDetailPage;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  table: css`
    width: 100%;
  `,
  overlayContent: css`
    left: calc(50vw - 225px);
    margin: 10vh 0;
    width: 450px;
    max-height: 80vh;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
