import { InMemoryCache } from '@apollo/client';
import introspectionResult from './generated/introspection-result.json';

const cursorPaginatedField = (keyArgs?: string[]) => ({
  keyArgs,
  merge: (existing: any, incoming: any) => {
    if (!incoming) return existing;

    // Currently, the "FieldFunctionOptions" object does not properly report
    // variables, nor args so we have to rely on the pageInfo object to determine
    // whether or not we need to clear the cache.
    // if in the result, "hasPreviousPage" === false, it means we have started querying
    // the list again and want to replace rather that append to the existing data
    if (!existing || !incoming.pageInfo.hasPreviousPage) return incoming;

    const newEdges = incoming.edges;
    const existingEdges = existing.edges;

    return existingEdges && newEdges
      ? {
          // Put the new comments at the end of the list and update `pageInfo`
          // so we have the new `endCursor` and `hasNextPage` values
          __typename: existing.__typename,
          edges: existingEdges.concat(newEdges),
          pageInfo: incoming.pageInfo,
        }
      : existing;
  },
  read(existing: any) {
    return existing;
  },
});

const cacheRedirect = (typename: string) => (
  existing: any,
  { args, toReference }: { args: Record<string, any>; toReference: any },
) =>
  existing ||
  toReference({
    __typename: typename,
    id: args.id,
  });

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        events: cursorPaginatedField(['status']),
        souls: cursorPaginatedField(['status']),
        companions: cursorPaginatedField(['status']),
        employees: cursorPaginatedField(['status']),
        donors: cursorPaginatedField([]),
        locations: cursorPaginatedField([]),
        signup: cacheRedirect('Signup'),
        soul: cacheRedirect('Soul'),
        companion: cacheRedirect('Companion'),
        donor: cacheRedirect('Donor'),
        location: cacheRedirect('Location'),
        organisation: cacheRedirect('Organisation'),
        category: cacheRedirect('Category'),
        allotment: cacheRedirect('Allotment'),
        contact: cacheRedirect('Contact'),
        event: cacheRedirect('Event'),
      },
    },
    Soul: {
      fields: {
        attends: cursorPaginatedField([]),
        future_signups: cursorPaginatedField([]),
      },
    },
    Companion: {
      fields: {
        attends: cursorPaginatedField([]),
        future_signups: cursorPaginatedField([]),
      },
    },
  },
});

export default cache;
