/** @jsx jsx */
import { Button, Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { EventDetailPageFragment } from '../../../generated/graphql';

type AttendeeListMenuProps = {
  event: EventDetailPageFragment;
};

const AttendeeListMenu = ({ event }: AttendeeListMenuProps) => {
  const history = useHistory();

  const navigateToMatching = () => {
    history.push(`/events/${event.id}/matching`);
  };

  if (!event.candidates_chosen) {
    return (
      <Button
        text="Erstellen"
        intent={Intent.PRIMARY}
        icon={IconNames.SAVED}
        css={styles.headerButton}
        onClick={navigateToMatching}
      />
    );
  }

  return (
    <Fragment>
      <Button text="Bearbeiten" icon={IconNames.EDIT} css={styles.headerButton} onClick={navigateToMatching} />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem text="Nachricht an alle Teilnehmer" icon={IconNames.ENVELOPE} />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} css={styles.headerButton} />
      </Popover>
    </Fragment>
  );
};

export default AttendeeListMenu;

const styles = {
  statusTag: css`
    margin-left: 10px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
};
