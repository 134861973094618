/** @jsx jsx */
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik, FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import PersonOrganisationSelect from '../../../components/PersonOrganisationSelect';
import PersonTagMultiSelect from '../../../components/PersonTagMultiSelect';
import SelectInput from '../../../components/SelectInput';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import ToggleInput from '../../../components/ToggleInput';
import { USER_GENDER_OPTIONS } from '../../../helpers/constants';

const validationSchema = Yup.object({
  user: Yup.object({
    first_name: Yup.string().required('Erforderlich'),
    last_name: Yup.string().required('Erforderlich'),
    email: Yup.string()
      .email('Keine gültige Email')
      .nullable(),
  }),
});

interface ICompanionFormProps<T> {
  isEdit?: boolean;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  onCancel?: (event?: any) => void;
  initialValues: T;
}

const CompanionForm = <T extends {}>({ isEdit, onCancel, onSubmit, initialValues }: ICompanionFormProps<T>) => (
  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ isSubmitting, submitForm }) => (
      <ContentCard elevation={Elevation.FOUR}>
        <ContentCardHeader
          leftElement={<span css={styles.heading}>Begleiter {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
          rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
        />

        <ContentCardScroll>
          <FormGroup label="Geschlecht" name="user.gender">
            <SelectInput name="user.gender" options={USER_GENDER_OPTIONS} />
          </FormGroup>
          <FormGroup label="Vorname" labelInfo="(erforderlich)" name="user.first_name">
            <TextInput name="user.first_name" placeholder="Vorname" />
          </FormGroup>
          <FormGroup label="Nachname" labelInfo="(erforderlich)" name="user.last_name">
            <TextInput name="user.last_name" placeholder="Nachname" />
          </FormGroup>
          <FormGroup label="Email" name="user.email">
            <TextInput name="user.email" type="email" placeholder="Email" />
          </FormGroup>
          <FormGroup label="Telefon" name="user.phone">
            <TextInput name="user.phone" type="text" placeholder="Telefon" />
          </FormGroup>
          <FormGroup label="Mobil" name="user.mobile">
            <TextInput name="user.mobile" type="text" placeholder="Mobil" />
          </FormGroup>
          <FormGroup label="Adresse" name="user.street">
            <TextInput name="user.street" type="text" placeholder="Straße" />
          </FormGroup>
          <FormGroup name="user.postal_code">
            <TextInput name="user.postal_code" type="text" placeholder="PLZ" />
          </FormGroup>
          <FormGroup name="user.city">
            <TextInput name="user.city" type="text" placeholder="Ort" />
          </FormGroup>

          <Divider css={styles.formDivider} />
          <FormGroup label="Besonderheiten" name="can_wheelchair">
            <ToggleInput name="can_wheelchair" label="Kann Rollstuhl schieben" />
            <ToggleInput name="can_blind" label="Kann Blinde begleiten" />
            <ToggleInput name="needs_wheeled_walker" label="Kann Genießer mit Rollator begleiten" />
            <ToggleInput name="needs_drive" label="Kann Genießer von Zuhause abholen" />
          </FormGroup>
          <FormGroup label="Attribute" name="tags">
            <PersonTagMultiSelect name="tags" />
          </FormGroup>
          <FormGroup label="Organisation" name="organisation">
            <PersonOrganisationSelect name="organisation" />
          </FormGroup>
          <FormGroup label="Interne Notizen" name="notes">
            <TextAreaInput name="notes" placeholder="Interne Notizen" fill rows={10} />
          </FormGroup>
        </ContentCardScroll>

        <ContentCardFooter
          rightElement={
            <Fragment>
              <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
              <Button
                text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                loading={isSubmitting}
                intent={Intent.PRIMARY}
                onClick={submitForm}
                css={styles.footerButton}
              />
            </Fragment>
          }
        />
      </ContentCard>
    )}
  </Formik>
);

CompanionForm.fragments = {
  // This is only used for Formik initialValues
  create: gql`
    fragment CompanionCreateForm on Companion {
      notes
      can_wheelchair
      can_wheeled_walker
      can_drive
      can_blind
      user {
        first_name
        last_name
        status
        gender
        email
        phone
        mobile
        street
        city
        postal_code
      }
      organisation {
        id
        name
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  `,
  edit: gql`
    fragment CompanionEditForm on Companion {
      id
      notes
      can_wheelchair
      can_wheeled_walker
      can_drive
      can_blind
      user {
        id
        first_name
        last_name
        gender
        email
        phone
        mobile
        street
        city
        postal_code
      }
      organisation {
        id
        name
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  `,
};

export default CompanionForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
