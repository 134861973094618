/** @jsx jsx */
import { Card, Elevation } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { AttendeeLoneSoulFragment } from '../../../generated/graphql';
import AttendeeListItem from './AttendeeListItem';

type AttendeeLoneSoulProps = {
  item: AttendeeLoneSoulFragment;
};

const AttendeeLoneSoul = ({ item: soul }: AttendeeLoneSoulProps) => (
  <Card css={styles.spacedCard} elevation={Elevation.TWO}>
    <AttendeeListItem item={soul} />
  </Card>
);

AttendeeLoneSoul.fragments = {
  loneSoul: gql`
    fragment AttendeeLoneSoul on Signup {
      ...AttendeeListItem
    }
    ${AttendeeListItem.fragments.attendees}
  `,
};

export default AttendeeLoneSoul;

const styles = {
  spacedCard: css`
    overflow: hidden;
    padding: 0;
    margin-bottom: 12px;
  `,
};
