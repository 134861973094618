/** @jsx jsx */
import { HTMLTable } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { OrganisationDetailPageFragment } from '../../../generated/graphql';
import OrganisationSoulListItem from './OrganisationSoulListItem';

type OrganisationSoulListProps = {
  souls: OrganisationDetailPageFragment['souls'];
};

const OrganisationSoulList = ({ souls }: OrganisationSoulListProps) => {
  const history = useHistory();

  return (
    <HTMLTable interactive condensed css={styles.table}>
      <thead>
        <tr>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Besonderheiten</TableHeaderCell>
        </tr>
      </thead>
      <tbody>
        {souls.map(soul => (
          <OrganisationSoulListItem key={soul.id} item={soul} onClick={() => history.push(`/souls/${soul.id}`)} />
        ))}
      </tbody>
    </HTMLTable>
  );
};

export default OrganisationSoulList;

const styles = {
  table: css`
    width: 100%;
  `,
};
