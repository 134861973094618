/** @jsx jsx */
import { Colors, Elevation, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import { useAllotmentDetailQuery } from '../../../generated/graphql';
import { formatDay } from '../../../helpers/utils';
import AllotmentDetailMenu from '../components/AllotmentDetailMenu';
import AllotmentEditOverlay from '../components/AllotmentEditOverlay';

const AllotmentDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { allotmentId } = useParams();

  const options = {
    variables: {
      id: allotmentId || '',
    },
  };

  const { data, loading, error } = useAllotmentDetailQuery(options);

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.allotment) return <NotFoundCard resource="Spender" />;

  const { allotment } = data;

  return (
    <Fragment>
      <AllotmentEditOverlay id={allotment.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{allotment.name}</span>
              <Tag minimal css={styles.personTag}>
                Kontingent
              </Tag>
            </Fragment>
          }
          rightElement={<AllotmentDetailMenu allotment={allotment} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div css={styles.content}>
          <PropertyList>
            <PropertyListHeader>Karten</PropertyListHeader>
            <PropertyListItem label="Insgesamt" value={allotment.amount.toString()} />
            <PropertyListItem label="Verfügbar" value={allotment.amount_left.toString()} />
          </PropertyList>
          <PropertyListDivider />
          <PropertyList>
            <PropertyListHeader>Gültigkeit</PropertyListHeader>
            <PropertyListItem label="Start" value={formatDay(allotment.start)} />
            <PropertyListItem
              label="Ende"
              value={allotment.end ? formatDay(allotment.end) : 'Unbegrenzt Gültig!'}
              showValueDisabled={!allotment.end}
            />
          </PropertyList>
        </div>
      </ContentCard>
    </Fragment>
  );
};

AllotmentDetailPage.fragments = {
  allotment: gql`
    fragment AllotmentDetailPage on Allotment {
      id
      name
      status
      amount
      amount_left
      start
      end
    }
  `,
};

export default AllotmentDetailPage;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  table: css`
    width: 100%;
  `,
  tableHeadCell: css`
    background-color: ${Colors.LIGHT_GRAY5};
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;
    }
  `,
  overlayContent: css`
    left: calc(50vw - 225px);
    margin: 10vh 0;
    width: 450px;
    max-height: 80vh;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
