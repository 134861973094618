import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { AllotmentCreateFormFragment, useCreateAllotmentMutation } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import AllotmentForm from './AllotmentForm';

type AllotmentCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const AllotmentCreateOverlay = ({ onSuccess, ...overlayProps }: AllotmentCreateOverlayProps) => {
  const [createAllotment] = useCreateAllotmentMutation();
  const handleSubmit = useSubmit({
    mutate: (values: AllotmentCreateFormFragment) =>
      createAllotment({
        variables: {
          input: values,
        },
      }),
    onCompleted: ({ data }) => {
      if (data && data.createAllotment) {
        onSuccess(data.createAllotment.id);
      }
    },
    getErrors: getLaravelValidationErrors,
  });

  const initialValues: AllotmentCreateFormFragment = {
    name: '',
    amount: 0,
    start: '',
  };

  return (
    <ContentOverlay {...overlayProps}>
      <AllotmentForm<AllotmentCreateFormFragment>
        onCancel={overlayProps.onClose}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default AllotmentCreateOverlay;
