/** @jsx jsx */
import { Colors } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

const TableHeaderCell = ({ children }: { children?: React.ReactNode }) => (
  <td css={styles.tableHeadCell}>{!!children && children}</td>
);

export default TableHeaderCell;

const styles = {
  tableHeadCell: css`
    background-color: ${Colors.LIGHT_GRAY5};
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;
    }
  `,
};
