/** @jsx jsx */
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik, FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
});

interface IDonorFormProps<T> {
  isEdit?: boolean;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  onCancel?: (event?: any) => void;
  initialValues: T;
}

const DonorForm = <T extends {}>({ isEdit, onCancel, onSubmit, initialValues }: IDonorFormProps<T>) => (
  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ isSubmitting, submitForm }) => (
      <ContentCard elevation={Elevation.FOUR}>
        <ContentCardHeader
          leftElement={<span css={styles.heading}>Spender {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
          rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
        />

        <ContentCardScroll>
          <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
            <TextInput name="name" placeholder="Name" />
          </FormGroup>
          <FormGroup label="Adresse" name="street">
            <TextInput name="street" type="text" placeholder="Straße" />
          </FormGroup>
          <FormGroup name="postal_code">
            <TextInput name="postal_code" type="text" placeholder="PLZ" />
          </FormGroup>
          <FormGroup name="city">
            <TextInput name="city" type="text" placeholder="Ort" />
          </FormGroup>

          <Divider css={styles.formDivider} />

          <FormGroup name="notes">
            <TextAreaInput name="notes" placeholder="Interne Notizen" fill rows={10} />
          </FormGroup>
        </ContentCardScroll>

        <ContentCardFooter
          rightElement={
            <Fragment>
              <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
              <Button
                text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                loading={isSubmitting}
                intent={Intent.PRIMARY}
                onClick={submitForm}
                css={styles.footerButton}
              />
            </Fragment>
          }
        />
      </ContentCard>
    )}
  </Formik>
);

DonorForm.fragments = {
  create: gql`
    fragment DonorCreateForm on Donor {
      name
      street
      city
      postal_code
      notes
    }
  `,
  edit: gql`
    fragment DonorEditForm on Donor {
      id
      name
      street
      city
      postal_code
      notes
    }
  `,
};

export default DonorForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
