/** @jsx jsx */
import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import { EventDetailPageFragment } from '../../../generated/graphql';
import AddSignupsOverlay from './AddSignupsOverlay';

type SignupsMenuProps = {
  event: EventDetailPageFragment;
};

const SignupsMenu = ({ event }: SignupsMenuProps) => {
  const [isAddSignupsOverlayOpen, setIsAddSignupsOverlayOpen] = useState(false);

  return (
    <Fragment>
      <AddSignupsOverlay
        signups={event.signups}
        onClose={() => setIsAddSignupsOverlayOpen(false)}
        isOpen={isAddSignupsOverlayOpen}
      />
      <Button
        text="Hinzufügen"
        icon={IconNames.ADD}
        css={styles.headerButton}
        onClick={() => setIsAddSignupsOverlayOpen(true)}
      />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem text="Nachricht an alle Interessenten" icon={IconNames.ENVELOPE} />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} css={styles.headerButton} />
      </Popover>
    </Fragment>
  );
};

export default SignupsMenu;

const styles = {
  statusTag: css`
    margin-left: 10px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
};
