/** @jsx jsx */
import { Elevation } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { useEventDetailQuery } from '../../../generated/graphql';
import AttendeeGroup from '../../attendees/components/AttendeeGroup';
import AttendeeListContainer from '../../attendees/components/AttendeeListContainer';
import AttendeeListItem from '../../attendees/components/AttendeeListItem';
import AttendeeLoneCompanion from '../../attendees/components/AttendeeLoneCompanion';
import AttendeeLoneSoul from '../../attendees/components/AttendeeLoneSoul';
import AttendeeTeam from '../../attendees/components/AttendeeTeam';
import EventDetailMenu from '../components/EventDetailMenu';
import EventEditOverlay from '../components/EventEditOverlay';
import EventInfo from '../components/EventInfo';
import SignupList from '../components/SignupList';
import SignupListItem from '../components/SignupListItem';
import SignupMenu from '../components/SignupMenu';

const EventDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { eventId } = useParams();

  const options = {
    variables: {
      id: eventId || '',
    },
  };

  const { data, loading, error } = useEventDetailQuery(options);

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.event) return <NotFoundCard resource="Veranstaltung" />;

  const { event } = data;

  return (
    <Fragment>
      <EventEditOverlay id={event.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{event.name}</span>
            </Fragment>
          }
          rightElement={<EventDetailMenu event={event} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div css={styles.content}>
          <EventInfo event={event} />
        </div>
      </ContentCard>
      <div css={styles.listsContainer}>
        <div css={styles.leftList}>
          <AttendeeListContainer event={event} />
        </div>
        <div css={styles.rightList}>
          <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
            <ContentCardHeader
              leftElement={
                <Fragment>
                  <span css={styles.heading}>Interessenten</span>
                </Fragment>
              }
              rightElement={<SignupMenu event={event} />}
            />
            <SignupList event={event} />
          </ContentCard>
        </div>
      </div>
    </Fragment>
  );
};

EventDetailPage.fragments = {
  event: gql`
    fragment EventDetailPage on Event {
      id
      name
      status
      start
      end
      notes
      description
      taken_tickets
      available_tickets
      candidates_chosen
      ticket_time
      ticket_retrieval_type
      ticket_retrieval_location
      reservation_number
      candidateCount @client
      attendeeCount @client
      prospects @client {
        ...SignupListItem
      }
      candidates @client {
        ...AttendeeListItem
      }
      attendees @client {
        ...AttendeeListItem
      }
      groups @client {
        ...AttendeeGroup
      }
      teams @client {
        ...AttendeeTeam
      }
      loneSouls @client {
        ...AttendeeLoneSoul
      }
      loneCompanions @client {
        ...AttendeeLoneCompanion
      }
      cardholder {
        id
        user {
          id
          display_name
          mobile
          phone
          email
        }
      }
      signups {
        ...AttendeeListItem
        group {
          ...AttendeeListItem
        }
        leader {
          ...AttendeeListItem
        }
      }
      location {
        id
        name
        accessible
        meeting_point
        public_transport
        street
        postal_code
        city
      }
    }
    ${SignupListItem.fragments.prospects}
    ${AttendeeListItem.fragments.attendees}
    ${AttendeeGroup.fragments.groups}
    ${AttendeeTeam.fragments.teams}
    ${AttendeeLoneSoul.fragments.loneSoul}
    ${AttendeeLoneCompanion.fragments.loneCompanion}
  `,
};

export default EventDetailPage;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
  listsContainer: css`
    display: flex;
  `,
  leftList: css`
    width: 50%;
    padding-right: 10px;
  `,
  rightList: css`
    width: 50%;
    padding-left: 10px;
  `,
};
