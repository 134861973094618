/** @jsx jsx */
import { Card, Elevation } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { AttendeeLoneCompanionFragment } from '../../../generated/graphql';
import AttendeeListItem from './AttendeeListItem';

type AttendeeLoneCompanionProps = {
  item: AttendeeLoneCompanionFragment;
};

const AttendeeLoneCompanion = ({ item: companion }: AttendeeLoneCompanionProps) => (
  <Card css={styles.spacedCard} elevation={Elevation.TWO}>
    <AttendeeListItem item={companion} />
  </Card>
);

AttendeeLoneCompanion.fragments = {
  loneCompanion: gql`
    fragment AttendeeLoneCompanion on Signup {
      ...AttendeeListItem
    }
    ${AttendeeListItem.fragments.attendees}
  `,
};

export default AttendeeLoneCompanion;

const styles = {
  spacedCard: css`
    overflow: hidden;
    padding: 0;
    margin-bottom: 12px;
  `,
};
