import { IconNames } from '@blueprintjs/icons';
import gql from 'graphql-tag';
import React from 'react';
import EmailLink from '../../../components/EmailLink';
import PersonSpecialtyIcon from '../../../components/PersonSpecialtyIcon';
import PersonTagIcon from '../../../components/PersonTagIcon';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { CompanionListItemFragment } from '../../../generated/graphql';

export type CompanionListProps = {
  item: CompanionListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

const CompanionListItem = ({ item, skeleton, onClick }: CompanionListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <Text skeleton={skeleton}>{item.user.display_name}</Text> <br />
      <Text skeleton={skeleton} small muted>
        {item.organisation && item.organisation.name}
      </Text>
    </TableCell>
    <TableCell>
      {item.can_wheelchair && (
        <PersonSpecialtyIcon label="Kann Rollstuhl schieben" icon={IconNames.SHOPPING_CART} skeleton={skeleton} />
      )}
      {item.can_wheeled_walker && (
        <PersonSpecialtyIcon label="Kann Genießer mit Rollator begleiten" icon={IconNames.WALK} skeleton={skeleton} />
      )}
      {item.can_blind && (
        <PersonSpecialtyIcon label="Kann Blinde begleiten" icon={IconNames.EYE_OFF} skeleton={skeleton} />
      )}
      {item.can_drive && (
        <PersonSpecialtyIcon
          label="Kann Genießer von Zuhause abholen"
          icon={IconNames.DRIVE_TIME}
          skeleton={skeleton}
        />
      )}
      {item.tags.map(tag => (
        <PersonTagIcon key={tag.id} tag={tag} skeleton={skeleton} />
      ))}
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton} muted>
        {item.user.phone || item.user.mobile || '—'}
      </Text>
    </TableCell>
    <TableCell>
      <EmailLink email={item.user.email} skeleton={skeleton} />
    </TableCell>
  </tr>
);

CompanionListItem.fragments = {
  companion: gql`
    fragment CompanionListItem on Companion {
      id
      can_wheelchair
      can_wheeled_walker
      can_drive
      can_blind
      user {
        id
        status
        display_name
        email
        phone
        mobile
      }
      organisation {
        id
        name
      }
      tags {
        ...PersonTagIcon
      }
    }
    ${PersonTagIcon.fragments.tag}
  `,
};

export default CompanionListItem;
