/** @jsx jsx */
import { Intent, ProgressBar } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import Text from '../../../components/Text';
import { EventListItemFragment, EventStatus } from '../../../generated/graphql';

type AttendeeListStatusProps = {
  event: EventListItemFragment;
  skeleton?: boolean;
};

const AttendeeListStatus = ({ event, skeleton }: AttendeeListStatusProps) => {
  if (event.status === EventStatus.Draft) {
    return (
      <Text skeleton={skeleton} small muted>
        {event.available_tickets ? `${event.available_tickets} Karten` : 'Freier Eintritt'}
        {' - noch nicht veröffentlicht'}
      </Text>
    );
  }

  if (event.status === EventStatus.Published) {
    return (
      <Fragment>
        <ProgressBar
          stripes={false}
          animate={false}
          value={event.available_tickets ? event.signups.length / event.available_tickets : 1}
        />
        <div css={styles.statusContainer}>
          <div>
            <Text skeleton={skeleton} small muted>
              Angemeldet:
            </Text>
            <Text skeleton={skeleton} small>{` ${event.signups.length} Personen`}</Text>
          </div>
          <div>
            <Text skeleton={skeleton} small muted>
              {event.available_tickets ? `${event.available_tickets} Karten` : 'Freier Eintritt'}
            </Text>
          </div>
        </div>
      </Fragment>
    );
  }

  if (event.status === EventStatus.Closed) {
    return (
      <Fragment>
        <ProgressBar
          stripes={false}
          animate={false}
          intent={event.attendeeCount === event.candidateCount ? Intent.SUCCESS : Intent.PRIMARY}
          value={event.attendeeCount / event.candidateCount}
        />
        <div css={styles.statusContainer}>
          <div>
            <Text skeleton={skeleton} small muted>
              Zugesagt:
            </Text>
            <Text skeleton={skeleton} small>{` ${event.attendeeCount} Personen`}</Text>
          </div>
          <div>
            <Text skeleton={skeleton} small muted>
              {event.available_tickets ? `${event.available_tickets} Karten` : 'Freier Eintritt'}
            </Text>
          </div>
        </div>
      </Fragment>
    );
  }

  if (event.status === EventStatus.Cancelled) {
    return (
      <Text skeleton={skeleton} small muted>
        Veranstaltung abgesagt!
      </Text>
    );
  }

  return (
    <Text skeleton={skeleton} small muted>
      Fehler
    </Text>
  );
};

export default AttendeeListStatus;

const styles = {
  statusContainer: css`
    display: flex;
    justify-content: space-between;
  `,
};
