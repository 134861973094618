export enum PersonType {
  Soul = 'Soul',
  Companion = 'Companion',
  Employee = 'Employee',
  Contact = 'Contact',
}

export type UserHit = {
  id: number;
  first_name: string;
  last_name: string;
  display_name: string;
};

export type DonorHit = {
  id: number;
  name: string;
};
