import { EmployeeListItemFragment, Role } from '../../generated/graphql';

export const dummyRoles: Role[] = [
  {
    id: '1',
    name: 'Admin',
    created_at: null,
    updated_at: null,
    permissions: [],
  },
  {
    id: '2',
    name: 'Mitarbeiter',
    created_at: null,
    updated_at: null,
    permissions: [],
  },
  {
    id: '3',
    name: 'Entwickler',
    created_at: null,
    updated_at: null,
    permissions: [],
  },
];

export const dummyEmployees: EmployeeListItemFragment[] = [
  {
    id: '1',
    position: 'Entwickler',
    user: {
      id: '1',
      display_name: 'Benjamin Wulff',
      email: 'benjamin.wulff@me.com',
      phone: '0000 0000 0000',
      roles: [dummyRoles[0], dummyRoles[1]],
    },
  },
  {
    id: '2',
    position: 'Werksstudentin',
    user: {
      id: '1',
      display_name: 'Mario Speedwagon',
      email: 'mario.speedwagon@googlemail.com',
      phone: '0000 0000 00',
      roles: [dummyRoles[2]],
    },
  },
  {
    id: '3',
    position: 'Kulturschlüssel-Mitarbeiter',
    user: {
      id: '1',
      display_name: 'Anna Sthesia',
      email: 'anna.sthesia@gmail.de',
      phone: '000 0000 00',
      roles: [dummyRoles[0], dummyRoles[1], dummyRoles[2]],
    },
  },
  {
    id: '4',
    position: 'Projektleiter',
    user: {
      id: '1',
      display_name: 'Forcewind Forcewind',
      email: 'mitarbeiter@email.de',
      phone: '0000 0000 00',
      roles: [dummyRoles[0]],
    },
  },
];
