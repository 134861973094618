import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import AppToaster from './AppToaster';
import cache from './cache';
import config from './helpers/config';
import { resolvers, typeDefs } from './resolvers';

// Strip __typename from variables
const withoutTypename = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);

    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const handleFirstGraphQLErrors = errors => {
  if (!errors || !errors.length) return;

  const firstError = errors[0];
  switch (firstError.extensions.category) {
    case 'authentication':
      localStorage.removeItem('token');
      localStorage.removeItem('branch_id');
      cache.writeData({
        data: {
          isAuthenticated: false,
        },
      });
      break;
    case 'validation':
      AppToaster.danger('Ungültige Daten');
      break;
    case 'internal':
    default:
      AppToaster.danger(firstError.message);
      console.log('internal');
  }

  errors.forEach(({ message, locations, path }) =>
    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
  );
};

const handleNetworkError = networkError => {
  AppToaster.danger('Ein unbekannter Fehler ist aufgetreten');
  console.log(`[Network error]: ${networkError}`);
};

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) handleFirstGraphQLErrors(graphQLErrors);

      if (networkError) {
        handleNetworkError(networkError);
      }
    }),
    withoutTypename,
    authLink,
    new HttpLink({
      uri: config.endpoint,
    }),
  ]),
  cache,
  resolvers,
  typeDefs,
});

cache.writeData({
  data: {
    isAuthenticated: !!localStorage.getItem('token'),
  },
});

export default client;
