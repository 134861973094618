/** @jsx jsx */
import { Classes, Elevation, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EmailLink from '../../../components/EmailLink';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import PersonSpecialtyIcon from '../../../components/PersonSpecialtyIcon';
import UserStatusTag from '../../../components/PersonStatusTag';
import PersonTagIcon from '../../../components/PersonTagIcon';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useCompanionDetailQuery, UserGender } from '../../../generated/graphql';
import CompanionDetailMenu from '../components/CompanionDetailMenu';
import CompanionEditOverlay from '../components/CompanionEditOverlay';

const CompanionDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { companionId } = useParams();

  const options = {
    variables: {
      id: companionId || '',
    },
  };

  const { data, loading, error } = useCompanionDetailQuery(options);

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.companion) return <NotFoundCard resource="Begleiter" />;

  const { companion } = data;
  const { user } = companion;

  const address: string | null =
    user.street && user.postal_code && user.city ? `${user.street}, ${user.postal_code} ${user.city}` : null;

  return (
    <Fragment>
      <CompanionEditOverlay id={companion.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{user.display_name}</span>
              <Tag minimal css={styles.personTag}>
                Begleiter
              </Tag>
              <UserStatusTag status={companion.user.status} deleted={!!companion.deleted_at} />
            </Fragment>
          }
          rightElement={<CompanionDetailMenu companion={companion} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div css={styles.content}>
          <PropertyList>
            <PropertyListHeader>Person</PropertyListHeader>
            <PropertyListItem
              label="Geschlecht"
              value={user.gender ? (user.gender === UserGender.Male ? 'Männlich' : 'Weiblich') : '—'}
              showValueDisabled={!user.gender}
            />
            <PropertyListItem label="Email">
              <EmailLink email={user.email} />
            </PropertyListItem>

            <PropertyListItem label="Telefon" value={user.phone || '—'} showValueDisabled={!user.phone} />
            <PropertyListItem label="Mobil" value={user.mobile || '—'} showValueDisabled={!user.mobile} />
            <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
          </PropertyList>
          <PropertyListDivider />
          <PropertyList>
            <PropertyListHeader>Begleiter Info</PropertyListHeader>
            <PropertyListItem label="Besonderheiten">
              {companion.can_wheelchair && (
                <PersonSpecialtyIcon label="Kann Rollstuhl schieben" icon={IconNames.SHOPPING_CART} />
              )}
              {companion.can_wheeled_walker && (
                <PersonSpecialtyIcon label="Kann Genießer mit Rollator begleiten" icon={IconNames.WALK} />
              )}
              {companion.can_blind && <PersonSpecialtyIcon label="Kann Blinde begleiten" icon={IconNames.EYE_OFF} />}
              {companion.can_drive && (
                <PersonSpecialtyIcon label="Kann Genießer von Zuhause abholen" icon={IconNames.DRIVE_TIME} />
              )}
            </PropertyListItem>
            <PropertyListItem label="Attribute">
              {!!companion.tags.length ? (
                companion.tags.map(tag => <PersonTagIcon key={tag.id} tag={tag} />)
              ) : (
                <Text disabled>—</Text>
              )}
            </PropertyListItem>
            <PropertyListItem
              label="Organisation"
              value={companion.organisation ? companion.organisation.name : '—'}
              showValueDisabled={!companion.organisation}
            />
            <PropertyListItem label="Interne Notizen">
              {companion.notes ? (
                <div className={[Classes.RUNNING_TEXT, Classes.TEXT_MUTED].join(' ')}>{companion.notes}</div>
              ) : (
                <Text disabled>—</Text>
              )}
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>
    </Fragment>
  );
};

CompanionDetailPage.fragments = {
  companion: gql`
    fragment CompanionDetailPage on Companion {
      id
      notes
      can_wheelchair
      can_wheeled_walker
      can_drive
      can_blind
      deleted_at
      user {
        id
        status
        display_name
        first_name
        last_name
        gender
        email
        phone
        mobile
        street
        city
        postal_code
      }
      organisation {
        id
        name
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  `,
};

export default CompanionDetailPage;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  overlayContent: css`
    left: calc(50vw - 225px);
    margin: 10vh 0;
    width: 450px;
    max-height: 80vh;
  `,
};
